import {
  getExportObjectSnackbarKey,
  parseExportSnackbar,
} from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportSnackbar";
import { EXPORT_STATUS_PENDING } from "@constants/static/export";
import {
  MODEL_TYPE_CLAIM,
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "@constants/static/globals";
import { updateConsumableExportObjectTSQ } from "@redux/actions/avey/consumables";
import { updateMedicationExportObjectTSQ } from "@redux/actions/avey/medications";
import { updateClaimExportObjectTSQ } from "@redux/actions/claims/claims";
import { updateOrganizationBundleExportObjectTSQ } from "@redux/actions/organizations/organization-bundles";
import { updateOrganizationClinicianExportObjectTSQ } from "@redux/actions/organizations/organization-clinicians";
import { updateOrganizationPatientExportObjectTSQ } from "@redux/actions/organizations/organization-patients";
import { updateOrganizationPolicyExportObjectTSQ } from "@redux/actions/organizations/organization-policies";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const MODEL_TYPE_FN_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: updateConsumableExportObjectTSQ,
  [MODEL_TYPE_MEDICATION]: updateMedicationExportObjectTSQ,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: updateOrganizationBundleExportObjectTSQ,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]:
    updateOrganizationClinicianExportObjectTSQ,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: updateOrganizationPatientExportObjectTSQ,
  [MODEL_TYPE_ORGANIZATION_POLICY]: updateOrganizationPolicyExportObjectTSQ,
  [MODEL_TYPE_CLAIM]: updateClaimExportObjectTSQ,
};

export default function handleWsExport({ object = {} }) {
  const { id, model_type } = object;
  closeSnackbar(
    getExportObjectSnackbarKey({ id, status: EXPORT_STATUS_PENDING })
  );
  enqueueSnackbar(parseExportSnackbar(object));
  const fn = MODEL_TYPE_FN_MAPPER[model_type];
  if (fn) fn({ object });
  return null;
}
