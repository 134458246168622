import React from "react";
import Button from "@mui/material/Button";
import CustomIconComponent from "./CustomIconComponent";

export default function CustomButton({
  label = "",
  icon = "",
  variant = "contained",
  iconProps = {},
  iconWrapperProps = {},
  style = {},
  isSquare = false,
  useIconAsLabel = false,
  disabled = false,
  isStartIcon = true,
  ...otherProps
}) {
  const iconComponent = (
    <CustomIconComponent
      icon={icon}
      variant={variant}
      disabled={disabled}
      iconProps={iconProps}
      iconWrapperProps={iconWrapperProps}
    />
  );
  const squareStyle = isSquare
    ? { minWidth: 40, minHeight: 40, padding: 0 }
    : { minWidth: 100 };
  const moreIconProps = useIconAsLabel
    ? {}
    : isStartIcon
    ? { startIcon: iconComponent }
    : { endIcon: iconComponent };
  const children = useIconAsLabel ? iconComponent : label;
  const finalStyle = { ...squareStyle, ...style };
  return (
    <Button
      color="primary"
      style={finalStyle}
      variant={variant}
      disabled={disabled}
      {...moreIconProps}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
