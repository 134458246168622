import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING } from "@constants/claims/claim-submission-uploads";
import MenuItem from "@mui/material/MenuItem";
import {
  cancelClaimSubmissionUpload,
  updateClaimSubmissionUploadObjectTSQ,
} from "@redux/actions/claims/claim-submission-uploads";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  getClaimSubmissionUploadSnackbarKey,
  parseClaimSubmissionUploadSnackbar,
} from "../ClaimSubmissionUploadSnackbar";

export default function ClaimSubmissionUploadCancelForm({ object }) {
  const { organization__slug, claim_submission__id } = useParams();
  const { id } = object;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    cancelClaimSubmissionUpload({
      id,
      filters: { organization__slug, claim_submission__id },
    })
      .then(({ payload }) => {
        const { instance } = payload;
        updateClaimSubmissionUploadObjectTSQ({ object: instance });
        setIsOpen(false);
        closeSnackbar(
          getClaimSubmissionUploadSnackbarKey({
            id,
            preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
          })
        );
        enqueueSnackbar(parseClaimSubmissionUploadSnackbar(instance));
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{"Cancel upload"}</MenuItem>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Cancel upload" }}
        subtitleComponentProps={{
          title: "Are you sure you wish to cancel this upload?",
        }}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        isBodyEmpty={true}
        yesText="Yes, I'm sure"
        noText="No, go back"
        yesButtonProps={{ color: "secondary" }}
        noButtonProps={{ color: "primary" }}
      />
    </>
  );
}
