import React from "react";
import { useRotateClassName } from "@helpers/hooks/useRotateClassName";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { ArrowDownIcon } from "@rimads/assets";

export default function CustomListItemCollapse({
  primary = "",
  secondary = "",
  useCollapse = true,
  children,
  wrapperProps = {},
}) {
  const [isOpen, setIsOpen] = React.useState(true);
  const rotateClassName = useRotateClassName({ isOpen });
  const className = useCollapse ? "button opacityOnHover-50" : "";
  const handleClick = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    useCollapse && setIsOpen(!isOpen);
  };

  return (
    <div>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        className={className}
        onClick={handleClick}
        {...wrapperProps}
      >
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ variant: "body2", fontWeight: "bold" }}
          secondary={secondary}
          secondaryTypographyProps={{
            variant: "caption",
            color: "textSecondary",
          }}
        />
        {useCollapse && <ArrowDownIcon className={rotateClassName} />}
      </Stack>
      <Collapse in={isOpen}>
        <div style={{ marginTop: 12 }}>{children}</div>
      </Collapse>
    </div>
  );
}
