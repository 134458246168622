import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import UnitCreateForm from "../Unit/UnitForms/UnitCreateForm";

export default function UnitCreateForms() {
  return (
    <CustomAddOrUploadWrapper label="Add units" useUploadForm={false}>
      <UnitCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
