import React from "react";
import { useQueryFetchOrganizationClinicians } from "@redux/actions/organizations/organization-clinicians";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getOrganizationClinicianOptionLabel,
  isOrganizationClinicianOptionEqualToValue,
  renderOrganizationClinicianOption,
} from "./OrganizationClinicianAutocompleteComponents";

export default function OrganizationClinicianAutocomplete({
  label = "",
  value = null,
  handleChange = () => {},
  textFieldProps = {},
}) {
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    filters: {
      newer_version__isnull: true,
      time_deleted__isnull: true,
      submitter_organization__slug,
    },
    useInfiniteWrapper: true,
  };
  return (
    <CustomInfiniteAutocomplete
      label={label}
      autocompleteProps={{ renderOption: renderOrganizationClinicianOption }}
      textFieldProps={textFieldProps}
      useQueryFn={useQueryFetchOrganizationClinicians}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getOrganizationClinicianOptionLabel}
      isOptionEqualToValue={isOrganizationClinicianOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
