import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import Grid from "@mui/material/Grid";
import { useQueryFetchClaimSubmissionUploads } from "@redux/actions/claims/claim-submission-uploads";
import { useParams } from "react-router-dom";
import ClaimSubmissionUploadData from "../ClaimSubmissionUpload/ClaimSubmissionUploadData";
import ClaimSubmissionUploadCreateForm from "../ClaimSubmissionUpload/ClaimSubmissionUploadForms/ClaimSubmissionUploadCreateForm";
import ClaimSubmissionUploadsGridProps from "./ClaimSubmissionUploadsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function ClaimSubmissionUploadsGrid({
  object: claim_submission,
}) {
  const { name } = claim_submission;
  const { organization__slug, claim_submission__id } = useParams();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [state, setState] = React.useState({ isOpen: false, object: null });
  const { isOpen, object } = state;
  const useQueryFnProps = {
    searchQuery,
    filters: { organization__slug, claim_submission__id },
  };
  const handleClickRow = (row) =>
    setState((prev) => ({ ...prev, isOpen: true, object: row }));
  const handleClose = (e) => {
    e?.preventDefault();
    setState((prev) => ({ ...prev, isOpen: false }));
  };
  const dataGridProps = React.useMemo(
    () => ClaimSubmissionUploadsGridProps({ handleClickRow }),
    []
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <ClaimSubmissionUploadCreateForm
            isMenuItem={false}
            buttonProps={{ variant: "contained" }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchClaimSubmissionUploads}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
      <CustomActionDialog
        isOpen={isOpen}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        handleSubmit={handleClose}
        handleClose={handleClose}
        titleComponentProps={{ title: name }}
        isOk={true}
        okButtonProps={{ color: "info" }}
        okText="Close"
        maxWidth="sm"
      >
        <ClaimSubmissionUploadData object={object} />
      </CustomActionDialog>
    </div>
  );
}
