import React from "react";
import OrganizationPatientData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData";
import OrganizationPatientCreateForm from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientForms/OrganizationPatientCreateForm";
import OrganizationPatientAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationPatientAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useClaimSubmitterResolveClaimFormContextProvider } from "../ClaimSubmitterResolveClaimFormContextProvider";
import {
  ClaimSubmitterResolveClaimSelectOptionwrapper,
  ClaimSubmitterResolveClaimUpdateOptionWrapper,
} from "../ClaimSubmitterResolveClaimOptionWrappers";

export default function ClaimOrganizationPatientSelectVariation() {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    claim_organization_patient_variations = [],
    claim_organization_patient_variation = {},
    updateContext = () => {},
  } = useClaimSubmitterResolveClaimFormContextProvider();
  const { index: variationIndex } = claim_organization_patient_variation || {};

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSelect = ({ e, index }) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateContext({
      claim_organization_patient_variation: {
        index,
        object: claim_organization_patient_variations?.[index],
      },
    });
  };

  const handleSelectOther = (payload) => {
    updateContext({
      claim_organization_patient_variations: [
        ...claim_organization_patient_variations,
        payload,
      ],
      claim_organization_patient_variation: {
        index: claim_organization_patient_variations.length,
        object: payload,
      },
    });
    setIsOpen(false);
  };

  return (
    <Stack spacing={2}>
      {claim_organization_patient_variations.map((object, index) => (
        <ClaimSubmitterResolveClaimSelectOptionwrapper
          key={index}
          isSelected={index === variationIndex}
          handleSelect={(e) => handleSelect({ e, index })}
          useEditIcon={false}
        >
          <OrganizationPatientData
            object={object}
            useCustomSection={false}
            useCustomCollapse={false}
          />
        </ClaimSubmitterResolveClaimSelectOptionwrapper>
      ))}
      {isOpen ? (
        <ClaimSubmitterResolveClaimUpdateOptionWrapper>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <OrganizationPatientAutocomplete
              label="Select patient"
              handleChange={handleSelectOther}
              textFieldProps={{ autoFocus: true }}
            />
            <CustomButton
              icon="CloseIcon"
              isSquare={true}
              useIconAsLabel={true}
              variant="outlined"
              onClick={handleIsOpen}
            />
          </Stack>
        </ClaimSubmitterResolveClaimUpdateOptionWrapper>
      ) : (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          pt
        >
          <CustomButton
            label="Search for a patient"
            color="info"
            onClick={handleIsOpen}
          />
          <Typography variant="body2">{"or"}</Typography>
          <OrganizationPatientCreateForm
            handleSelectPatient={handleSelectOther}
            withNavigate={false}
            isTypographyButton={true}
            label="Create a new patient"
            typographyProps={{ prefix: "" }}
          />
        </Stack>
      )}
    </Stack>
  );
}
