import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import { CustomQueryParams } from "@components/Custom/CustomAppliedFilters";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import { DESCRIBE_FILTERS_EXPORT_MAPPER } from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimestamp } from "@helpers/parse/time";
import HealthcareOrganizationTag, {
  useHealthcareOrganizationTag,
} from "../../HealthcareOrganizationConfigurations/HealthcareOrganizationTag";

export default function ConfigurationsBulkExportData({ object = {} }) {
  const { isStaff } = useStaffContextProvider();
  const { model_type } = useModelTypeContextProvider();
  const describeFilters = DESCRIBE_FILTERS_EXPORT_MAPPER[model_type];
  const {
    submitter_organization,
    time_start,
    time_end,
    requesting_user,
    file,
    query_params,
    exception_str,
    num_instances,
  } = object || {};

  const timeStart = parseTimestamp({ value: time_start, useSeconds: true });
  const timeEnd = parseTimestamp({ value: time_end, useSeconds: true });
  const numInstances = getIntOrFloat(num_instances);
  const hasFile = Boolean(file);
  const hasError = Boolean(exception_str);
  const full_name =
    `${requesting_user?.first_name} ${requesting_user?.last_name}`?.trim();
  const useHealthcareOrganization = useHealthcareOrganizationTag({
    isStaff,
    submitter_organization,
  });

  return (
    <CustomGrid>
      {useHealthcareOrganization && (
        <HealthcareOrganizationTag
          submitter_organization={submitter_organization}
        />
      )}
      <CustomLabeledText label="Requesting user" value={full_name} />
      <CustomLabeledText label="Matches" value={numInstances} />
      {hasFile && (
        <CustomLabeledText label="Generated file" useChildren={true}>
          <CustomExternalLink
            href={file}
            label="Download file"
            wrapperProps={{ download: true }}
            isTypography={true}
          />
        </CustomLabeledText>
      )}
      <CustomGrid>
        {timeStart && (
          <CustomLabeledText label="Time started" value={timeStart} />
        )}
        {timeEnd && <CustomLabeledText label="Time ended" value={timeEnd} />}
      </CustomGrid>
      <CustomQueryParams
        query_params={query_params}
        describeFilters={describeFilters}
      />
      {hasError && <CustomLabeledText label="Error" value={exception_str} />}
    </CustomGrid>
  );
}
