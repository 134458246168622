import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import {
  APP_CONFIGURATIONS_BASE_HREFS,
  APP_CONFIGURATIONS_TITLE,
} from "@constants/static/routing";

export default function AppConfigurationsHeader() {
  return (
    <CustomHeader
      titleComponentProps={{ title: APP_CONFIGURATIONS_TITLE }}
      hrefs={APP_CONFIGURATIONS_BASE_HREFS}
    />
  );
}
