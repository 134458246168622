import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { CLAIM_MODE_SUBMISSION_MAPPER } from "@constants/static/system-modes";
import MenuItem from "@mui/material/MenuItem";
import {
  activateMaintenanceOrganizationAgreement,
  updateOrganizationAgreementObjectTSQ,
} from "@redux/actions/organizations/organization-agreements";
import { selectorAuthClaimMode } from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function OrganizationAgreementActivateMaintenanceForm({
  object,
  useMenuItem = true,
  isOpenParent = false,
  handleCloseParent = () => {},
}) {
  const claimMode = useSelector(selectorAuthClaimMode);
  const claimModeStr = CLAIM_MODE_SUBMISSION_MAPPER[claimMode]?.toLowerCase();
  const claimModePluralStr = pluralize(claimModeStr);
  const { organization__slug: processor_organization__slug } = useParams();
  const [isOpen, setIsOpen] = React.useState(isOpenParent);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { id, time_maintenance, processor_organization } = object;
  const { image } = processor_organization || {};
  const isUnderMainenance = Boolean(time_maintenance);

  const menuItemText = isUnderMainenance
    ? `Enable ${claimModePluralStr}`
    : `Disable ${claimModePluralStr}`;
  const title = `${menuItemText}?`;

  const dialogProps = isUnderMainenance
    ? {
        subtitleComponentProps: {
          title: `Your registered staff will be able to ${claimModeStr} claims for this insurer.`,
        },
        noText: "Cancel",
        yesText: "Yes, enable",
      }
    : {
        subtitleComponentProps: {
          title: `Your registered staff will not be able to ${claimModeStr} new claims until you re-enable ${claimModePluralStr} for this insurer.`,
        },
        noText: "Cancel",
        yesText: "Yes, disable",
        yesButtonProps: { color: "secondary", variant: "contained" },
      };

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
    isOpen && handleCloseParent();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    activateMaintenanceOrganizationAgreement({ id, time_maintenance })
      .then(({ payload }) => {
        const { time_maintenance: newTimeMaintenance } = payload;
        enqueueSnackbar({
          variant: "success",
          message: newTimeMaintenance
            ? `${claimModePluralStr} successfully disabled`
            : `${claimModePluralStr} successfully enabled`,
        });
        updateOrganizationAgreementObjectTSQ({
          object: payload,
          processor_organization__slug,
        });
        setIsOpen(false);
        handleCloseParent();
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  React.useEffect(() => {
    setIsOpen(isOpenParent);
  }, [isOpenParent]);

  return (
    <>
      {useMenuItem && (
        <MenuItem onClick={handleIsOpen}>{menuItemText}</MenuItem>
      )}
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        isBodyEmpty={true}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title, image }}
        {...dialogProps}
      />
    </>
  );
}
