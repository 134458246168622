import React from "react";
import { CustomNavTabs } from "@components/Custom/CustomTabs";
import {
  CONSUMABLES_HREF,
  CONSUMABLES_TITLE,
  DRUG_NAMES_HREF,
  DRUG_NAMES_TITLE,
  IDENTITY_TYPES_HREF,
  IDENTITY_TYPES_TITLE,
  MEDICATIONS_HREF,
  MEDICATIONS_TITLE,
  SPECIALITIES_HREF,
  SPECIALITIES_TITLE,
  UNITS_HREF,
  UNITS_TITLE,
} from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import { useQueryFetchAppConfigurationCount } from "@redux/actions/utils/utils";
import ConsumablesGrid from "../../Consumables/Consumables/ConsumablesGrid";
import DrugNamesGrid from "../../DrugNames/DrugNames/DrugNamesGrid";
import IdentityTypesGrid from "../../IdentityTypes/IdentityTypes/IdentityTypesGrid";
import MedicationsGrid from "../../Medications/Medications/MedicationsGrid";
import SpecialitiesGrid from "../../Specialities/Specialities/SpecialitiesGrid";
import UnitsGrid from "../../Units/Units/UnitsGrid";

const defaultTab = CONSUMABLES_HREF;

const tabs = [
  {
    label: CONSUMABLES_TITLE,
    tab: CONSUMABLES_HREF,
    fieldname: "num_consumables",
  },
  {
    label: DRUG_NAMES_TITLE,
    tab: DRUG_NAMES_HREF,
    fieldname: "num_drug_names",
  },
  {
    label: IDENTITY_TYPES_TITLE,
    tab: IDENTITY_TYPES_HREF,
    fieldname: "num_identity_types",
  },
  {
    label: MEDICATIONS_TITLE,
    tab: MEDICATIONS_HREF,
    fieldname: "num_medications",
  },
  {
    label: SPECIALITIES_TITLE,
    tab: SPECIALITIES_HREF,
    fieldname: "num_specialities",
  },
  {
    label: UNITS_TITLE,
    tab: UNITS_HREF,
    fieldname: "num_units",
  },
];

const TabComponentMapper = {
  [CONSUMABLES_HREF]: ConsumablesGrid,
  [DRUG_NAMES_HREF]: DrugNamesGrid,
  [IDENTITY_TYPES_HREF]: IdentityTypesGrid,
  [MEDICATIONS_HREF]: MedicationsGrid,
  [SPECIALITIES_HREF]: SpecialitiesGrid,
  [UNITS_HREF]: UnitsGrid,
};

export default function AppConfigurationsBody() {
  const searchParams = new URLSearchParams(window.location.search);
  const tab = searchParams.get("tab") || defaultTab;
  const Component = TabComponentMapper[tab] || React.Fragment;
  const { data } = useQueryFetchAppConfigurationCount({});

  return (
    <Stack spacing={2}>
      <CustomNavTabs tabs={tabs} defaultTab={defaultTab} tabCountData={data} />
      <div>
        <Component />
      </div>
    </Stack>
  );
}
