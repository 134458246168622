import React from "react";
import IdentityTypeAutocomplete from "@components/Custom/CustomAutocomplete/IdentityTypeAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { emptyOrganizationPatientIdentity } from "@constants/organizations/organization-patients";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

export default function OrganizationPatientIdentitiesFormData({
  spacing = 0,
  padding = 0,
  formData = {},
  handleUpdateOrganizationPatientIdentities = () => {},
}) {
  const hasIdentities = formData.length > 0;

  const handleAddOrganizationPatientIdentity = () =>
    handleUpdateOrganizationPatientIdentities([
      ...formData,
      emptyOrganizationPatientIdentity,
    ]);

  const handleUpdateOrganizationPatientIdentity = ({ index, payload }) =>
    handleUpdateOrganizationPatientIdentities(
      formData.map((bundleCpt, position) =>
        position === index ? payload : bundleCpt
      )
    );

  const handleRemoveOrganizationPatientIdentity = ({ index }) =>
    handleUpdateOrganizationPatientIdentities(
      formData.filter((_, position) => position !== index)
    );

  return (
    <Stack spacing={spacing}>
      {formData.map((organizationPatientIdentityFormData, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <Divider />}
          <Box p={padding}>
            <OrganizationPatientIdentityFormData
              index={index}
              formData={organizationPatientIdentityFormData}
              handleUpdateOrganizationPatientIdentity={
                handleUpdateOrganizationPatientIdentity
              }
              handleRemoveOrganizationPatientIdentity={
                handleRemoveOrganizationPatientIdentity
              }
            />
          </Box>
        </React.Fragment>
      ))}
      {hasIdentities && <Divider />}
      <Box p={padding}>
        <CustomTypographyButton
          value="+ Add identity"
          onClick={handleAddOrganizationPatientIdentity}
        />
      </Box>
    </Stack>
  );
}

function OrganizationPatientIdentityFormData({
  index,
  formData,
  handleUpdateOrganizationPatientIdentity = () => {},
  handleRemoveOrganizationPatientIdentity = () => {},
}) {
  const { identity_type, number } = formData;

  const handleRemoveSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveOrganizationPatientIdentity({ index });
  };

  const handleChangeIdentityType = (newValue) =>
    handleUpdateOrganizationPatientIdentity({
      index,
      payload: { ...formData, identity_type: newValue },
    });

  const handleChangeIdentityNumber = (e) =>
    handleUpdateOrganizationPatientIdentity({
      index,
      payload: { ...formData, number: e.target.value },
    });

  return (
    <div>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs md={3}>
          <IdentityTypeAutocomplete
            value={identity_type}
            wrapperProps={{ flex: 1 }}
            handleChange={handleChangeIdentityType}
          />
        </Grid>
        <Grid item xs md={3}>
          <CustomOutlinedTextField
            required
            label="ID Number"
            name="number"
            value={number}
            onChange={handleChangeIdentityNumber}
            wrapperProps={{ flex: 1 }}
          />
        </Grid>
        <Grid item md={6} justifyContent="flex-end" display="grid">
          <CustomButton
            icon="CloseIcon"
            variant="outlined"
            isSquare={true}
            useIconAsLabel={true}
            onClick={handleRemoveSubmit}
          />
        </Grid>
      </Grid>
    </div>
  );
}
