import React from "react";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_ORGANIZATION_CLINICIAN,
  CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE,
  CLAIM_SECTION_RESULTS_MAPPER,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import { useClaimContext } from "../Claim/context";
import ClaimOrganizationClinicianFormData from "./ClaimOrganizationClinicianFormData";

const section = CLAIM_SECTION_ORGANIZATION_CLINICIAN;
const title = CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE;

export default function ClaimOrganizationClinician() {
  const {
    claim = {},
    isCreateOrUpdateMode = false,
    setFormData = () => {},
  } = useClaimContext();
  const { organization_clinician = {} } = claim;
  const handleChangeOrganizationClinician = React.useCallback(
    (newValue) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        organization_clinician: newValue,
      }));
    },
    [setFormData]
  );
  const hasOrganizationClinician = Boolean(organization_clinician);
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const hasResults = results.length > 0;

  return (
    <CustomSection title={title} isCollapsable={!isCreateOrUpdateMode}>
      <Stack spacing={3}>
        {isCreateOrUpdateMode ? (
          <ClaimOrganizationClinicianFormData
            formData={organization_clinician}
            handleChangeOrganizationClinician={
              handleChangeOrganizationClinician
            }
          />
        ) : hasOrganizationClinician ? (
          <OrganizationClinicianData
            object={organization_clinician}
            useCustomSection={false}
          />
        ) : (
          <CustomNotProvidedText title={title} />
        )}
        {hasResults && (
          <>
            <Divider />
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={section}
            />
          </>
        )}
      </Stack>
    </CustomSection>
  );
}
