import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import { getConfigurationsBulkUploadsHeader } from "@constants/static/routing";
import {
  UPLOAD_STATUS_COMMITTED,
  UPLOAD_STATUS_COMMITTING,
  UPLOAD_STATUS_PENDING,
  UPLOAD_STATUS_PREPROCESSING,
  UPLOAD_STATUS_PROPS_MAPPER,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/static/upload";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { useParams } from "react-router-dom";
import ConfigurationsBulkUploadCancelForm from "./ConfigurationsBulkUploadForms/ConfigurationsBulkUploadCancelForm";
import ConfigurationsBulkUploadCommitForm from "./ConfigurationsBulkUploadForms/ConfigurationsBulkUploadCommitForm";
import ConfigurationsBulkUploadRevalidateForm from "./ConfigurationsBulkUploadForms/ConfigurationsBulkUploadRevalidateForm";

export default function ConfigurationsBulkUploadHeader({ object }) {
  const { as_staff } = useStaffContextProvider();
  const { bundle_type, model_type } = useModelTypeContextProvider();
  const { organization__slug } = useParams();
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { id, name, status } = object;
  const { hrefs } = getConfigurationsBulkUploadsHeader({
    model_type,
    bundle_type,
    organization,
    upload: { id, name },
    as_staff,
  });
  const canCommitUpload = status === UPLOAD_STATUS_SUCCESS;
  const canReprocessUpload = ![
    UPLOAD_STATUS_COMMITTING,
    UPLOAD_STATUS_COMMITTED,
  ].includes(status);
  const canCancelUpload = [
    UPLOAD_STATUS_PENDING,
    UPLOAD_STATUS_PREPROCESSING,
  ].includes(status);
  const hasMoreActions = canReprocessUpload || canCancelUpload;

  return (
    <CustomHeader
      TitleComponent={CustomHeaderTitle}
      titleComponentProps={{
        title: name,
        children: (
          <CustomStatusTag
            status={status}
            textMapper={UPLOAD_STATUS_TEXT_MAPPER}
            propsMapper={UPLOAD_STATUS_PROPS_MAPPER}
          />
        ),
      }}
      hrefs={hrefs}
    >
      {canCommitUpload && (
        <ConfigurationsBulkUploadCommitForm object={object} />
      )}
      {hasMoreActions && (
        <CustomMoreActions>
          {canReprocessUpload && (
            <ConfigurationsBulkUploadRevalidateForm object={object} />
          )}
          {canCancelUpload && (
            <ConfigurationsBulkUploadCancelForm object={object} />
          )}
        </CustomMoreActions>
      )}
    </CustomHeader>
  );
}
