import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function CustomValueWithUnit({
  value,
  unit,
  separator = "",
  valueTypographyProps = {},
  unitTypographyProps = {},
  wrapperProps = {},
}) {
  return (
    <Stack
      spacing={0.5}
      direction="row"
      alignItems="baseline"
      {...wrapperProps}
    >
      <Typography variant="body2" {...valueTypographyProps}>
        {value}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        {...unitTypographyProps}
      >
        {`${separator || ""} ${unit || ""}`.trim()}
      </Typography>
    </Stack>
  );
}
