import React from "react";
import ConfigurationsBulkUpload from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUpload";
import ConfigurationsBulkUploads from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUploads";
import { Navigate, Route, Routes } from "react-router-dom";

export default function UploadRoutes() {
  return (
    <Routes>
      <Route path="" Component={ConfigurationsBulkUploads} />
      <Route path={":upload__id/*"} Component={ConfigurationsBulkUpload} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
