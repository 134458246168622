const {
  REACT_APP_AVEY_BLOOM_BACKEND_API_URL,
  REACT_APP_AVEY_BLOOM_BACKEND_WS_URL,
  REACT_APP_AVEY_BLOOM_COST_PER_CLAIM_IN_TOKENS,
  REACT_APP_AVEY_BLOOM_BACKEND_CURRENCY_CODE,
  REACT_APP_AVEY_BLOOM_BACKEND_CURRENCY_NAME,
  REACT_APP_LOG_WEBSOCKET_NOTIFICATIONS,
  REACT_APP_DIBSY_VAULT_API_URL,
  REACT_APP_DIBSY_VAULT_PUBLIC_KEY,
  REACT_APP_MUI_LICENSE_KEY,
  REACT_APP_AVEY_WITH_AVEY_COS,
  REACT_APP_AVEY_SUPPORT_EMAIL,
  REACT_APP_AVEY_PHONE_NUMBER,
  REACT_APP_AVEY_WHATSAPP_NUMBER,
  REACT_APP_SERVER_API_URL,
  REACT_APP_SERVER_WS_URL,
  REACT_APP_MICROSOFT_SPEECH_KEY,
  REACT_APP_MICROSOFT_SPEECH_REGION,
  REACT_APP_MICROSOFT_RECOGNITION_LANGUAGE,
  REACT_APP_EES_ENABLED,
  REACT_APP_AZURE_ICD_ENABLED,
  REACT_APP_AZURE_CPT_ENABLED,
  REACT_APP_AZURE_NDC_ENABLED,
  REACT_APP_IS_AVEY_BLOOM,
  REACT_APP_SITE_NAME,
  REACT_APP_GCP_ICD_ENABLED,
  REACT_APP_GCP_CPT_ENABLED,
  REACT_APP_GCP_NDC_ENABLED,
  REACT_APP_IS_STAFF_FRIENDLY,
} = process.env;

export const MUI_LICENSE_KEY = REACT_APP_MUI_LICENSE_KEY;
export const AVEY_SUPPORT_EMAIL = REACT_APP_AVEY_SUPPORT_EMAIL;
export const AVEY_PHONE_NUMBER = REACT_APP_AVEY_PHONE_NUMBER;
export const AVEY_WHATSAPP_NUMBER = REACT_APP_AVEY_WHATSAPP_NUMBER;
export const IS_STAFF_FRIENDLY = REACT_APP_IS_STAFF_FRIENDLY === "1";

export const AVEY_WITH_AVEY_COS = REACT_APP_AVEY_WITH_AVEY_COS === "1";
export const AVEY_BLOOM_BACKEND_API_URL = REACT_APP_AVEY_BLOOM_BACKEND_API_URL;
export const AVEY_BLOOM_BACKEND_WS_URL = REACT_APP_AVEY_BLOOM_BACKEND_WS_URL;
export const AVEY_BLOOM_COST_PER_CLAIM_IN_TOKENS = parseInt(
  REACT_APP_AVEY_BLOOM_COST_PER_CLAIM_IN_TOKENS || "1"
);
export const AVEY_BLOOM_BACKEND_CURRENCY_CODE =
  REACT_APP_AVEY_BLOOM_BACKEND_CURRENCY_CODE || "USD";
export const AVEY_BLOOM_BACKEND_CURRENCY_NAME =
  REACT_APP_AVEY_BLOOM_BACKEND_CURRENCY_NAME || "United States Dollar";

export const DIBSY_VAULT_API_URL = REACT_APP_DIBSY_VAULT_API_URL;
export const DIBSY_VAULT_PUBLIC_KEY = REACT_APP_DIBSY_VAULT_PUBLIC_KEY;

export const SERVER_API_URL = REACT_APP_SERVER_API_URL;
export const SERVER_WS_URL = REACT_APP_SERVER_WS_URL;

export const MICROSOFT_SPEECH_KEY = REACT_APP_MICROSOFT_SPEECH_KEY;
export const MICROSOFT_SPEECH_REGION = REACT_APP_MICROSOFT_SPEECH_REGION;
export const MICROSOFT_RECOGNITION_LANGUAGE =
  REACT_APP_MICROSOFT_RECOGNITION_LANGUAGE || "en-US";

export const LOG_WEBSOCKET_NOTIFICATIONS =
  REACT_APP_LOG_WEBSOCKET_NOTIFICATIONS === "1";

export const EES_ENABLED = REACT_APP_EES_ENABLED === "1";
export const AZURE_ICD_ENABLED = REACT_APP_AZURE_ICD_ENABLED === "1";
export const AZURE_CPT_ENABLED = REACT_APP_AZURE_CPT_ENABLED === "1";
export const AZURE_NDC_ENABLED = REACT_APP_AZURE_NDC_ENABLED === "1";

export const GCP_ICD_ENABLED = REACT_APP_GCP_ICD_ENABLED === "1";
export const GCP_CPT_ENABLED = REACT_APP_GCP_CPT_ENABLED === "1";
export const GCP_NDC_ENABLED = REACT_APP_GCP_NDC_ENABLED === "1";

export const IS_AVEY_BLOOM = REACT_APP_IS_AVEY_BLOOM === "1";
export const SITE_NAME = REACT_APP_SITE_NAME;
export const SITE_LOGO_WHITE = IS_AVEY_BLOOM
  ? "/assets/svg/logos/white/avey-bloom.svg"
  : "/assets/svg/logos/white/avey.svg";
export const SITE_LOGO_BLUE = IS_AVEY_BLOOM
  ? "/assets/svg/logos/blue/avey-bloom.svg"
  : "/assets/svg/logos/blue/avey.svg";

export const MODEL_TYPE_MEDICATION = "M";
export const MODEL_TYPE_CONSUMABLE = "C";
export const MODEL_TYPE_ORGANIZATION_CLINICIAN = "N";
export const MODEL_TYPE_ORGANIZATION_POLICY = "P";
export const MODEL_TYPE_ORGANIZATION_PATIENT = "A";
export const MODEL_TYPE_ORGANIZATION_BUNDLE = "B";
export const MODEL_TYPE_CLAIM = "X";
