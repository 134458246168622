import React from "react";
import UserAutocomplete from "@components/Custom/CustomAutocomplete/UserAutocomplete";
import CustomAvatar from "@components/Custom/CustomAvatar";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import { getOrganizationNameGivenOrganizationRole } from "@constants/organizations/organizations";
import { getClaimSubmissionHrefGivenOrganizationRole } from "@constants/static/routing";
import {
  USER_ORGANIZATION_ROLE_ADMIN,
  USER_ORGANIZATION_ROLE_EDITOR,
} from "@constants/users/users";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LineArrowRightIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";

export default function UserManagementReassignClaimSubmissionFormData({
  from_user = {},
  reassignment = {},
  handleChange = () => {},
  organizationRole,
}) {
  const { user, claim_submission } = reassignment;
  const { name } = claim_submission;
  const { image, first_name, last_name } = from_user;
  const full_name = `${first_name || ""} ${last_name || ""}`.trim();
  const href = getClaimSubmissionHrefGivenOrganizationRole({
    organizationRole,
    claim_submission,
  });
  const organizationName = getOrganizationNameGivenOrganizationRole({
    organizationRole,
    claim_submission,
  });
  const useQueryFnProps = {
    filters: {
      exclude_id: from_user.id,
      is_active: true,
      role__in: [USER_ORGANIZATION_ROLE_EDITOR, USER_ORGANIZATION_ROLE_ADMIN],
    },
  };

  return (
    <Stack spacing={1}>
      <Stack spacing={2} direction="row" alignItems="center">
        <CustomExternalLink href={href} label={name} isTypography={true} />
        <Typography variant="body2" color="textSecondary">
          {organizationName}
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row" alignItems="center">
        <CustomAvatar src={image} title={full_name} className="sizeSm" />
        <LineArrowRightIcon primary_fill={AVEY_BLACK} />
        <UserAutocomplete
          value={user}
          handleChange={handleChange}
          useQueryFnProps={useQueryFnProps}
        />
      </Stack>
    </Stack>
  );
}
