import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const emptySpecialityFormData = {
  code: "",
  name: "",
  description: "",
};

export const formatSpecialityFormData = ({ isCheck, formData }) => {
  const { id = undefined, code, name, description } = formData;

  return {
    id,
    is_check: isCheck,
    code: code?.trim(),
    name: name?.trim(),
    description: description?.trim(),
  };
};

const specialityRowToObject = (row) => {
  const { code, name, description } = getRowValue({
    row,
    columns: [
      { headerName: "CODE", fieldname: "code" },
      { headerName: "NAME", fieldname: "name" },
      { headerName: "DESCRIPTION", fieldname: "description" },
    ],
  });

  const isRowEmpty = !(code || name || description);
  return {
    id: isRowEmpty ? null : uuidv4(),
    code,
    name,
    description,
  };
};

export const specialityRowsToObject = (rows) =>
  rows.map((row) => specialityRowToObject(row)).filter(({ id }) => Boolean(id));
