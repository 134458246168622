import React from "react";
import CustomSummaryCards from "@components/Custom/CustomSummaryCards";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function ClaimSubmissionClaimsSummary({
  claim_submission_summary,
}) {
  const {
    num_claims = 0,
    num_claims_progressed = 0,
    total_amount_approved = 0,
    total_amount_requested = 0,
    total_amount_rejected = 0,
    currency = "",
  } = claim_submission_summary || {};

  const progress = (num_claims_progressed / (num_claims || 1)) * 100;

  const summaries = [
    {
      title: "Claims",
      primary: getIntOrFloat(num_claims),
    },
    {
      title: "Adjudicated",
      primary: getIntOrFloat({
        value: progress,
        minNumSigFig: 0,
        maxNumSigFig: 2,
      }),
      secondary: "%",
    },
    {
      title: "Approved",
      primary: getIntOrFloat(total_amount_approved),
      secondary: `/ ${getIntOrFloat(total_amount_requested)} ${currency}`,
    },
    {
      title: "Rejected",
      primary: getIntOrFloat(total_amount_rejected),
      secondary: currency,
    },
  ];
  return <CustomSummaryCards summaries={summaries} />;
}
