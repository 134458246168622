import {
  AZURE_NDC_ENABLED,
  EES_ENABLED,
  GCP_NDC_ENABLED,
} from "@constants/static/globals";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
  PayloadError,
  ServerApi,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";
import { getUniqueList } from "../utils/utils";

const endpointUrl = "/avey/medications/";
const exportEndpointUrl = "/avey/medication_exports/";
const queryKeySingular = "medication";
const queryKeyExports = `${queryKeySingular}_exports`;
const queryKeyPlural = "medications";
const queryKeySingularHistory = `${queryKeySingular}_history`;

const eesEndpointUrl = "/api/ees/medications/";
const azureEndpointUrl = "/api/azure/ndcs/";
const gcpEndpointUrl = "/api/gcp/ndcs/";

export const useQueryFetchMedications = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  eesFilters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
  useEesIfEnabled = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      {
        page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        eesFilters,
        useEesIfEnabled,
      },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchMedications, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        eesFilters,
        useEesIfEnabled,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchMedications({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  eesFilters = {},
  useEesIfEnabled = true,
}) {
  try {
    if (EES_ENABLED && useEesIfEnabled) {
      let searchQueries = [searchQuery];
      let nlpEndpointUrl = null;
      if (Boolean(searchQuery)) {
        if (GCP_NDC_ENABLED) nlpEndpointUrl = gcpEndpointUrl;
        else if (AZURE_NDC_ENABLED) nlpEndpointUrl = azureEndpointUrl;
        if (nlpEndpointUrl) {
          const body = { searchQuery };
          const response = await ServerApi().post(nlpEndpointUrl, body);
          searchQueries = getUniqueList([searchQuery, ...response.data]);
        }
      }
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        searches: searchQueries,
        ordering: sortQuery,
        filters: { ...filters, ...eesFilters },
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...filters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchMedicationExports = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyExports,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchMedicationExports, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchMedicationExports({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(
      `${exportEndpointUrl}?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchMedication = ({
  medication__id = "",
  as_staff,
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: medication__id, as_staff }],
    queryFn: () =>
      apiWrapper({ fn: fetchMedication, ...apiWrapperProps })({
        medication__id,
        as_staff,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchMedication({ medication__id = "", as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${medication__id}/?${queryParams}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createMedication({ data, as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().post(
      `${endpointUrl}?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateMedication({ data, as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function deleteMedication({ data, as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/activate/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateMedicationObjectTSQ({ object }) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1].id === object.id,
    object,
  });
}

export const useQueryFetchMedicationHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchMedicationHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchMedicationHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export function invalidateMedicationsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingular, queryKeyPlural, queryKeySingularHistory].includes(
        queryKey[0]
      ),
  });
}

export async function exportMedications({
  data = {},
  searchQuery = "",
  filters = {},
}) {
  try {
    const { ndc_tag_codes, ...otherFilters } = filters;
    const queryParams = getQueryParams({
      search: searchQuery,
      ...otherFilters,
    });
    const response = await AveyBloomApi().post(
      `${endpointUrl}export/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateMedicationExportObjectTSQ({ object }) {
  updatePaginatedTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyExports,
    object,
  });
}
