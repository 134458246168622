import React from "react";
import CustomListAvatarItem from "../CustomListAvatarItem";

export default function CustomActionDialogTitle({
  image,
  title,
  variant = "square",
  fullScreen = false,
}) {
  const imageProps = fullScreen
    ? { className: "sizeLg" }
    : { className: "sizeMd" };
  const textProps = fullScreen
    ? { variant: "h4", fontWeight: "bold" }
    : { variant: "h5", fontWeight: "bold" };
  const wrapperProps = fullScreen ? { spacing: 3 } : {};

  return (
    <CustomListAvatarItem
      imageProps={{ src: image, variant, ...imageProps }}
      textProps={{ children: title, ...textProps }}
      wrapperProps={wrapperProps}
    />
  );
}
