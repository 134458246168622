import {
  apiWrapper,
  AveyBloomApi,
  DibsyApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import { invalidateTSQ, useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/payments/organization_cards/";
const queryKeySingular = "organization_card";
const queryKeyPlural = "organization_cards";
const queryKeySingularPrimary = `${queryKeySingular}_primary`;

export const useQueryFetchOrganizationCards = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "-time_created",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationCards,
        ...apiWrapperProps,
      })({ page, page_size, searchQuery, sortQuery, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationCards({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchPrimaryOrganizationCard = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingularPrimary],
    queryFn: () =>
      apiWrapper({
        fn: fetchPrimaryOrganizationCard,
        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchPrimaryOrganizationCard() {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}retrieve_primary/`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createCardToken(data) {
  try {
    const response = await DibsyApi().post("/card-tokens/", data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response.data?.details,
    });
  }
}

export async function createOrReplaceOrganizationCard(
  data,
  objectIdToReplace = null
) {
  try {
    let response;
    if (Boolean(objectIdToReplace)) {
      response = await AveyBloomApi().post(
        `${endpointUrl}${objectIdToReplace}/replace/`,
        data
      );
    } else {
      response = await AveyBloomApi().post(endpointUrl, data);
    }
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function deleteOrganizationCard(id) {
  try {
    await AveyBloomApi().delete(`${endpointUrl}${id}/`);
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function makePrimaryOrganizationCard(id) {
  try {
    await AveyBloomApi().put(`${endpointUrl}${id}/make_primary/`);
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function invalidateOrganizationCardsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingularPrimary, queryKeyPlural].includes(queryKey[0]),
  });
}
