import React from "react";
import { getIsStaff } from "@constants/static/routing";

const StaffContext = React.createContext({
  as_staff: "0",
  isStaff: false,
});

export const useStaffContextProvider = () => React.useContext(StaffContext);

export const StaffContextProvider = ({ children, as_staff }) => {
  const isStaff = getIsStaff({ as_staff });
  return (
    <StaffContext.Provider value={{ as_staff, isStaff }}>
      {children}
    </StaffContext.Provider>
  );
};
