import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import { CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING } from "@constants/claims/claim-submission-uploads";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { useQueryFetchClaimSubmissionUploads } from "@redux/actions/claims/claim-submission-uploads";
import { useParams } from "react-router-dom";
import ClaimSubmissionUploadData from "../../ClaimSubmissionUpload/ClaimSubmissionUploadData";
import ClaimSubmissionUploadsActiveUpload from "./ClaimSubmissionUploadsActiveUpload";

export default function ClaimSubmissionUploadsActiveUploads({
  spacing = 2,
  name,
}) {
  const [state, setState] = React.useState({ isOpen: false, object: null });
  const { isOpen, object } = state;
  const { organization__slug, claim_submission__id } = useParams();
  const infiniteQuery = useQueryFetchClaimSubmissionUploads({
    sortQuery: "-time_created",
    filters: {
      organization__slug,
      claim_submission__id,
      preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
    },
  });

  const { isLoading, data } = infiniteQuery;

  const hasData = Boolean((data?.pages?.[0]?.results || []).length !== 0);

  const handleClickRow = (row) =>
    setState((prev) => ({ ...prev, isOpen: true, object: row }));
  const handleClose = (e) => {
    e?.preventDefault();
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <Collapse in={hasData && !isLoading}>
      <Box mt={spacing}>
        <CustomListItemCollapse
          primary="Active uploads"
          wrapperProps={{ width: "max-content" }}
        >
          <CustomInfiniteGrid
            customLoadingIndicator={null}
            displayNoResultsFound={false}
            infiniteQuery={infiniteQuery}
            ObjectComponent={ClaimSubmissionUploadsActiveUpload}
            objectComponentProps={{ handleClickRow }}
            gridContainerProps={{ spacing: 2 }}
            gridItemProps={{ xs: 12, md: 6, xl: 4 }}
          />
        </CustomListItemCollapse>
      </Box>
      <CustomActionDialog
        isOpen={isOpen}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        handleSubmit={handleClose}
        handleClose={handleClose}
        titleComponentProps={{ title: name }}
        isOk={true}
        okButtonProps={{ color: "info" }}
        okText="Close"
        maxWidth="sm"
      >
        <ClaimSubmissionUploadData object={object} />
      </CustomActionDialog>
    </Collapse>
  );
}
