import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  CONSUMABLES_TITLE,
  getConfigurationBaseHrefs,
  getConsumablesHref,
} from "@constants/static/routing";
import {
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ConsumableActivateForm from "../ConsumableForms/ConsumableActivateForm";
import ConsumableUpdateForm from "../ConsumableForms/ConsumableUpdateForm";
import ConsumableVersionHistory from "./ConsumableVersionHistory";

export function ConsumableHeaderTitle({ object, variant = "h3" }) {
  const { version, name, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function ConsumableHeader({ object }) {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { consumable__id } = useParams();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const { name, time_deleted, submitter_organization } = object || {};
  const { slug } = submitter_organization || {};
  const isSubmitterOrganization = slug === submitter_organization__slug;
  const title = name;
  const isDeleted = Boolean(time_deleted);
  const canUpdateObject =
    isStaff || (hasAdminPrivileges && isSubmitterOrganization);

  const hrefs = [
    ...getConfigurationBaseHrefs({ as_staff }),
    {
      label: CONSUMABLES_TITLE,
      href: getConsumablesHref({ as_staff }),
    },
    {
      label: title,
      href: getConsumablesHref({ consumable__id, as_staff }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={ConsumableHeaderTitle}
      titleComponentProps={{ object }}
    >
      {canUpdateObject && !isDeleted && (
        <ConsumableUpdateForm object={object} />
      )}
      <CustomMoreActions>
        <ConsumableVersionHistory />
        {canUpdateObject && <ConsumableActivateForm object={object} />}
      </CustomMoreActions>
    </CustomHeader>
  );
}
