import React from "react";
import CustomNoResultsFound from "@components/Custom/CustomNoResultsFound";
import Stack from "@mui/material/Stack";
import { selectorAuthHasSubmitterPrivileges } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ClaimSubmissionUploadCreateForm from "../../ClaimSubmissionUploads/ClaimSubmissionUpload/ClaimSubmissionUploadForms/ClaimSubmissionUploadCreateForm";
import ClaimSubmitterCreateClaimFormLink from "../Claim/Claim/ClaimSubmitterForms/ClaimSubmitterCreateClaimForm/ClaimSubmitterCreateClaimFormLink";

export default function ClaimsNoResults() {
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const noResultProps = getNoResultProps({ hasSubmitterPrivileges });

  return (
    <CustomNoResultsFound
      src="/assets/images/placeholders/no-results.png"
      wrapperProps={{
        spacing: 2,
        textAlign: "center",
        justifyContent: "center",
        minHeight: "50vh",
      }}
      {...noResultProps}
    />
  );
}

const getNoResultProps = ({ hasSubmitterPrivileges = false }) => {
  if (hasSubmitterPrivileges)
    return {
      subtitle: (
        <>
          {"No claims have been submitted yet."}
          <br />
          {"Start by submitting claims."}
        </>
      ),
      children: (
        <Stack spacing={2}>
          <ClaimSubmitterCreateClaimFormLink />
          <ClaimSubmissionUploadCreateForm />
        </Stack>
      ),
    };

  return { subtitle: "There are no claims yet." };
};
