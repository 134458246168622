import React from "react";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import { CustomTab, CustomTabs } from "@components/Custom/CustomTabs";
import { CLAIM_STATUS_ALL } from "@constants/claims/claim-status";
import { getUploadStatusFilterMapper } from "@constants/static/upload";
import { getIntOrFloat } from "@helpers/parse/numbers";

export default function ConfigurationsBulkUploadStatusFilters({
  object,
  setStatusTab = () => {},
}) {
  const [value, setValue] = React.useState(0);
  const { entries_summary = {}, use_processing_status = false } = object;

  const { textOptions, propsMapper } = React.useMemo(
    () => getUploadStatusFilterMapper({ use_processing_status }),
    [use_processing_status]
  );

  const { num_entries, entry_counts: tabCountData = {} } =
    entries_summary || {};

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setStatusTab(textOptions[newValue]["key"]);
  };

  return (
    <CustomTabs value={value} onChange={handleChange} useUnderline={false}>
      {textOptions.map(({ key, value }, index) => (
        <CustomTab
          key={key}
          label={value}
          value={index}
          icon={
            <CustomStatusBox
              status={key}
              propsMapper={propsMapper}
              value={getIntOrFloat(
                key === CLAIM_STATUS_ALL
                  ? num_entries || "0"
                  : (tabCountData || {})[key] || "0"
              )}
              wrapperProps={{ ml: 1 }}
            />
          }
          iconPosition="end"
        />
      ))}
    </CustomTabs>
  );
}
