import React from "react";
import ConfigurationsBulkExportsGrid from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExports/ConfigurationsBulkExportsGrid";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import ConfigurationsBulkExportsHeader from "./ConfigurationsBulkExportsHeader";

export default function ConfigurationsBulkExports() {
  return (
    <>
      <ConfigurationsBulkExportsHeader />
      <CustomPage mt={0}>
        <ConfigurationsBulkExportsGrid />
      </CustomPage>
    </>
  );
}
