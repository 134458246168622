import {
  ServerApi,
  apiWrapper,
  emptyPaginatedResponse,
  parsePaginatedResponse,
} from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/api/phoenix/";
const instancesQueryKey = "instances";
const conceptFindingsQueryKey = "concept_findings";

export const useQueryFetchConceptFindings = ({
  searchQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [conceptFindingsQueryKey, { searchQuery, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchConceptFindings,
        ...apiWrapperProps,
      })({ page: pageParam, searchQuery, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

export async function fetchConceptFindings(data) {
  try {
    const response = await ServerApi().post(
      `${endpointUrl}concept-findings/`,
      data
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchInstances = ({
  searchQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [instancesQueryKey, { searchQuery, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchInstances,
        ...apiWrapperProps,
      })({ page: pageParam, searchQuery, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

export async function fetchInstances(data) {
  try {
    const response = await ServerApi().post(`${endpointUrl}instances/`, data);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
