import React from "react";
import { renderOptionCountry } from "@components/Custom/CustomAutocomplete/CountryAutocomplete/CountryAutocompleteComponents";
import CustomDividedList from "@components/Custom/CustomDividedList";
import Typography from "@mui/material/Typography";

export default function IdentityTypeCountriesData({
  countries = [],
  divideAt = 2,
}) {
  const isUniversal = countries.length === 0;
  const list = countries.map((country) => ({
    children: renderOptionCountry({}, country),
    color: "white",
  }));

  if (isUniversal)
    return <Typography variant="body2">{"Universal"}</Typography>;

  return (
    <CustomDividedList
      divideAt={divideAt}
      list={list}
      tooltipProps={{ bgColor: "transparent", padding: 0 }}
    />
  );
}
