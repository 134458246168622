import {
  BLACK_PILL,
  BLUE_PILL,
  RED_PILL,
  YELLOW_PILL,
} from "@components/Custom/CustomPills";

export const CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING = "P";
export const CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS = "S";
export const CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE = "F";
export const CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED = "C";

export const CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER = {
  [CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING]: "Processing",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS]: "Success",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE]: "Failure",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED]: "Canceled",
};

export const CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER = {
  [CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING]: BLACK_PILL,
  [CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS]: BLUE_PILL,
  [CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE]: RED_PILL,
  [CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED]: YELLOW_PILL,
};

export const CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER = {
  [CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING]: "primary",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS]: "success",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE]: "error",
  [CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED]: "tertiary",
};
