import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import Custom404 from "@components/Custom/Custom404";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import {
  formatClaimFormData,
  getEmptyClaimFormData,
} from "@constants/claims/claims";
import { getClaimSubmissionHref } from "@constants/static/routing";
import {
  CLAIM_MODE_MAPPER,
  CLAIM_MODE_SUBMISSION_MAPPER,
} from "@constants/static/system-modes";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import { submitClaim } from "@redux/actions/claims/claims";
import {
  selectorAuthClaimMode,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ClaimBody from "../../ClaimBody";
import ClaimRedirectToLatestVersion from "../../ClaimRedirectToLatestVersion";
import ClaimReviewAndConfirm from "../../ClaimReviewAndConfirm";
import { ClaimContext } from "../../context";

const isCreateOrUpdateMode = true;

export default function ClaimSubmitterCreateClaimForm() {
  const { organization__slug, claim_submission__id } = useParams();
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const claimMode = useSelector(selectorAuthClaimMode);
  const claimModeStr = pluralize(CLAIM_MODE_SUBMISSION_MAPPER[claimMode]);
  const submitText = CLAIM_MODE_MAPPER[claimMode] + " claim";
  const {
    isAgreementDisabled,
    isLoading: isLoadingAgreement,
    icd_type,
  } = useFetchOrganizationAgreement();
  const { data: claim_submission, isLoading: isLoadingClaimSubmission } =
    useFetchClaimSubmission();
  const { currency, processor_organization } = claim_submission || {};
  const initialClaimFormData = React.useMemo(
    () => getEmptyClaimFormData({ currency }),
    [currency]
  );
  const { meta_mds_cols = [] } = processor_organization || {};

  const [latestVersionId, setLatestVersionId] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(initialClaimFormData);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert && !latestVersionId;
  const dialogRef = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    setFormData(initialClaimFormData);
    setAlertState(initialAlertState);
    setSummaryState(initialSummaryState);
    setLatestVersionId(null);
    setIsProcessing(false);
    dialogRef.current &&
      dialogRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [initialClaimFormData]);

  const handleResetForm = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setFormData(initialClaimFormData);
    setAlertState(initialAlertState);
    setSummaryState(initialSummaryState);
    setLatestVersionId(null);
    setIsProcessing(false);
    dialogRef.current &&
      dialogRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNavigate = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    navigate(
      getClaimSubmissionHref({
        organization__slug,
        claim_submission__id,
      })
    );
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.stopPropagation();
    e.preventDefault();
    const formattedFormData = formatClaimFormData({
      formData,
      isCheck,
      claim_submission__id,
      processor_organization__slug: organization__slug,
    });

    setIsProcessing(true);
    submitClaim(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: "Claim successfully submitted",
          });
          setLatestVersionId(instance.id);
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) =>
        setAlertState({ isOpen: true, errorStr: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  if (isLoadingAgreement || isLoadingClaimSubmission)
    return (
      <CustomActionDialog
        isOpen={true}
        displayActions={false}
        displayCloseIcon={false}
        fullScreen={true}
        useDivider={false}
      >
        <CustomBackdrop />
      </CustomActionDialog>
    );

  if (!Boolean(claim_submission) || !hasSubmitterPrivileges)
    return <Custom404 />;

  if (isAgreementDisabled)
    return (
      <CustomActionDialog
        isOpen={true}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: `${claimModeStr} disabled` }}
        subtitleComponentProps={{
          title: `${claimModeStr} have been disabled for this organization.`,
        }}
        handleSubmit={handleNavigate}
        handleClose={handleNavigate}
        isBodyEmpty={true}
        isOk={true}
      />
    );

  return (
    <ClaimContext.Provider
      value={{
        claim: formData,
        setFormData,
        isCreateOrUpdateMode,
        meta_mds_cols,
        icd_type,
      }}
    >
      <CustomActionDialog
        isOpen={true}
        isProcessing={isProcessing}
        handleClose={handleNavigate}
        handleSubmit={handleCheckData}
        fullScreen={true}
        useCustomCloseIcon={true}
        disableEscapeKeyDown={true}
        dialogContentProps={{ ref: dialogRef }}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "New claim", fullScreen: true }}
        yesText={submitText}
        yesButtonProps={{ icon: "TickIcon", iconProps: { dimension: 14 } }}
      >
        <ClaimBody />
      </CustomActionDialog>
      {isOpenSummaryDialog && (
        <CustomSummaryDialog
          summaries={summaries}
          isOpen={true}
          isProcessing={isProcessing}
          setSummaryState={setSummaryState}
          handleSubmit={handleSubmitData}
          ContentComponent={ClaimReviewAndConfirm}
          contentComponentProps={{ claim: formData }}
          summaryDialogProps={{ maxWidth: "lg", isOk: false }}
          TitleComponent={InsuranceOrganizationActionDialogTitle}
        />
      )}
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      <ClaimRedirectToLatestVersion
        isCreateOrUpdateMode={isCreateOrUpdateMode}
        latestVersionId={latestVersionId}
        handleResetForm={handleResetForm}
      />
    </ClaimContext.Provider>
  );
}
