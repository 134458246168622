import React from "react";
import { CustomQueryParams } from "@components/Custom/CustomAppliedFilters";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import CustomSection from "@components/Custom/CustomSection";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/claims/claim-submission-uploads";
import { parseTimestamp } from "@helpers/parse/time";
import Stack from "@mui/material/Stack";
import { describeFilters } from "../../Claims/Claims/ClaimsFilters";
import ClaimSubmissionUploadDataExceptionsJson from "./ClaimSubmissionUploadDataExceptionsJson";

export default function ClaimSubmissionUploadData({ object = {} }) {
  const {
    submitter_user,
    file,
    preprocessing_time_start,
    preprocessing_time_end,
    exceptions_json,
    num_claims,
    query_params = {},
    preprocessing_status,
  } = object || {};

  const { first_name, last_name } = submitter_user;
  const name = `${first_name || ""} ${last_name || ""}`.trim();
  const timeStarted = parseTimestamp({
    value: preprocessing_time_start,
    useSeconds: true,
  });
  const timeEnded = parseTimestamp({
    value: preprocessing_time_end,
    useSeconds: true,
  });
  const hasFile = Boolean(file);

  return (
    <Stack spacing={3}>
      <CustomSection title="Upload summary" isMinified={true}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomGrid>
            <CustomGrid>
              <CustomLabeledText label="Submitted by" value={name} />
              <CustomLabeledText label="Status" useChildren={true}>
                <CustomStatusBox
                  status={preprocessing_status}
                  textMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER}
                  propsMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER}
                />
              </CustomLabeledText>
              <CustomLabeledText label="Claims" value={num_claims} />
              {hasFile ? (
                <CustomLabeledText label="Uploaded file" useChildren={true}>
                  <CustomExternalLink
                    href={file}
                    label="Download file"
                    wrapperProps={{ download: true }}
                    isTypography={true}
                  />
                </CustomLabeledText>
              ) : (
                <CustomQueryParams
                  query_params={query_params}
                  describeFilters={describeFilters}
                />
              )}
            </CustomGrid>
            <CustomGrid>
              <CustomLabeledText label="Time started" value={timeStarted} />
              <CustomLabeledText label="Time ended" value={timeEnded} />
            </CustomGrid>
          </CustomGrid>
        </Stack>
      </CustomSection>
      <ClaimSubmissionUploadDataExceptionsJson
        exceptions_json={exceptions_json}
      />
    </Stack>
  );
}
