import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { DRUG_NAME_TYPE_MAPPER } from "@constants/avey/drug-names";
import { getDrugNamesHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";
import HealthcareOrganizationTag from "../../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationTag";

export default function DrugNamesGridProps({ as_staff, isStaff }) {
  const hiddenColumns = isStaff ? [] : ["submitter_organization"];
  return {
    columns: [
      {
        flex: 1,
        field: "submitter_organization",
        headerName: "Healthcare provider",
        renderCell: ({ value }) => (
          <HealthcareOrganizationTag
            submitter_organization={value}
            useLabel={false}
          />
        ),
      },
      {
        flex: 1,
        field: "name",
        headerName: "Name",
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value}
          </Typography>
        ),
      },
      {
        flex: 1,
        field: "description",
        headerName: "Description",
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value}
          </Typography>
        ),
      },
      {
        flex: 1,
        field: "drug_name_type",
        headerName: "Type",
        valueGetter: ({ value }) => DRUG_NAME_TYPE_MAPPER[value] || "-",
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) => getDrugNamesHref({ drug_name__id: id, as_staff }),
  };
}
