import { getClaimSubmissionRevelantOrganization } from "@constants/claims/claim-submissions";
import { updateClaimSubmissionObjectTSQ } from "@redux/actions/claims/claim-submissions";
import { updateOrganizationTSQ } from "@redux/actions/organizations/organizations";

export default function handleWsClaimSubmission({
  object = {},
  organizationRole,
}) {
  const organization = getClaimSubmissionRevelantOrganization({
    object,
    organizationRole,
  });
  updateOrganizationTSQ({ object: organization });
  updateClaimSubmissionObjectTSQ({ object });
  return null;
}
