import React from "react";
import ConceptFindingAutocomplete from "@components/Custom/CustomAutocomplete/PhoenixAutocomplete/ConceptFindingAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import { CustomOutlinedField } from "@components/Custom/CustomOutlinedField";
import CustomTooltip from "@components/Custom/CustomTooltip";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import {
  SYMPTOM_STATUS_ICON_MAPPER,
  SYMPTOM_STATUS_PRESENT,
  SYMPTOM_STATUS_PRESENT_NEXT_STATUS_MAPPER,
  SYMPTOM_STATUS_TOOLTIP_MAPPER,
  SYMPTOM_TYPE_SUBJECTIVE,
} from "@constants/avey-cowriter";
import { PHOENIX_GENDER_BOTH } from "@constants/organizations/organization-patients";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  emptySymptomFormData,
  selectorClaimDemoParsedOrganizationPatient,
  useClaimDemoContext,
} from "../context";

export default function ClaimDemoSymptomsAddSymptom({
  type,
  label = "+ Add symptom",
}) {
  const { state, handleAddClaimSymptom } = useClaimDemoContext();
  const parsedOrganizationPatient =
    selectorClaimDemoParsedOrganizationPatient(state);
  const { ageInt, phoenixGender } = parsedOrganizationPatient;
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(emptySymptomFormData);
  const { status, is_chief } = formData;

  const isSubjective = type === SYMPTOM_TYPE_SUBJECTIVE;
  const isPresent = status === SYMPTOM_STATUS_PRESENT;

  const useQueryFnProps = {
    filters: {
      age_lower_limit__lte: ageInt,
      age_upper_limit__gte: ageInt,
      gender__in: [PHOENIX_GENDER_BOTH, phoenixGender],
      ...(is_chief && isSubjective ? { is_searchable: true } : {}),
      is_production: true,
      is_askable: true,
    },
  };

  const handleIsOpen = () => setIsOpen(!isOpen);
  const handleChangeObject = (object) => {
    handleAddClaimSymptom({
      object,
      type,
      is_chief,
      status,
    });
    setIsOpen(false);
  };
  const handleChangeStatus = () =>
    setFormData((prev) => ({
      ...prev,
      status: SYMPTOM_STATUS_PRESENT_NEXT_STATUS_MAPPER[prev.status],
    }));
  const handleChangeIsChief = () =>
    setFormData((prev) => ({ ...prev, is_chief: !prev.is_chief }));

  React.useEffect(() => {
    isOpen && setFormData(emptySymptomFormData);
  }, [isOpen]);

  if (!isOpen)
    return (
      <CustomTypographyButton
        value={label}
        onClick={handleIsOpen}
        style={{ minHeight: 40, alignItems: "center", display: "grid" }}
      />
    );

  return (
    <div>
      <Grid container spacing={1.5} alignItems="center">
        <Grid item>
          <CustomTooltip
            title={SYMPTOM_STATUS_TOOLTIP_MAPPER[status]}
            tooltipProps={{ placement: "left" }}
          >
            <IconButton
              size="large"
              onClick={handleChangeStatus}
              className="outlined square"
            >
              {SYMPTOM_STATUS_ICON_MAPPER[status]}
            </IconButton>
          </CustomTooltip>
        </Grid>
        {isSubjective && isPresent && (
          <Grid item>
            <CustomOutlinedField
              wrapperProps={{
                width: "max-content",
                className: "button",
                onClick: handleChangeIsChief,
              }}
            >
              <Typography
                variant="caption"
                fontWeight="bold"
                color={is_chief ? "textPrimary" : "textSecondary"}
                className={is_chief ? "" : "del"}
                px={1}
              >
                {"Chief"}
              </Typography>
            </CustomOutlinedField>
          </Grid>
        )}
        <Grid item xs>
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            width="100%"
            minWidth={250}
          >
            <ConceptFindingAutocomplete
              value={null}
              handleChange={handleChangeObject}
              useQueryFnProps={useQueryFnProps}
            />
            <CustomButton
              icon="CloseIcon"
              variant="outlined"
              isSquare={true}
              useIconAsLabel={true}
              onClick={handleIsOpen}
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
