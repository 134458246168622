import React from "react";
import { getIntOrFloat } from "@helpers/parse/numbers";
import CustomTooltip from "./CustomTooltip";
import CustomValueWithUnit from "./CustomValueWithUnit";

export default function CustomProgressBar({ nominator = 0, denominator = 1 }) {
  const percent = nominator === 0 ? 0 : (100 * nominator) / denominator;

  return (
    <CustomTooltip
      title={
        <CustomValueWithUnit
          value={getIntOrFloat(nominator)}
          separator="/"
          unit={getIntOrFloat(denominator)}
        />
      }
    >
      <CustomValueWithUnit
        value={getIntOrFloat({
          value: percent,
          minNumSigFig: 0,
          maxNumSigFig: 2,
        })}
        valueTypographyProps={{ fontWeight: "bold" }}
        unit="%"
      />
    </CustomTooltip>
  );
}
