import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import Grid from "@mui/material/Grid";
import { useQueryFetchSpecialities } from "@redux/actions/avey/specialities";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./SpecialitiesFilters";
import SpecialitiesGridProps from "./SpecialitiesGridProps";
import SpecialityCreateForms from "./SpecialityCreateForms";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function SpecialitiesGrid() {
  const { as_staff } = useStaffContextProvider();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true, as_staff, ...filters },
  };
  const dataGridProps = React.useMemo(
    () => SpecialitiesGridProps({ as_staff }),
    [as_staff]
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item>
          <SpecialityCreateForms />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchSpecialities}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </div>
  );
}
