import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const isOptionEqualToValueCountry = (option, value) =>
  option?.id === value?.id || option?.code === value?.code;

export const getOptionLabelCountry = (option) =>
  `${option?.flag} ${option?.name}`;

export const renderOptionCountry = (props, option) => {
  const { flag, name } = option;
  return (
    <div {...props} title={name}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="body2">{flag}</Typography>
        <Typography variant="body2">{name}</Typography>
      </Stack>
    </div>
  );
};
