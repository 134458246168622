import React from "react";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import {
  UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER,
  UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER,
  UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER,
  UPLOAD_ENTRY_STATUS_PENDING,
} from "@constants/static/upload";

export default function ConfigurationsBulkUploadEntryStatus({
  preprocessing_status,
  processing_status,
}) {
  const isProcessing = processing_status !== UPLOAD_ENTRY_STATUS_PENDING;
  const status = isProcessing ? processing_status : preprocessing_status;
  const textMapper = isProcessing
    ? UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER
    : UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER;

  return (
    <CustomStatusBox
      propsMapper={UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER}
      textMapper={textMapper}
      status={status}
    />
  );
}
