import handleWsClaim from "./handleWsClaim";
import handleWsClaimSubmission from "./handleWsClaimSubmission";
import handleWsClaimSubmissionUpload from "./handleWsClaimSubmissionUpload";
import handleWsExport from "./handleWsExport";
import handleWsUpload from "./handleWsUpload";
import handleWsUploadEntry from "./handleWsUploadEntry";

const WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION_UPLOAD =
  "CLAIM_SUBMISSION_UPLOAD";
const WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION = "CLAIM_SUBMISSION";
const WEBSOCKET_NOTIFICATION_TYPE_CLAIM = "CLAIM";
const WEBSOCKET_NOTIFICATION_TYPE_EXPORT = "EXPORT";
const WEBSOCKET_NOTIFICATION_TYPE_UPLOAD = "UPLOAD";
const WEBSOCKET_NOTIFICATION_TYPE_UPLOAD_ENTRY = "UPLOAD_ENTRY";

export const WEBSOCKET_NOTIFICATION_TYPE_HANDLER_FN_MAPPER = {
  [WEBSOCKET_NOTIFICATION_TYPE_CLAIM]: handleWsClaim,
  [WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION]: handleWsClaimSubmission,
  [WEBSOCKET_NOTIFICATION_TYPE_CLAIM_SUBMISSION_UPLOAD]:
    handleWsClaimSubmissionUpload,
  [WEBSOCKET_NOTIFICATION_TYPE_EXPORT]: handleWsExport,
  [WEBSOCKET_NOTIFICATION_TYPE_UPLOAD_ENTRY]: handleWsUploadEntry,
  [WEBSOCKET_NOTIFICATION_TYPE_UPLOAD]: handleWsUpload,
};
