import React from "react";
import CustomTooltip from "./CustomTooltip";
import { InfoIcon } from "@rimads/assets";

export default function CustomMoreInformationIcon({
  children,
  tooltipProps = {},
}) {
  return (
    <CustomTooltip title={children} tooltipProps={tooltipProps}>
      <InfoIcon />
    </CustomTooltip>
  );
}
