import { useParams } from "react-router-dom";
import HealthcareOrganizationActionDialogTitle from "../HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";
import InsuranceOrganizationActionDialogTitle from "../InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";

export default function AppConfigurationsTitleComponent({ isStaff }) {
  const { organization__slug } = useParams();

  if (Boolean(organization__slug))
    return InsuranceOrganizationActionDialogTitle;

  if (isStaff) return null;

  return HealthcareOrganizationActionDialogTitle;
}
