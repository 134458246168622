import React from "react";
import ConfigurationsBulkExports from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExports";
import InsuranceOrganizationsConfigurations from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations";
import OrganizationBundle from "@components/Authenticated/Configurations/OrganizationBundles/OrganizationBundle/OrganizationBundle";
import OrganizationPatient from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatient";
import OrganizationPolicy from "@components/Authenticated/Configurations/OrganizationPolicies/OrganizationPolicy/OrganizationPolicy";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { StaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import {
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import {
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "@constants/static/globals";
import {
  ORGANIZATION_BUNDLE_MEDICATIONS_EXPORTS_HREF,
  ORGANIZATION_BUNDLE_MEDICATIONS_HREF,
  ORGANIZATION_BUNDLE_MEDICATIONS_UPLOADS_HREF,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_EXPORTS_HREF,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_UPLOADS_HREF,
  ORGANIZATION_PATIENT_EXPORTS_HREF,
  ORGANIZATION_PATIENT_UPLOADS_HREF,
  ORGANIZATION_PATIENTS_HREF,
  ORGANIZATION_POLICIES_HREF,
  ORGANIZATION_POLICY_EXPORTS_HREF,
  ORGANIZATION_POLICY_UPLOADS_HREF,
} from "@constants/static/routing";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import { Navigate, Route, Routes } from "react-router-dom";
import UploadRoutes from "../upload-routes";

export default function InsuranceOrganizationConfigurationsRoutes() {
  const { isLoading, hasData } = useFetchOrganization();

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <StaffContextProvider as_staff="0">
      <Routes>
        <Route path="" Component={InsuranceOrganizationsConfigurations} />

        {/* ORGANIZATION PATIENTS */}
        <Route
          path={`${ORGANIZATION_PATIENTS_HREF}/:organization_patient__id/*`}
          Component={OrganizationPatient}
        />
        <Route
          path={`${ORGANIZATION_PATIENT_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_PATIENT}
            >
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_PATIENT_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_PATIENT}
            >
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />

        {/* ORGANIZATION POLICIES */}
        <Route
          path={`${ORGANIZATION_POLICIES_HREF}/:organization_policy__id/*`}
          Component={OrganizationPolicy}
        />
        <Route
          path={`${ORGANIZATION_POLICY_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_POLICY}
            >
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_POLICY_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_POLICY}
            >
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />

        {/* ORGANIZATION BUNDLES - PROCEDURES & CONSUMABLES */}
        <Route
          path={`${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF}/:organization_bundle__id/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES}
            >
              <OrganizationBundle />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES}
            >
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES}
            >
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />

        {/* ORGANIZATION BUNDLES - MEDICATIONS */}
        <Route
          path={`${ORGANIZATION_BUNDLE_MEDICATIONS_HREF}/:organization_bundle__id/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_MEDICATIONS}
            >
              <OrganizationBundle />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_BUNDLE_MEDICATIONS_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_MEDICATIONS}
            >
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_BUNDLE_MEDICATIONS_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
              bundle_type={ORGANIZATION_BUNDLE_TYPE_MEDICATIONS}
            >
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />

        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </StaffContextProvider>
  );
}
