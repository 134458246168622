import React from "react";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import {
  AZURE_ICD_ENABLED,
  EES_ENABLED,
  GCP_ICD_ENABLED,
} from "@constants/static/globals";
import Grid from "@mui/material/Grid";
import { useQueryFetchIcds } from "@redux/actions/avey/icds";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./IcdsFilters";
import IcdsGridProps from "./IcdsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function IcdsGrid() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true, ...filters },
  };
  const isClientPagination =
    Boolean(searchQuery) &&
    EES_ENABLED &&
    (AZURE_ICD_ENABLED || GCP_ICD_ENABLED);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item flexGrow={1}>
        <CustomContextSearchField setSearchQuery={setSearchQuery} />
      </Grid>
      <Grid item>
        <CustomFilters
          initialFilterState={initialFilterState}
          filterOptions={filterOptions}
          formatFilters={formatFilters}
          setFilters={setFilters}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInfiniteDataGrid
          useQueryFn={useQueryFetchIcds}
          useQueryFnProps={useQueryFnProps}
          dataGridProps={IcdsGridProps}
          initialSortModel={initialSortModel}
          isClientPagination={isClientPagination}
        />
      </Grid>
    </Grid>
  );
}
