import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  DESCRIBE_FILTERS_EXPORT_MAPPER,
  FN_EXPORT_MAPPER,
  getExportsHref,
  LABEL_EXPORT_MAPPER,
} from "@constants/static/routing";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useParams } from "react-router-dom";
import AppConfigurationsTitleComponent from "../../AppConfigurations/AppConfigurationsTitleComponent";
import ConfigurationsBulkExportForm from "./ConfigurationsBulkExportForm";

export default function ConfigurationsBulkExportActions({ useQueryFnProps }) {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { model_type, bundle_type } = useModelTypeContextProvider();
  const { organization__slug } = useParams();
  const label = LABEL_EXPORT_MAPPER[model_type];
  const exportFn = FN_EXPORT_MAPPER[model_type];
  const describeFilters = DESCRIBE_FILTERS_EXPORT_MAPPER[model_type];
  const TitleComponent = AppConfigurationsTitleComponent({ isStaff });
  const exportHref = getExportsHref({
    model_type,
    bundle_type,
    organization__slug,
    as_staff,
  });

  return (
    <CustomMoreActions
      icon="DownloadIcon"
      useRotateProps={{ useRotate: false }}
    >
      <ConfigurationsBulkExportForm
        label={label}
        submitFn={exportFn}
        useQueryFnProps={useQueryFnProps}
        TitleComponent={TitleComponent}
        describeFilters={describeFilters}
      />
      <NavLink to={exportHref}>
        <MenuItem>{"View export history"}</MenuItem>
      </NavLink>
    </CustomMoreActions>
  );
}
