import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  IDENTITY_TYPES_TITLE,
  getConfigurationBaseHrefs,
  getIdentityTypesHref,
} from "@constants/static/routing";
import { useParams } from "react-router-dom";
import IdentityTypeActivateForm from "../IdentityTypeForms/IdentityTypeActivateForm";
import IdentityTypeUpdateForm from "../IdentityTypeForms/IdentityTypeUpdateForm";
import IdentityTypeVersionHistory from "./IdentityTypeVersionHistory";

export function IdentityTypeHeaderTitle({ object, variant = "h3" }) {
  const { version, name, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function IdentityTypeHeader({ object }) {
  const { as_staff } = useStaffContextProvider();
  const { identity_type__id } = useParams();
  const { code, name, time_deleted } = object || {};
  const title = code || name;
  const isDeleted = Boolean(time_deleted);
  const hrefs = [
    ...getConfigurationBaseHrefs({ as_staff }),
    {
      label: IDENTITY_TYPES_TITLE,
      href: getIdentityTypesHref({ as_staff }),
    },
    {
      label: title,
      href: getIdentityTypesHref({ identity_type__id, as_staff }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={IdentityTypeHeaderTitle}
      titleComponentProps={{ object }}
    >
      {!isDeleted && <IdentityTypeUpdateForm object={object} />}
      <CustomMoreActions>
        <IdentityTypeVersionHistory />
        <IdentityTypeActivateForm object={object} />
      </CustomMoreActions>
    </CustomHeader>
  );
}
