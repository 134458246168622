import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const emptyOrganizationClinicianFormData = {
  first_name: "",
  last_name: "",
  clinician_id: "",
  specialities: [],
};

export const formatOrganizationClinicianFormData = ({ isCheck, formData }) => {
  const {
    id = undefined,
    first_name,
    last_name,
    clinician_id,
    specialities = [],
  } = formData;

  return {
    id,
    is_check: isCheck,
    first_name: first_name?.trim(),
    last_name: last_name?.trim(),
    clinician_id: clinician_id?.trim(),
    specialities,
  };
};

const organizationClinicianRowToObject = (row) => {
  const { first_name, last_name, clinician_id, speciality_name } = getRowValue({
    row,
    columns: [
      { headerName: "FIRST NAME", fieldname: "first_name" },
      { headerName: "LAST NAME", fieldname: "last_name" },
      { headerName: "CLINICIAN ID", fieldname: "clinician_id" },
      { headerName: "SPECIALITY", fieldname: "speciality_name" },
    ],
  });

  const isRowEmpty = !(clinician_id || first_name || last_name);
  return {
    id: isRowEmpty ? null : uuidv4(),
    clinician_id,
    first_name,
    last_name,
    speciality_name,
  };
};

export const organizationClinicianRowsToObjects = (rows) => {
  const finalDict = {};
  rows
    .map((row) => organizationClinicianRowToObject(row))
    .filter(({ id }) => Boolean(id))
    .forEach(({ id, clinician_id, first_name, last_name, speciality_name }) => {
      const prevInstance = {
        ...(finalDict[clinician_id] || emptyOrganizationClinicianFormData),
      };
      const newInstance = {
        id,
        clinician_id,
        first_name,
        last_name,
      };
      const object = { ...prevInstance, ...newInstance };
      if (Boolean(speciality_name)) {
        finalDict[clinician_id] = {
          ...object,
          specialities: [...object.specialities, { name: speciality_name }],
        };
      } else {
        finalDict[clinician_id] = object;
      }
    });

  return Object.values(finalDict);
};
