import React from "react";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteUnit,
  invalidateUnitsTSQ,
  updateUnitObjectTSQ,
} from "@redux/actions/avey/units";

export default function UnitActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateUnitsTSQ();
    updateUnitObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="unit"
      deleteFn={deleteUnit}
      handleDeleteFn={handleDeleteFn}
    />
  );
}
