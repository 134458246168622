import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  SPECIALITIES_TITLE,
  getConfigurationBaseHrefs,
  getSpecialitiesHref,
} from "@constants/static/routing";
import { useParams } from "react-router-dom";
import SpecialityActivateForm from "../SpecialityForms/SpecialityActivateForm";
import SpecialityUpdateForm from "../SpecialityForms/SpecialityUpdateForm";
import SpecialityVersionHistory from "./SpecialityVersionHistory";

export function SpecialityHeaderTitle({ object, variant = "h3" }) {
  const { version, name, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function SpecialityHeader({ object }) {
  const { as_staff } = useStaffContextProvider();
  const { speciality__id } = useParams();
  const { name, time_deleted } = object || {};
  const title = name;
  const isDeleted = Boolean(time_deleted);
  const hrefs = [
    ...getConfigurationBaseHrefs({ as_staff }),
    {
      label: SPECIALITIES_TITLE,
      href: getSpecialitiesHref({ as_staff }),
    },
    {
      label: title,
      href: getSpecialitiesHref({ speciality__id, as_staff }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={SpecialityHeaderTitle}
      titleComponentProps={{ object }}
    >
      {!isDeleted && <SpecialityUpdateForm object={object} />}
      <CustomMoreActions>
        <SpecialityVersionHistory />
        <SpecialityActivateForm object={object} />
      </CustomMoreActions>
    </CustomHeader>
  );
}
