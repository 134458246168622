import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  getOrganizationBundlesHref,
  getOrganizationSetupDefaultHref,
  INSURANCE_CONFIGURATIONS_BASE_HREFS,
  ORGANIZATION_BUNDLE_TITLE_MAPPER,
} from "@constants/static/routing";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { selectorAuthHasAdminPrivileges } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OrganizationBundleActivateForm from "../OrganizationBundleForms/OrganizationBundleActivateForm";
import OrganizationBundleUpdateForm from "../OrganizationBundleForms/OrganizationBundleUpdateForm";
import OrganizationBundleVersionHistory from "./OrganizationBundleVersionHistory";

export function OrganizationBundleHeaderTitle({ object, variant = "h3" }) {
  const { version, internal_code, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={internal_code}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function OrganizationBundleHeader({ object }) {
  const { bundle_type } = useModelTypeContextProvider();
  const { organization_bundle__id, organization__slug } = useParams();
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { internal_code, time_deleted } = object || "";
  const isDeleted = Boolean(time_deleted);
  const title = internal_code || organization_bundle__id;
  const hrefs = [
    ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
    {
      label: organization?.name || organization__slug,
      href: getOrganizationSetupDefaultHref({ organization__slug }),
    },
    {
      label: ORGANIZATION_BUNDLE_TITLE_MAPPER[bundle_type],
      href: getOrganizationBundlesHref({ bundle_type, organization__slug }),
    },
    {
      label: title,
      href: getOrganizationBundlesHref({
        bundle_type,
        organization__slug,
        organization_bundle__id,
      }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={OrganizationBundleHeaderTitle}
      titleComponentProps={{ object }}
    >
      {hasAdminPrivileges && !isDeleted && (
        <OrganizationBundleUpdateForm object={object} />
      )}
      <CustomMoreActions>
        <OrganizationBundleVersionHistory />
        {hasAdminPrivileges && (
          <OrganizationBundleActivateForm object={object} />
        )}
      </CustomMoreActions>
    </CustomHeader>
  );
}
