import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import DrugNameCreateForm from "../DrugName/DrugNameForms/DrugNameCreateForm";

export default function DrugNameCreateForms() {
  return (
    <CustomAddOrUploadWrapper label="Add drug names" useUploadForm={false}>
      <DrugNameCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
