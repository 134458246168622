import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import OrganizationPatientDetailsData from "../OrganizationPatientData/OrganizationPatientDetailsData";
import OrganizationPatientPolicyDetailsData from "./OrganizationPatientPolicyDetailsData";

export default function OrganizationPatientData({
  object = {},
  isMinified = false,
  useCustomSection = true,
  useCustomCollapse = true,
}) {
  const spacing = isMinified ? 2 : 3;
  const useDivider = !useCustomSection;

  if (!Boolean(object)) return null;

  if (!(useCustomSection || useCustomCollapse))
    return (
      <CustomGrid>
        <OrganizationPatientDetailsData
          object={object}
          useCustomSection={false}
          useCustomCollapse={false}
          isMinified={true}
        />
        <OrganizationPatientPolicyDetailsData
          object={object}
          useCustomSection={false}
          useCustomCollapse={false}
          isMinified={true}
        />
      </CustomGrid>
    );

  return (
    <Stack spacing={spacing}>
      <OrganizationPatientDetailsData
        object={object}
        useCustomSection={useCustomSection}
        useCustomCollapse={useCustomCollapse}
        isMinified={isMinified}
      />
      {useDivider && <Divider />}
      <OrganizationPatientPolicyDetailsData
        object={object}
        useCustomSection={useCustomSection}
        useCustomCollapse={useCustomCollapse}
        isMinified={isMinified}
      />
    </Stack>
  );
}
