import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getIdentityTypesHref } from "@constants/static/routing";
import { useQueryFetchIdentityType } from "@redux/actions/avey/identity-types";
import { Navigate, useParams } from "react-router-dom";
import IdentityTypeData from "./IdentityTypeData";
import IdentityTypeHeader from "./IdentityTypeHeader";

export default function IdentityType() {
  const { as_staff } = useStaffContextProvider();
  const { identity_type__id } = useParams();
  const { isLoading, data } = useQueryFetchIdentityType({
    identity_type__id,
    as_staff,
  });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (Boolean(latest_version_id) && latest_version_id !== identity_type__id) {
    const latestVersionHref = getIdentityTypesHref({
      identity_type__id: latest_version_id,
      as_staff,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <IdentityTypeHeader object={data} />
      <CustomPage>
        <IdentityTypeData object={data} />
      </CustomPage>
    </>
  );
}
