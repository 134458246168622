import React from "react";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/claims/claim-submission-uploads";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimeSince } from "@helpers/parse/time";

export default function ClaimSubmissionUploadsGridProps({
  handleClickRow = () => {},
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "submitter_user__first_name",
        headerName: "Submitted by",
        valueGetter: ({ row }) => row?.submitter_user?.full_name,
      },
      {
        flex: 1,
        field: "file",
        sortable: false,
        headerName: "File",
        renderCell: ({ value }) =>
          Boolean(value) ? (
            <CustomExternalLink
              href={value}
              label="Download file"
              wrapperProps={{ download: true }}
              isTypography={true}
            />
          ) : (
            "-"
          ),
      },
      {
        flex: 1,
        field: "num_claims",
        headerName: "Claims",
        valueGetter: ({ value }) => getIntOrFloat(value),
      },
      {
        flex: 1,
        field: "preprocessing_status",
        headerName: "Status",
        renderCell: ({ value }) => (
          <CustomStatusBox
            status={value}
            textMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER}
            propsMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last Updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    disableRowSelectionOnClick: true,
    onRowClick: ({ row }) => handleClickRow(row),
  };
}
