import React from "react";
import { CLAIM_STATUS_TO_REVIEW } from "@constants/claims/claim-status";
import ClaimSubmitterResolveClaimForm from "./ClaimSubmitterResolveClaimForm";
import ClaimSubmitterUpdateClaimForm from "./ClaimSubmitterUpdateClaimForm";

export default function ClaimSubmitterResolveOrUpdateForm({
  object,
  icd_type,
}) {
  const { status, to_review } = object;
  const { claim_variations = null, has_claim_icds_to_review = false } =
    to_review || {};

  if (status === CLAIM_STATUS_TO_REVIEW) {
    if (Boolean(claim_variations))
      return (
        <ClaimSubmitterResolveClaimForm object={object} icd_type={icd_type} />
      );
    else if (has_claim_icds_to_review)
      return (
        <ClaimSubmitterUpdateClaimForm
          object={object}
          icd_type={icd_type}
          dialogProps={{
            fullScreen: false,
            maxWidth: "lg",
          }}
          buttonProps={{
            label: "Review ICDs",
          }}
          useIcdsOnly={true}
          hideDialogWhenSummary={true}
        />
      );
  }

  return <ClaimSubmitterUpdateClaimForm object={object} icd_type={icd_type} />;
}
