import React from "react";
import CustomInfiniteAutocomplete from "@components/Custom/CustomAutocomplete/CustomInfiniteAutocomplete";
import CustomChip from "@components/Custom/CustomChip";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import Stack from "@mui/material/Stack";
import { useQueryFetchSpecialities } from "@redux/actions/avey/specialities";
import {
  getSpecialityOptionLabel,
  isSpecialityOptionEqualToValue,
  renderOptionSpeciality,
} from "./SpecialityAutocompleteComponents";

const useQueryFnProps = {
  filters: {
    time_deleted__isnull: true,
    newer_version__isnull: true,
  },
};

export default function SpecialitiesAutocomplete({
  label = "Specialities",
  error = false,
  value = [],
  handleChange = () => {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const hasValue = value.length > 0;
  const handleRemoveOption = (option) =>
    handleChange(value.filter(({ id }) => id !== option?.id));

  return (
    <Stack spacing={2}>
      <CustomInfiniteAutocomplete
        label={label}
        error={error}
        textFieldProps={{ ...textFieldProps }}
        autocompleteProps={{
          multiple: true,
          filterSelectedOptions: true,
          disableCloseOnSelect: true,
          renderOption: renderOptionSpeciality,
          ...autocompleteProps,
        }}
        useQueryFn={useQueryFetchSpecialities}
        useQueryFnProps={useQueryFnProps}
        getOptionLabel={getSpecialityOptionLabel}
        isOptionEqualToValue={isSpecialityOptionEqualToValue}
        value={value}
        handleChange={handleChange}
      />
      {hasValue && (
        <CustomGrid rowSpacing={1} columnSpacing={1}>
          {value.map((option) => (
            <CustomChip
              key={option.id}
              name={getSpecialityOptionLabel(option)}
              title={option.description}
              handleClick={() => handleRemoveOption(option)}
              color="grey"
              hasCloseIcon={true}
            />
          ))}
        </CustomGrid>
      )}
    </Stack>
  );
}
