import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomSection from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE } from "@constants/claims/claims";
import { ORGANIZATION_PATIENT_GENDER_MAPPER } from "@constants/organizations/organization-patients";
import { parseDob } from "@helpers/parse/time";

const title = CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE;

export default function OrganizationPatientDetailsData({
  object = {},
  isMinified = false,
  useCustomSection = true,
  useCustomCollapse = true,
}) {
  if (useCustomSection)
    return (
      <CustomSection
        title={title}
        useCustomSection={useCustomSection}
        isMinified={isMinified}
      >
        <OrganizationPatientDetailsDataContent object={object} />
      </CustomSection>
    );

  if (useCustomCollapse)
    return (
      <CustomListItemCollapse primary={title}>
        <OrganizationPatientDetailsDataContent object={object} />
      </CustomListItemCollapse>
    );

  return <OrganizationPatientDetailsDataContent object={object} />;
}

const OrganizationPatientDetailsDataContent = ({ object }) => {
  const {
    first_name,
    last_name,
    localized_first_name,
    localized_last_name,
    dob,
    gender,
    organization_patient_identities = [],
  } = object || {};
  const name = `${first_name || ""} ${last_name || ""}`.trim();
  const localizedName = `${localized_first_name || ""} ${
    localized_last_name || ""
  }`.trim();
  const hasLocalizedName = Boolean(localizedName);
  const { dobStr, ageStr } = parseDob(dob);
  const genderStr = ORGANIZATION_PATIENT_GENDER_MAPPER[gender];

  return (
    <CustomGrid>
      <CustomLabeledText label="Name" value={name} />
      {hasLocalizedName && (
        <CustomLabeledText label="Localized name" value={localizedName} />
      )}
      <CustomLabeledText
        label="Dob"
        value={dobStr}
        title={ageStr}
        useCustomTooltip={true}
      />
      <CustomLabeledText label="Gender" value={genderStr} />
      {organization_patient_identities.map(
        (organization_patient_identity, index) => (
          <CustomLabeledText
            key={index}
            label={organization_patient_identity?.identity_type?.name}
            value={organization_patient_identity?.number}
          />
        )
      )}
    </CustomGrid>
  );
};
