import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  DRUG_NAMES_TITLE,
  getConfigurationBaseHrefs,
  getDrugNamesHref,
} from "@constants/static/routing";
import {
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DrugNameActivateForm from "../DrugNameForms/DrugNameActivateForm";
import DrugNameUpdateForm from "../DrugNameForms/DrugNameUpdateForm";
import DrugNameVersionHistory from "./DrugNameVersionHistory";

export function DrugNameHeaderTitle({ object, variant = "h3" }) {
  const { version, name, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function DrugNameHeader({ object }) {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { drug_name__id } = useParams();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const { code, name, time_deleted, submitter_organization } = object || {};
  const { slug } = submitter_organization || {};
  const isSubmitterOrganization = slug === submitter_organization__slug;
  const title = code || name;
  const isDeleted = Boolean(time_deleted);
  const canUpdateObject =
    isStaff || (hasAdminPrivileges && isSubmitterOrganization);

  const hrefs = [
    ...getConfigurationBaseHrefs({ as_staff }),
    {
      label: DRUG_NAMES_TITLE,
      href: getDrugNamesHref({ as_staff }),
    },
    {
      label: title,
      href: getDrugNamesHref({ drug_name__id, as_staff }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={DrugNameHeaderTitle}
      titleComponentProps={{ object }}
    >
      {canUpdateObject && !isDeleted && <DrugNameUpdateForm object={object} />}
      <CustomMoreActions>
        <DrugNameVersionHistory />
        {canUpdateObject && <DrugNameActivateForm object={object} />}
      </CustomMoreActions>
    </CustomHeader>
  );
}
