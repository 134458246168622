import React from "react";
import { describeFilters } from "@components/Authenticated/ClaimSubmissions/Claims/Claims/ClaimsFilters";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomAppliedFilters from "@components/Custom/CustomAppliedFilters";
import CustomButtonVariant from "@components/Custom/CustomButtonVariant";
import {
  ADJUST_FILTERS_TEXT,
  FILTER_BUTTON_ID,
} from "@components/Custom/CustomFilters";
import { handleElementOnClickDispatchEvent } from "@helpers/hooks/useElementOnClick";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Typography from "@mui/material/Typography";
import {
  reprocessClaimSubmissionUpload,
  updateClaimSubmissionUploadObjectTSQ,
} from "@redux/actions/claims/claim-submission-uploads";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { parseClaimSubmissionUploadSnackbar } from "../ClaimSubmissionUploadSnackbar";

const title = "Reprocess claims";
const label = "claims";

export default function ClaimSubmissionUploadReprocessForm({
  useQueryFnPropsClaims = {},
}) {
  const { isAgreementDisabled, isLoading } = useFetchOrganizationAgreement();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const { searchQuery = "", filters = {} } = useQueryFnPropsClaims || {};

  const handleClose = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(false);
  };

  const handleSubmit = ({ e, isCheck = true }) => {
    e?.preventDefault();
    setIsOpen(true);
    setIsProcessing(true);
    reprocessClaimSubmissionUpload({
      data: { is_check: isCheck },
      searchQuery,
      filters,
    })
      .then(({ payload }) => {
        const { count, instance } = payload || {};
        setIsProcessing(false);
        if (Boolean(instance)) {
          updateClaimSubmissionUploadObjectTSQ({ object: instance });
          enqueueSnackbar(parseClaimSubmissionUploadSnackbar(instance));
          setIsOpen(false);
        } else {
          setCount(count || 0);
        }
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ message: payload, variant: "error" });
      });
  };

  const handleSubmitData = (e) => handleSubmit({ e, isCheck: false });
  const handleCheckData = (e) => handleSubmit({ e, isCheck: true });

  const hasNoData = count === 0;
  const countrStr = hasNoData
    ? `no ${pluralize(label)}`
    : `${getIntOrFloat(count)} ${pluralize(label, count, false)}`;

  const summary = !(isProcessing && hasNoData) && (
    <>
      {"You have "}
      <b>{countrStr}</b>
      {" that match your filters"}
    </>
  );

  const handleAdjustFilters = ({ e, handleFn }) => {
    e?.preventDefault();
    handleElementOnClickDispatchEvent({ id: FILTER_BUTTON_ID, detail: true });
    handleFn();
  };

  const dialogProps = hasNoData
    ? {
        handleSubmit: (e) => handleAdjustFilters({ e, handleFn: handleClose }),
        isOk: true,
        okText: ADJUST_FILTERS_TEXT,
      }
    : {
        handleCloseAlt: (e) =>
          handleAdjustFilters({ e, handleFn: handleClose }),
        handleSubmit: handleSubmitData,
        yesText: "Reprocess",
        noText: ADJUST_FILTERS_TEXT,
      };

  if (isAgreementDisabled || isLoading)
    return (
      <CustomButtonVariant label={title} isMenuItem={true} disabled={true} />
    );

  return (
    <>
      <CustomButtonVariant
        label={title}
        onClick={handleCheckData}
        isMenuItem={true}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleClose}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title }}
        {...dialogProps}
      >
        <CustomAppliedFilters
          describeFilters={describeFilters}
          searchQuery={searchQuery}
          filters={filters}
        />
        <Typography variant="body2">{summary}</Typography>
      </CustomActionDialog>
    </>
  );
}
