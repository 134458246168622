import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";

export default function SpecialityData({
  object,
  useCustomSection = true,
  isMinified = false,
}) {
  const { name, description } = object || {};

  return (
    <CustomSection
      title="Speciality details"
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        <CustomLabeledText label="Name" value={name} />
        <CustomLabeledText label="Description" value={description} />
      </CustomGrid>
    </CustomSection>
  );
}

export const SpecialityDataReviewAndConfirm = ({ object }) => (
  <SpecialityData object={object} isMinified={true} />
);
