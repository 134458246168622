import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getConsumablesHref } from "@constants/static/routing";
import { useQueryFetchConsumable } from "@redux/actions/avey/consumables";
import { Navigate, useParams } from "react-router-dom";
import ConsumableData from "./ConsumableData";
import ConsumableHeader from "./ConsumableHeader";

export default function Consumable() {
  const { as_staff } = useStaffContextProvider();
  const { consumable__id } = useParams();
  const { isLoading, data } = useQueryFetchConsumable({
    consumable__id,
    as_staff,
  });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (Boolean(latest_version_id) && latest_version_id !== consumable__id) {
    const latestVersionHref = getConsumablesHref({
      consumable__id: latest_version_id,
      as_staff,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <ConsumableHeader object={data} />
      <CustomPage>
        <ConsumableData object={data} />
      </CustomPage>
    </>
  );
}
