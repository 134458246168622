import React from "react";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteConsumable,
  invalidateConsumablesTSQ,
  updateConsumableObjectTSQ,
} from "@redux/actions/avey/consumables";

export default function ConsumableActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateConsumablesTSQ();
    updateConsumableObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="consumable"
      deleteFn={deleteConsumable}
      handleDeleteFn={handleDeleteFn}
    />
  );
}
