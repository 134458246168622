import React from "react";
import UnauthenticatedWrapper from "@components/Unauthenticated/UnauthenticatedWrapper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomBox from "./CustomBox";

export default function Custom404({ title = "Oops! Page not found." }) {
  return (
    <UnauthenticatedWrapper backgroundColor="white" useLogo={false}>
      <Stack spacing={3}>
        <div>
          {["4", "0", "4"].map((digit, index) => (
            <CustomBox
              component="img"
              key={index}
              src={`/assets/images/numbers/${digit}.png`}
              height="80px"
            />
          ))}
        </div>
        <Typography fontWeight="bold">{title}</Typography>
      </Stack>
    </UnauthenticatedWrapper>
  );
}
