import React from "react";
import { getNumActiveFilters } from "@helpers/hooks/objects";
import { useElementOnClick } from "@helpers/hooks/useElementOnClick";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_BLACK, AVEY_YELLOW } from "@styles/theme";
import CustomInputAdornment from "./CustomInputAdornment";
import CustomLabeledCheckbox from "./CustomLabeledCheckbox";
import CustomGrid from "./CustomLayout/CustomGrid";
import CustomListItemCollapse from "./CustomListItemCollapse";
import CustomMoreActions from "./CustomMoreActions";
import {
  CustomDateRangePicker,
  CustomOutlinedTextField,
} from "./CustomOutlinedField";
import CustomTooltip from "./CustomTooltip";
import CustomTypographyButton from "./CustomTypographyButton";

export const FILTER_BUTTON_ID = "filter-button";
export const ADJUST_FILTERS_TEXT = "Adjust filters";
const id = FILTER_BUTTON_ID;

export default function CustomFilters({
  initialFilterState = {},
  filterOptions = {},
  formatFilters = () => {},
  setFilters = () => {},
}) {
  const { isOpen } = useElementOnClick({
    id,
    timeout: 3_000,
    useScrollIntoView: false,
  });
  const [activeFilters, setActiveFilters] = React.useState(initialFilterState);

  const handleChangeActiveFilters = (newFilters) => {
    setActiveFilters(newFilters);
    setFilters(formatFilters(newFilters));
  };

  const numActiveFilters = getNumActiveFilters(activeFilters);
  const hasActiveFilters = numActiveFilters > 0;

  const handleClearActiveFilters = () =>
    handleChangeActiveFilters(initialFilterState);

  return (
    <CustomTooltip
      title="Adjust your filters here"
      tooltipProps={{
        open: isOpen,
        color: AVEY_BLACK,
        bgColor: AVEY_YELLOW,
        fontSize: 14,
        arrow: true,
      }}
    >
      <CustomMoreActions
        buttonProps={{
          id,
          label: numActiveFilters,
          variant: "contained",
          color: "info",
          icon: "TuneIcon",
          isSquare: !hasActiveFilters,
          useIconAsLabel: !hasActiveFilters,
          style: { minWidth: !hasActiveFilters && 40, scrollMarginTop: 166 },
        }}
        popperProps={{
          cardStyleProps: { maxWidth: 270, padding: "24px" },
          listProps: { disablePadding: true },
        }}
        useRotateProps={{ useRotate: false }}
      >
        <Stack spacing={2}>
          <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={1} direction="row" alignItems="baseline">
              <Typography fontWeight="bold">{"Filters"}</Typography>
              {hasActiveFilters && (
                <Typography flexGrow={1} variant="body2">
                  {numActiveFilters}
                </Typography>
              )}
            </Stack>
            {hasActiveFilters && (
              <CustomTypographyButton
                value="Clear"
                fontWeight="normal"
                onClick={handleClearActiveFilters}
              />
            )}
          </Stack>
          <CustomFilterOptions
            filterOptions={filterOptions}
            activeFilters={activeFilters}
            handleChangeActiveFilters={handleChangeActiveFilters}
          />
        </Stack>
      </CustomMoreActions>
    </CustomTooltip>
  );
}

export const CustomFilterOptions = ({
  useCollapse = true,
  filterOptions = {},
  activeFilters = {},
  handleChangeActiveFilters = () => {},
}) => {
  const handleChangeOption = ({ field, e }) => {
    const { name, checked } = e.target;
    name === ""
      ? handleChangeActiveFilters({
          ...activeFilters,
          [field]: checked
            ? filterOptions[field].options.map(({ key }) => key)
            : [],
        })
      : handleChangeActiveFilters({
          ...activeFilters,
          [field]: checked
            ? [...activeFilters[field], name]
            : activeFilters[field].filter(
                (option) => option !== name && option !== ""
              ),
        });
  };

  const handleChangeDateRange = ({ field, newValue }) =>
    handleChangeActiveFilters({ ...activeFilters, [field]: newValue });

  const handleChangeRange = ({ field, e, index }) => {
    const { value } = e.target;
    const newValue =
      index === 0
        ? [value, activeFilters[field][1]]
        : [activeFilters[field][0], value];
    handleChangeActiveFilters({ ...activeFilters, [field]: newValue });
  };

  const handleChangeBoolean = ({ field, key }) => {
    const value = activeFilters[field]?.[0] === key ? [] : [key];
    handleChangeActiveFilters({ ...activeFilters, [field]: value });
  };

  return (
    <Stack spacing={3}>
      {Object.entries(filterOptions).map(
        (
          [
            field,
            {
              title,
              type = "option",
              options = [],
              disabled = false,
              useCustomGrid = true,
              unit = undefined,
            },
          ],
          index
        ) => {
          const endAdornment = Boolean(unit) && (
            <CustomInputAdornment value={unit} />
          );

          switch (type) {
            case "range":
              return (
                <CustomListItemCollapse
                  key={index}
                  primary={title}
                  useCollapse={useCollapse}
                >
                  <Stack spacing={2} direction="row">
                    <CustomOutlinedTextField
                      type="number"
                      placeholder="From"
                      value={activeFilters[field]?.[0]}
                      onChange={(e) =>
                        handleChangeRange({ field, e, index: 0 })
                      }
                      wrapperProps={{ flex: 1 }}
                      disabled={disabled}
                      InputProps={{ endAdornment }}
                    />
                    <CustomOutlinedTextField
                      type="number"
                      placeholder="To"
                      value={activeFilters[field]?.[1]}
                      onChange={(e) =>
                        handleChangeRange({ field, e, index: 1 })
                      }
                      wrapperProps={{ flex: 1 }}
                      disabled={disabled}
                      InputProps={{ endAdornment }}
                    />
                  </Stack>
                </CustomListItemCollapse>
              );

            case "date-range":
              return (
                <CustomListItemCollapse
                  key={index}
                  primary={title}
                  useCollapse={useCollapse}
                >
                  <CustomDateRangePicker
                    label=""
                    value={activeFilters[field]}
                    onChange={(newValue) =>
                      handleChangeDateRange({ field, newValue })
                    }
                    disabled={disabled}
                  />
                </CustomListItemCollapse>
              );

            case "boolean":
              return (
                <CustomListItemCollapse
                  key={index}
                  primary={title}
                  useCollapse={useCollapse}
                >
                  <OptionWrapper useCustomGrid={useCustomGrid}>
                    {options.map(({ key, value }, j_index) => (
                      <CustomLabeledCheckbox
                        key={j_index}
                        checked={activeFilters[field].includes(key)}
                        label={value}
                        onChange={() => handleChangeBoolean({ field, key })}
                        isRadio={true}
                        disabled={disabled}
                      />
                    ))}
                  </OptionWrapper>
                </CustomListItemCollapse>
              );

            case "option":
            default:
              return (
                <CustomListItemCollapse
                  key={index}
                  primary={title}
                  useCollapse={useCollapse}
                >
                  <OptionWrapper useCustomGrid={useCustomGrid}>
                    {options.map(({ key, value }, j_index) => (
                      <CustomLabeledCheckbox
                        key={j_index}
                        checked={activeFilters[field].includes(key.toString())}
                        name={key.toString()}
                        label={value}
                        onChange={(e) => handleChangeOption({ field, e })}
                        disabled={disabled}
                      />
                    ))}
                  </OptionWrapper>
                </CustomListItemCollapse>
              );
          }
        }
      )}
    </Stack>
  );
};

const OptionWrapper = ({ children, useCustomGrid }) =>
  useCustomGrid ? (
    <CustomGrid rowSpacing={0} columnSpacing={3}>
      {children}
    </CustomGrid>
  ) : (
    <Stack>{children}</Stack>
  );
