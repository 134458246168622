import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { getOrganizationPatientUploadsHref } from "@constants/static/routing";
import { useParams } from "react-router-dom";
import OrganizationPatientCreateForm from "../OrganizationPatient/OrganizationPatientForms/OrganizationPatientCreateForm";

export default function OrganizationPatientsCreateForms() {
  const { organization__slug } = useParams();
  const uploadHistoryHref = getOrganizationPatientUploadsHref({
    organization__slug,
  });

  return (
    <CustomAddOrUploadWrapper
      label="Add patients"
      uploadHistoryHref={uploadHistoryHref}
      useUploadForm={true}
    >
      <OrganizationPatientCreateForm
        withNavigate={true}
        isFullScreen={true}
        isTypographyButton={false}
      />
    </CustomAddOrUploadWrapper>
  );
}
