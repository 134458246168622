import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { getOrganizationBundlesUploadsHref } from "@constants/static/routing";
import { useParams } from "react-router-dom";
import OrganizationBundleCreateForm from "../OrganizationBundle/OrganizationBundleForms/OrganizationBundleCreateForm";

export default function OrganizationBundlesCreateForms() {
  const { bundle_type } = useModelTypeContextProvider();
  const { organization__slug } = useParams();
  const uploadHistoryHref = getOrganizationBundlesUploadsHref({
    bundle_type,
    organization__slug,
  });

  return (
    <CustomAddOrUploadWrapper
      label="Add codes"
      uploadHistoryHref={uploadHistoryHref}
      useUploadForm={true}
    >
      <OrganizationBundleCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
