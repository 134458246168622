import React from "react";
import ConfigurationsBulkExports from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExports";
import HealthcareOrganizationConfigurations from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations";
import Consumable from "@components/Authenticated/Configurations/Consumables/Consumable/Consumable";
import Cpt from "@components/Authenticated/Configurations/Cpts/Cpt";
import Icd from "@components/Authenticated/Configurations/Icds/Icd";
import Medication from "@components/Authenticated/Configurations/Medications/Medication/Medication";
import OrganizationClinician from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinician";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { StaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import {
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
} from "@constants/static/globals";
import {
  CONSUMABLE_EXPORTS_HREF,
  CONSUMABLE_UPLOADS_HREF,
  CONSUMABLES_HREF,
  CPTS_HREF,
  ICDS_HREF,
  MEDICATION_EXPORTS_HREF,
  MEDICATION_UPLOADS_HREF,
  MEDICATIONS_HREF,
  ORGANIZATION_CLINICIAN_EXPORTS_HREF,
  ORGANIZATION_CLINICIAN_UPLOADS_HREF,
  ORGANIZATION_CLINICIANS_HREF,
} from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";
import UploadRoutes from "../upload-routes";

export default function HealthcareConfigurationsRoutes() {
  return (
    <StaffContextProvider as_staff="0">
      <Routes>
        <Route path="" Component={HealthcareOrganizationConfigurations} />
        <Route path={`${CPTS_HREF}/:cpt__id/*`} Component={Cpt} />
        <Route path={`${ICDS_HREF}/:icd__id/*`} Component={Icd} />
        <Route
          path={`${MEDICATIONS_HREF}/:medication__id/*`}
          Component={Medication}
        />
        <Route
          path={`${CONSUMABLES_HREF}/:consumable__id/*`}
          Component={Consumable}
        />
        <Route
          path={`${ORGANIZATION_CLINICIANS_HREF}/:organization_clinician__id/*`}
          Component={OrganizationClinician}
        />
        <Route
          path={`${CONSUMABLE_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_CONSUMABLE}>
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${CONSUMABLE_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_CONSUMABLE}>
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${MEDICATION_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_MEDICATION}>
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${MEDICATION_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_MEDICATION}>
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_CLINICIAN_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_CLINICIAN}
            >
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${ORGANIZATION_CLINICIAN_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider
              model_type={MODEL_TYPE_ORGANIZATION_CLINICIAN}
            >
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </StaffContextProvider>
  );
}
