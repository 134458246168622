import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { getCptsHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";

const CptsGridProps = {
  columns: [
    {
      flex: 1,
      field: "code",
      headerName: "Code",
      valueGetter: ({ value }) => value || "-",
    },
    {
      flex: 5,
      field: "description",
      headerName: "Description",
      renderCell: ({ value }) => (
        <Typography variant="body2" className="ellipsis-2">
          {value}
        </Typography>
      ),
    },
    {
      flex: 1,
      field: "time_updated",
      headerName: "Last updated",
      valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
    },
  ],
  getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
  disableRowSelectionOnClick: true,
  navigateTo: ({ id }) => getCptsHref({ cpt__id: id }),
};

export default CptsGridProps;
