import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import AppConfigurationsBody from "./AppConfigurationsBody";
import AppConfigurationsHeader from "./AppConfigurationsHeader";

export default function AppConfigurations() {
  return (
    <>
      <AppConfigurationsHeader />
      <CustomPage mt={0}>
        <AppConfigurationsBody />
      </CustomPage>
    </>
  );
}
