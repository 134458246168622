import React from "react";
import CustomActionDialog, {
  customActionDialogBodyPx,
} from "@components/Custom/CustomActionDialog";
import CustomListAvatarItem from "@components/Custom/CustomListAvatarItem";
import CustomSection from "@components/Custom/CustomSection";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HealthcareOrganizationActionDialogTitle from "../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationActionDialogTitle";

export default function OrganizationAffiliationsOrganizationsReviewAndConfirm({
  summaryState,
  isProcessing,
  setSummaryState,
  handleSubmit = () => {},
}) {
  const { summary, isOpen } = summaryState;
  const { to_remove = [], to_add = [] } = summary;
  const hasToRemove = (to_remove || []).length > 0;
  const hasToAdd = (to_add || []).length > 0;

  const hasNoChanges = !hasToRemove && !hasToAdd;

  const handleCloseSummary = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setSummaryState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleCloseSummary}
      handleSubmit={hasNoChanges ? handleCloseSummary : handleSubmit}
      isOk={hasNoChanges}
      okText="Go back"
      okButtonProps={{ color: "info" }}
      TitleComponent={HealthcareOrganizationActionDialogTitle}
      titleComponentProps={{ title: "Review & Confirm" }}
    >
      {!hasToRemove && !hasToAdd ? (
        <Box px={customActionDialogBodyPx}>
          <Typography variant="body2">{"No changes detected."}</Typography>
        </Box>
      ) : (
        <Stack spacing={2}>
          {hasToRemove && (
            <OrganizationListLocale organizations={to_remove} isAdd={false} />
          )}
          {/* {hasToRemove && hasToAdd && <Divider />} */}
          {hasToAdd && (
            <OrganizationListLocale organizations={to_add} isAdd={true} />
          )}
        </Stack>
      )}
    </CustomActionDialog>
  );
}

function OrganizationListLocale({ organizations, isAdd = true }) {
  return (
    <CustomSection
      isMinified={true}
      title={isAdd ? "Activations" : "Deactivations"}
      subtitle={organizations.length}
      isCollapsable={false}
    >
      <Stack spacing={2}>
        {organizations.map(({ image, name }, index) => (
          <CustomListAvatarItem
            key={index}
            imageProps={{ src: image, variant: "square" }}
            textProps={{ children: name }}
          />
        ))}
      </Stack>
    </CustomSection>
  );
}
