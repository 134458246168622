import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import { CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE } from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import OrganizationPatientDetailsFormData from "./OrganizationPatientDetailsFormData";
import OrganizationPatientIdentitiesFormData from "./OrganizationPatientIdentitiesFormData";
import OrganizationPatientPolicyDetailsFormData from "./OrganizationPatientPolicyDetailsFormData";

export default function OrganizationPatientFormData({
  formData = {},
  setFormData = () => {},
  useCustomSection = true,
}) {
  const { organization_patient_identities = [] } = formData || {};
  const handleUpdateFormData = (payload) =>
    setFormData({ ...formData, ...payload });
  const handleUpdateOrganizationPatientIdentities = (payload) =>
    setFormData({ ...formData, organization_patient_identities: payload });

  if (useCustomSection)
    return (
      <Stack spacing={3}>
        <CustomSection
          title={CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE}
          isCollapsable={false}
          bodyProps={{ p: 0 }}
        >
          <Box p={customSectionBodyPadding}>
            <OrganizationPatientDetailsFormData
              formData={formData}
              handleUpdateFormData={handleUpdateFormData}
            />
          </Box>
          <Divider />
          <OrganizationPatientIdentitiesFormData
            spacing={0}
            padding={customSectionBodyPadding}
            formData={organization_patient_identities}
            handleUpdateOrganizationPatientIdentities={
              handleUpdateOrganizationPatientIdentities
            }
          />
        </CustomSection>
        <CustomSection title="Policy details" isCollapsable={false}>
          <OrganizationPatientPolicyDetailsFormData
            formData={formData}
            handleUpdateFormData={handleUpdateFormData}
          />
        </CustomSection>
      </Stack>
    );

  return (
    <Stack spacing={3}>
      <OrganizationPatientDetailsFormData
        formData={formData}
        handleUpdateFormData={handleUpdateFormData}
      />
      <Divider />
      <OrganizationPatientIdentitiesFormData
        spacing={3}
        padding={0}
        formData={organization_patient_identities}
        handleUpdateOrganizationPatientIdentities={
          handleUpdateOrganizationPatientIdentities
        }
      />
      <Divider />
      <OrganizationPatientPolicyDetailsFormData
        formData={formData}
        handleUpdateFormData={handleUpdateFormData}
      />
    </Stack>
  );
}

export const OrganizationPatientFormDataOnBlur = ({
  formData: parentFormData = {},
  setFormData: setParentFormData = () => {},
  useCustomSection = true,
}) => {
  const [formData, setFormData] = React.useState(parentFormData);

  React.useEffect(() => {
    setFormData(parentFormData);
  }, [parentFormData]);

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      !currentTarget?.contains(document.activeElement) &&
        setParentFormData(formData);
    }, 0);
  };

  return (
    <Box tabIndex={-1} onBlur={handleBlur}>
      <OrganizationPatientFormData
        formData={formData}
        setFormData={setFormData}
        useCustomSection={useCustomSection}
      />
    </Box>
  );
};
