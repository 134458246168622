import React from "react";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import {
  AZURE_NDC_ENABLED,
  EES_ENABLED,
  GCP_NDC_ENABLED,
  MODEL_TYPE_MEDICATION,
} from "@constants/static/globals";
import Grid from "@mui/material/Grid";
import { useQueryFetchMedications } from "@redux/actions/avey/medications";
import {
  selectorAuthCurrentUserOrganizationNdcTagCodes,
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ConfigurationsBulkExportActions from "../../Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportActions";
import MedicationsCreateForms from "./MedicationsCreateForms";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./MedicationsFilters";
import MedicationsGridProps from "./MedicationsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function MedicationsGrid() {
  const { as_staff, isStaff } = useStaffContextProvider();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const ndc_tag_codes = useSelector(
    selectorAuthCurrentUserOrganizationNdcTagCodes
  );
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true, as_staff, ...filters },
    eesFilters: { submitter_organization__slug, ndc_tag_codes },
    useEesIfEnabled: !isStaff,
  };
  const dataGridProps = React.useMemo(
    () => MedicationsGridProps({ as_staff, isStaff }),
    [as_staff, isStaff]
  );
  const isClientPagination =
    Boolean(searchQuery) &&
    EES_ENABLED &&
    (AZURE_NDC_ENABLED || GCP_NDC_ENABLED);

  return (
    <ModelTypeContextProvider model_type={MODEL_TYPE_MEDICATION}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasAdminPrivileges && (
          <>
            <Grid item>
              <MedicationsCreateForms />
            </Grid>
            <Grid item>
              <ConfigurationsBulkExportActions
                useQueryFnProps={useQueryFnProps}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchMedications}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
            isClientPagination={isClientPagination}
          />
        </Grid>
      </Grid>
    </ModelTypeContextProvider>
  );
}
