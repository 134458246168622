import React from "react";
import DrugNameAutocomplete from "@components/Custom/CustomAutocomplete/DrugNameAutocomplete";
import UnitAutocomplete from "@components/Custom/CustomAutocomplete/UnitAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import {
  DRUG_NAME_TYPE_GENERIC,
  DRUG_NAME_TYPE_TRADE,
} from "@constants/avey/drug-names";
import { emptyMedicationActiveIngredientFormData } from "@constants/avey/medications";
import {
  UNIT_TYPE_DOSAGE_FORM,
  UNIT_TYPE_PACKAGE_TYPE,
} from "@constants/avey/units";
import Grid from "@mui/material/Grid";

export default function MedicationDetailsFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { code, trade_name, generic_name, dosage_form, package_type } =
    formData;

  const handleChanGenericName = (newValue) =>
    setFormData((prev) => {
      const applyGenericName =
        Boolean(newValue) &&
        !(prev.medication_active_ingredients || []).find(({ generic_name }) =>
          Boolean(generic_name)
        );
      return {
        ...prev,
        generic_name: newValue,
        medication_active_ingredients: applyGenericName
          ? [
              {
                ...emptyMedicationActiveIngredientFormData,
                generic_name: newValue,
              },
              ...prev.medication_active_ingredients,
            ]
          : prev.medication_active_ingredients,
      };
    });

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            autoFocus
            label="Code"
            value={code}
            name="code"
            onChange={handleChange}
            placeholder="e.g. NDC-XXXX"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DrugNameAutocomplete
            label="Generic name"
            value={generic_name}
            handleChange={handleChanGenericName}
            placeholder="e.g. Paracetamol"
            drug_name_type={DRUG_NAME_TYPE_GENERIC}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DrugNameAutocomplete
            label="Trade name"
            value={trade_name}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "trade_name", newValue })
            }
            placeholder="e.g. Biogesic"
            drug_name_type={DRUG_NAME_TYPE_TRADE}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UnitAutocomplete
            label="Package type"
            value={package_type}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "package_type", newValue })
            }
            textFieldProps={{ placeholder: "e.g. Blister pack" }}
            unit_type={UNIT_TYPE_PACKAGE_TYPE}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UnitAutocomplete
            label="Dosage form"
            value={dosage_form}
            handleChange={(newValue) =>
              handleChangeField({ fieldname: "dosage_form", newValue })
            }
            textFieldProps={{ placeholder: "e.g. Tablet" }}
            unit_type={UNIT_TYPE_DOSAGE_FORM}
          />
        </Grid>
      </Grid>
    </div>
  );
}
