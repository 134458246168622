import {
  getBulkUploadSnackbarKey,
  parseBulkUploadSnackbar,
} from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUploads/ConfigurationsBulkUploadsSnackbar";
import { USE_QUERY_FN_CLEAR_CACHE_MAPPER } from "@constants/static/routing";
import {
  UPLOAD_STATUS_COMMITTING,
  UPLOAD_STATUS_PENDING,
  UPLOAD_STATUS_PREPROCESSING,
} from "@constants/static/upload";
import {
  updateUploadEntryObjectsTSQ,
  updateUploadObjectTSQ,
} from "@redux/actions/utils/uploads";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export default function handleWsUpload({ object = {}, enqueuedSnackbarKeys }) {
  let key = null;
  const { id, entries, status, model_type, use_processing_status } = object;
  const clearCacheFn = USE_QUERY_FN_CLEAR_CACHE_MAPPER[model_type];

  updateUploadObjectTSQ({ object });
  if (Boolean(entries)) updateUploadEntryObjectsTSQ({ objects: entries });
  if (clearCacheFn) clearCacheFn();

  if (
    ![
      UPLOAD_STATUS_PENDING,
      UPLOAD_STATUS_PREPROCESSING,
      UPLOAD_STATUS_COMMITTING,
    ].includes(status)
  ) {
    closeSnackbar(
      getBulkUploadSnackbarKey({
        id,
        status: use_processing_status
          ? UPLOAD_STATUS_COMMITTING
          : UPLOAD_STATUS_PENDING,
        model_type,
      })
    );
    const parsedUpload = parseBulkUploadSnackbar(object);
    key = parsedUpload.key;

    !enqueuedSnackbarKeys.has(key) && enqueueSnackbar(parsedUpload);
  }

  return key;
}
