import React from "react";
import {
  AVEY_BLOOM_BACKEND_WS_URL,
  LOG_WEBSOCKET_NOTIFICATIONS,
} from "@constants/static/globals";
import {
  selectorAuth,
  selectorAuthCurrentUserOrganizationRole,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ReconnectingWebSocket from "reconnecting-websocket";
import { WEBSOCKET_NOTIFICATION_TYPE_HANDLER_FN_MAPPER } from "./handlers";

const enqueuedSnackbarKeys = new Set(); // Set to track enqueued snackbar keys

export default function useWebsocketHook() {
  const { isAuthenticated, user = {} } = useSelector(selectorAuth);
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const { id } = user || {};

  React.useEffect(() => {
    if (isAuthenticated && Boolean(id)) {
      const ws = new ReconnectingWebSocket(
        `${AVEY_BLOOM_BACKEND_WS_URL}/${id}/`
      );
      ws.onopen = () => console.log("Backend Websocket Connection Opened");
      ws.onmessage = ({ data }) => wsOnMessage({ data, organizationRole });
      ws.onerror = (e) => console.log("Backend Websocket Connection Error", e);
      ws.onclose = () => console.log("Backend Websocket Connection Closed");
      return () => ws.close();
    }
  }, [isAuthenticated, id, organizationRole]);

  return null;
}

const wsOnMessage = ({ data, organizationRole }) => {
  const { ntype, payload: object } = JSON.parse(data || "{}") || {};
  LOG_WEBSOCKET_NOTIFICATIONS &&
    console.log("Incoming Websocket Notification", ntype, object);
  const handleWebsocketFn =
    WEBSOCKET_NOTIFICATION_TYPE_HANDLER_FN_MAPPER[ntype];
  const key = handleWebsocketFn({
    object,
    organizationRole,
    enqueuedSnackbarKeys,
  });
  key && enqueuedSnackbarKeys.add(key);
};
