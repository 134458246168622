import React from "react";
import CurrencyAutocomplete from "@components/Custom/CustomAutocomplete/CurrencyAutocomplete";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { AVEY_BLOOM_BACKEND_CURRENCY_CODE } from "@constants/static/globals";
import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { updateUser } from "@redux/actions/users/auth";
import {
  selectorAuthBillingConversion,
  selectorAuthCurrentUser,
} from "@redux/selectors/auth";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

const useQueryFnProps = {
  filters: {
    target_rates__source_currency__code: AVEY_BLOOM_BACKEND_CURRENCY_CODE,
  },
};

export default function BillingManagementCurrencyPicker() {
  const dispatch = useDispatch();
  const user = useSelector(selectorAuthCurrentUser);
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(billing_conversion);
  const { code } = formData;

  const handleOpen = () => !isLoading && setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleChange = (newValue) => {
    handleClose();
    if (newValue.code !== billing_conversion.code) {
      setIsLoading(true);
      setFormData(newValue);
      setTimeout(() => {
        dispatch(updateUser({ id: user.id, currency: newValue?.code }))
          .then(() => {
            enqueueSnackbar({
              variant: "success",
              message: "Preferred currency successfully updated",
            });
            setIsLoading(false);
            handleClose();
          })
          .catch(() => {
            enqueueSnackbar({
              variant: "error",
              message: DEFAULT_ERROR_MESSAGE,
            });
            setIsLoading(false);
            handleClose();
            setFormData(billing_conversion);
          });
      }, 500);
    }
  };

  React.useEffect(() => setFormData(billing_conversion), [billing_conversion]);

  if (isOpen)
    return (
      <CurrencyAutocomplete
        wrapperProps={{ minWidth: 250, maxWidth: 270 }}
        useQueryFnProps={useQueryFnProps}
        withSearchIcon={false}
        handleChange={handleChange}
        textFieldProps={{ autoFocus: true }}
        autocompleteProps={{ openOnFocus: true, onBlur: handleClose }}
      />
    );

  return (
    <CustomTooltip title="Change currency" tooltipProps={{ placement: "left" }}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        className="outlined borderRadius-8 button"
        style={{ padding: "0px 12px", minHeight: 40 }}
        onClick={handleOpen}
      >
        <Typography component="div" variant="body2">
          {code}
        </Typography>
        {isLoading && <CircularProgress size={16} />}
      </Stack>
    </CustomTooltip>
  );
}
