import React from "react";
import CustomValueWithUnit from "@components/Custom/CustomValueWithUnit";
import { getIntOrFloat } from "@helpers/parse/numbers";

export function CustomAmountApproved({ object }) {
  const { amount_requested, amount_approved, currency } = object;
  const amountRequested = getIntOrFloat(amount_requested) || "0";
  const amountApproved = getIntOrFloat(amount_approved);
  const isAmountApprovedNan = isNaN(parseInt(amount_approved));

  return (
    <CustomValueWithUnit
      value={isAmountApprovedNan ? amountRequested : amountApproved}
      separator={isAmountApprovedNan ? "" : "/"}
      unit={isAmountApprovedNan ? currency : `${amountRequested} ${currency}`}
      unitTypographyProps={{ className: "ellipsis-1" }}
    />
  );
}
