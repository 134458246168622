import React from "react";
import HealthcareOrganizationTag, {
  useHealthcareOrganizationTag,
} from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationTag";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import Stack from "@mui/material/Stack";

export default function ConsumableFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { isStaff } = useStaffContextProvider();
  const { submitter_organization, name, description } = formData || {};
  const useHealthcareOrganization = useHealthcareOrganizationTag({
    isStaff,
    submitter_organization: submitter_organization,
  });

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  return (
    <Stack spacing={3}>
      {useHealthcareOrganization && (
        <HealthcareOrganizationTag
          submitter_organization={submitter_organization}
        />
      )}
      <CustomOutlinedTextField
        required
        label="Name"
        value={name}
        name="name"
        onChange={handleChange}
      />
      <CustomOutlinedTextField
        label="Description"
        value={description}
        name="description"
        onChange={handleChange}
        multiline={true}
        minRows={3}
      />
    </Stack>
  );
}
