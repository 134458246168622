import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_RED } from "@styles/theme";
import CustomTooltip from "./CustomTooltip";

const errorWrapperProps = {
  border: `1px solid ${AVEY_RED}`,
  borderRadius: "4px",
  padding: "4px 8px",
  minWidth: 70,
};

export default function CustomLabeledText({
  label,
  value = "",
  helperText = "",
  labelProps = {},
  typographyProps = {},
  helperTextProps = {},
  defaultValue = "-",
  wrapperProps = {},
  isErrorField = false,
  title,
  useCustomTooltip = false,
  useChildren = false,
  children = null,
  tooltipProps = {},
}) {
  const val = value || defaultValue;
  const finalTitle = title ?? val;
  const childrenStyles = isErrorField ? errorWrapperProps : {};

  return (
    <Stack spacing={0.5} height="100%" {...wrapperProps}>
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        flexGrow={1}
        className="ellipsis-1"
        {...labelProps}
      >
        {label}
      </Typography>
      <div style={{ flexGrow: 1 }}>
        <CustomTooltip
          useCustomTooltip={useCustomTooltip}
          title={finalTitle}
          tooltipProps={tooltipProps}
        >
          <div style={childrenStyles}>
            {useChildren ? (
              children
            ) : (
              <Typography
                component="div"
                variant="body2"
                title={useCustomTooltip ? "" : finalTitle}
                {...typographyProps}
              >
                {val}
              </Typography>
            )}
          </div>
        </CustomTooltip>
      </div>
      {helperText !== "" && (
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          {...helperTextProps}
        >
          {helperText}
        </Typography>
      )}
    </Stack>
  );
}
