import React from "react";
import HealthcareOrganizationTag, {
  useHealthcareOrganizationTag,
} from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationTag";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import { UNIT_TYPE_MAPPER } from "@constants/avey/units";

export default function UnitData({
  object,
  useCustomSection = true,
  isMinified = false,
}) {
  const { isStaff } = useStaffContextProvider();
  const { submitter_organization, name, description, unit_type } = object || {};
  const unitType = UNIT_TYPE_MAPPER[unit_type];
  const useHealthcareOrganization = useHealthcareOrganizationTag({
    isStaff,
    submitter_organization,
  });

  return (
    <CustomSection
      title="Unit details"
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        {useHealthcareOrganization && (
          <HealthcareOrganizationTag
            submitter_organization={submitter_organization}
          />
        )}
        <CustomLabeledText label="Name" value={name} />
        <CustomLabeledText label="Type" value={unitType} />
        <CustomLabeledText label="Description" value={description} />
      </CustomGrid>
    </CustomSection>
  );
}

export const UnitDataReviewAndConfirm = ({ object }) => (
  <UnitData object={object} isMinified={true} />
);
