import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getSpecialitiesHref } from "@constants/static/routing";
import { useQueryFetchSpeciality } from "@redux/actions/avey/specialities";
import { Navigate, useParams } from "react-router-dom";
import SpecialityData from "./SpecialityData";
import SpecialityHeader from "./SpecialityHeader";

export default function Speciality() {
  const { as_staff } = useStaffContextProvider();
  const { speciality__id } = useParams();
  const { isLoading, data } = useQueryFetchSpeciality({
    speciality__id,
    as_staff,
  });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (Boolean(latest_version_id) && latest_version_id !== speciality__id) {
    const latestVersionHref = getSpecialitiesHref({
      speciality__id: latest_version_id,
      as_staff,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <SpecialityHeader object={data} />
      <CustomPage>
        <SpecialityData object={data} />
      </CustomPage>
    </>
  );
}
