import {
  AZURE_CPT_ENABLED,
  EES_ENABLED,
  GCP_CPT_ENABLED,
} from "@constants/static/globals";
import {
  AveyBloomApi,
  ServerApi,
  apiWrapper,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
} from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";
import { getUniqueList } from "../utils/utils";

const endpointUrl = "/avey/cpts/";
const queryKeySingular = "cpt";
const queryKeyPlural = "cpts";
const queryKeySingularHistory = `${queryKeySingular}_history`;

const eesEndpointUrl = "/api/ees/cpts/";
const azureEndpointUrl = "/api/azure/cpts/";
const gcpEndpointUrl = "/api/gcp/cpts/";

export const useQueryFetchCpts = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
  useEesIfEnabled = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters, useEesIfEnabled },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchCpts, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        useEesIfEnabled,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchCpts({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  useEesIfEnabled = true,
}) {
  try {
    if (EES_ENABLED && useEesIfEnabled) {
      let searchQueries = [searchQuery];
      let nlpEndpointUrl = null;
      if (Boolean(searchQuery)) {
        if (GCP_CPT_ENABLED) nlpEndpointUrl = gcpEndpointUrl;
        else if (AZURE_CPT_ENABLED) nlpEndpointUrl = azureEndpointUrl;
        if (nlpEndpointUrl) {
          const body = { searchQuery };
          const response = await ServerApi().post(nlpEndpointUrl, body);
          searchQueries = getUniqueList([searchQuery, ...response.data]);
        }
      }
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        searches: searchQueries,
        ordering: sortQuery,
        filters,
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...filters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchCpt = ({
  cpt__id = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: cpt__id }],
    queryFn: () =>
      apiWrapper({ fn: fetchCpt, ...apiWrapperProps })({ cpt__id }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchCpt({ cpt__id = "" }) {
  try {
    const response = await AveyBloomApi().get(`${endpointUrl}${cpt__id}/`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const useQueryFetchCptHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchCptHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchCptHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
