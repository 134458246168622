import React from "react";

const getEventName = (id) => `clickable-${id}`;

export const handleElementOnClickDispatchEvent = ({ id, detail }) =>
  window.dispatchEvent(new CustomEvent(getEventName(id), { detail }));

export const useElementOnClick = ({
  id,
  timeout = 2000,
  useScrollIntoView = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const eventName = getEventName(id);

  React.useEffect(() => {
    const handleIsOpen = (event) => {
      if (Boolean(event?.detail)) {
        const element = document.getElementById(id);
        if (element) {
          useScrollIntoView && element.scrollIntoView({ behavior: "smooth" });
          setIsOpen(true);
          setTimeout(() => setIsOpen(false), timeout);
        }
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener(eventName, handleIsOpen);

    return () => window.removeEventListener(eventName, handleIsOpen);
  }, [id, eventName, timeout, useScrollIntoView]);

  return { isOpen };
};
