import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import {
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import Grid from "@mui/material/Grid";
import OrganizationBundleConsumablesFormData from "./OrganizationBundleConsumablesFormData";
import OrganizationBundleCptsFormData from "./OrganizationBundleCptsFormData";
import OrganizationBundleDetailsFormData from "./OrganizationBundleDetailsFormData";

export default function OrganizationBundleFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { bundle_type } = useModelTypeContextProvider();
  const {
    organization_bundle_cpts = [],
    organization_bundle_consumables = [],
    ...otherFormData
  } = formData || {};

  const handleChangeOrganizationBundleCpts = (payload) =>
    setFormData((prev) => ({ ...prev, organization_bundle_cpts: payload }));

  const handleChangeOrganizationBundleConmsumables = (payload) =>
    setFormData((prev) => ({
      ...prev,
      organization_bundle_consumables: payload,
    }));

  switch (bundle_type) {
    case ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES:
      return (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrganizationBundleDetailsFormData
                formData={otherFormData}
                setFormData={setFormData}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <OrganizationBundleCptsFormData
                formData={organization_bundle_cpts}
                handleChange={handleChangeOrganizationBundleCpts}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <OrganizationBundleConsumablesFormData
                formData={organization_bundle_consumables}
                handleChange={handleChangeOrganizationBundleConmsumables}
              />
            </Grid>
          </Grid>
        </div>
      );

    case ORGANIZATION_BUNDLE_TYPE_MEDICATIONS:
      return (
        <OrganizationBundleDetailsFormData
          formData={otherFormData}
          setFormData={setFormData}
        />
      );

    default:
      break;
  }

  return <></>;
}
