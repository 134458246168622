import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import SpecialityCreateForm from "../Speciality/SpecialityForms/SpecialityCreateForm";

export default function SpecialityCreateForms() {
  return (
    <CustomAddOrUploadWrapper label="Add specialities" useUploadForm={false}>
      <SpecialityCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
