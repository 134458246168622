import {
  BLACK_MARKED_PILL,
  BLACK_PILL,
  BLUE_MARKED_PILL,
  BLUE_PILL,
  GREY_MARKED_PILL,
  GREY_PILL,
  LIGHT_BLUE_MARKED_PILL,
  LIGHT_BLUE_PILL,
  LIGHT_GREY_MARKED_PILL,
  OUTLINED_BLACK_PILL,
  OUTLINED_GREY_PILL,
  PENDING_STATUS,
  PROCESSING_STATUS,
  RED_LIGHT_MARKED_PILL,
  RED_LIGHT_PILL,
  RED_MARKED_PILL,
  RED_PILL,
  WHITE_PILL,
  YELLOW_MARKED_PILL,
  YELLOW_PILL,
} from "@components/Custom/CustomPills";
import { CLAIM_MODE_SUBMIT } from "@constants/static/system-modes";
import {
  AVEY_BLACK,
  AVEY_BLUE,
  AVEY_BLUE_LIGHT,
  AVEY_GREY,
  AVEY_GREY_F2,
  AVEY_RED,
  AVEY_RED_LIGHT,
  AVEY_YELLOW,
} from "@styles/theme";
import { getOptions } from "../../helpers/hooks/objects";
import {
  ORGANIZATION_ROLE_PROCESSOR,
  ORGANIZATION_ROLE_SUBMITTER,
  ORGANIZATION_ROLE_TPA,
} from "../organizations/organizations";

export const CLAIM_STATUS_ALL = "";
export const CLAIM_STATUS_PENDING = "P";
export const CLAIM_STATUS_PROCESSING = "L";
export const CLAIM_STATUS_ALERTED = "Y";
export const CLAIM_STATUS_VALIDATED = "Z";
export const CLAIM_STATUS_APPROVED = "A";
export const CLAIM_STATUS_PARTIALLY_APPROVED = "V";
const CLAIM_STATUS_CANCELED = "C";
const CLAIM_STATUS_INCOMPLETE = "I";
const CLAIM_STATUS_ERROR = "E";
const CLAIM_STATUS_REJECTED = "R";
const CLAIM_STATUS_RETURNED = "D";
export const CLAIM_STATUS_TO_REVIEW = "Q";

const CLAIM_STATUS_TEXT_ALL = "All";
const CLAIM_STATUS_TEXT_PENDING = "Pending";
const CLAIM_STATUS_TEXT_PROCESSING = "Processing";
const CLAIM_STATUS_TEXT_ALERTED = "Alerted";
const CLAIM_STATUS_TEXT_VALIDATED = "Validated";
const CLAIM_STATUS_TEXT_APPROVED = "Approved";
const CLAIM_STATUS_TEXT_PARTIALLY_APPROVED = "Partially approved";
const CLAIM_STATUS_TEXT_CANCELED = "Canceled";
const CLAIM_STATUS_TEXT_INCOMPLETE = "Incomplete";
const CLAIM_STATUS_TEXT_ERROR = "Error";
const CLAIM_STATUS_TEXT_REJECTED = "Rejected";
const CLAIM_STATUS_TEXT_RETURNED = "Sent back";
const CLAIM_STATUS_TEXT_TO_REVIEW = "To review";

export const CLAIM_STATUS_TEXT_MAPPER = {
  [CLAIM_STATUS_ALL]: CLAIM_STATUS_TEXT_ALL,
  [CLAIM_STATUS_PENDING]: CLAIM_STATUS_TEXT_PENDING,
  [CLAIM_STATUS_CANCELED]: CLAIM_STATUS_TEXT_CANCELED,
  [CLAIM_STATUS_PROCESSING]: CLAIM_STATUS_TEXT_PROCESSING,
  [CLAIM_STATUS_INCOMPLETE]: CLAIM_STATUS_TEXT_INCOMPLETE,
  [CLAIM_STATUS_ERROR]: CLAIM_STATUS_TEXT_ERROR,
  [CLAIM_STATUS_ALERTED]: CLAIM_STATUS_TEXT_ALERTED,
  [CLAIM_STATUS_VALIDATED]: CLAIM_STATUS_TEXT_VALIDATED,
  [CLAIM_STATUS_APPROVED]: CLAIM_STATUS_TEXT_APPROVED,
  [CLAIM_STATUS_PARTIALLY_APPROVED]: CLAIM_STATUS_TEXT_PARTIALLY_APPROVED,
  [CLAIM_STATUS_REJECTED]: CLAIM_STATUS_TEXT_REJECTED,
  [CLAIM_STATUS_RETURNED]: CLAIM_STATUS_TEXT_RETURNED,
  [CLAIM_STATUS_TO_REVIEW]: CLAIM_STATUS_TEXT_TO_REVIEW,
};

const getClaimStatusTextMapper = (options) =>
  options.reduce((acc, option) => {
    acc[option] = CLAIM_STATUS_TEXT_MAPPER[option];
    return acc;
  }, {});

const getClaimStatusTextOptions = (options) =>
  getOptions(getClaimStatusTextMapper(options));

export const getClaimSubmissionColorMapper = ({ organizationRole }) => {
  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      return {
        [CLAIM_STATUS_PENDING]: AVEY_GREY_F2,
        [CLAIM_STATUS_PROCESSING]: AVEY_BLACK,
        [CLAIM_STATUS_INCOMPLETE]: AVEY_GREY,
        [CLAIM_STATUS_ERROR]: AVEY_YELLOW,
        [CLAIM_STATUS_APPROVED]: AVEY_BLUE,
        [CLAIM_STATUS_PARTIALLY_APPROVED]: AVEY_BLUE_LIGHT,
        [CLAIM_STATUS_RETURNED]: AVEY_YELLOW,
        [CLAIM_STATUS_REJECTED]: AVEY_RED,
        [CLAIM_STATUS_CANCELED]: AVEY_GREY_F2,
        [CLAIM_STATUS_TO_REVIEW]: AVEY_RED_LIGHT,
      };
    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
    default:
      return {
        [CLAIM_STATUS_PENDING]: AVEY_GREY_F2,
        [CLAIM_STATUS_PROCESSING]: AVEY_BLACK,
        [CLAIM_STATUS_INCOMPLETE]: AVEY_GREY,
        [CLAIM_STATUS_ERROR]: AVEY_YELLOW,
        [CLAIM_STATUS_ALERTED]: AVEY_YELLOW,
        [CLAIM_STATUS_VALIDATED]: AVEY_BLACK,
        [CLAIM_STATUS_APPROVED]: AVEY_BLUE,
        [CLAIM_STATUS_PARTIALLY_APPROVED]: AVEY_BLUE_LIGHT,
        [CLAIM_STATUS_RETURNED]: AVEY_GREY_F2,
        [CLAIM_STATUS_REJECTED]: AVEY_RED,
        [CLAIM_STATUS_CANCELED]: AVEY_GREY_F2,
        [CLAIM_STATUS_TO_REVIEW]: AVEY_RED_LIGHT,
      };
  }
};

export const getClaimStatusFilterMapper = ({ claimMode, organizationRole }) => {
  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      const options = [
        CLAIM_STATUS_ALL,
        CLAIM_STATUS_PENDING,
        CLAIM_STATUS_PROCESSING,
        CLAIM_STATUS_INCOMPLETE,
        CLAIM_STATUS_ERROR,
        CLAIM_STATUS_APPROVED,
        CLAIM_STATUS_PARTIALLY_APPROVED,
        CLAIM_STATUS_REJECTED,
        CLAIM_STATUS_CANCELED,
        CLAIM_STATUS_TO_REVIEW,
      ];
      if (claimMode === CLAIM_MODE_SUBMIT) options.push(CLAIM_STATUS_RETURNED);

      return {
        textOptions: getClaimStatusTextOptions(options),
        propsMapper: {
          [CLAIM_STATUS_ALL]: BLACK_PILL,
          [CLAIM_STATUS_PENDING]: OUTLINED_GREY_PILL,
          [CLAIM_STATUS_PROCESSING]: OUTLINED_BLACK_PILL,
          [CLAIM_STATUS_INCOMPLETE]: GREY_PILL,
          [CLAIM_STATUS_ERROR]: YELLOW_PILL,
          [CLAIM_STATUS_APPROVED]: BLUE_PILL,
          [CLAIM_STATUS_PARTIALLY_APPROVED]: LIGHT_BLUE_PILL,
          [CLAIM_STATUS_RETURNED]: YELLOW_PILL,
          [CLAIM_STATUS_REJECTED]: RED_PILL,
          [CLAIM_STATUS_CANCELED]: WHITE_PILL,
          [CLAIM_STATUS_TO_REVIEW]: RED_LIGHT_PILL,
        },
      };

    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
    default:
      return {
        textOptions: getClaimStatusTextOptions([
          CLAIM_STATUS_ALL,
          CLAIM_STATUS_PROCESSING,
          CLAIM_STATUS_ERROR,
          CLAIM_STATUS_INCOMPLETE,
          CLAIM_STATUS_ALERTED,
          CLAIM_STATUS_VALIDATED,
          CLAIM_STATUS_REJECTED,
          CLAIM_STATUS_APPROVED,
          CLAIM_STATUS_PARTIALLY_APPROVED,
          CLAIM_STATUS_RETURNED,
          CLAIM_STATUS_CANCELED,
          CLAIM_STATUS_TO_REVIEW,
        ]),
        propsMapper: {
          [CLAIM_STATUS_ALL]: WHITE_PILL,
          [CLAIM_STATUS_PROCESSING]: WHITE_PILL,
          [CLAIM_STATUS_ERROR]: YELLOW_PILL,
          [CLAIM_STATUS_INCOMPLETE]: GREY_PILL,
          [CLAIM_STATUS_ALERTED]: YELLOW_PILL,
          [CLAIM_STATUS_VALIDATED]: BLACK_PILL,
          [CLAIM_STATUS_REJECTED]: RED_PILL,
          [CLAIM_STATUS_APPROVED]: BLUE_PILL,
          [CLAIM_STATUS_PARTIALLY_APPROVED]: LIGHT_BLUE_PILL,
          [CLAIM_STATUS_RETURNED]: OUTLINED_GREY_PILL,
          [CLAIM_STATUS_CANCELED]: WHITE_PILL,
          [CLAIM_STATUS_TO_REVIEW]: RED_LIGHT_PILL,
        },
      };
  }
};

export const getClaimStatusMapper = ({ organizationRole }) => {
  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      return {
        textMapper: getClaimStatusTextMapper([
          CLAIM_STATUS_PENDING,
          CLAIM_STATUS_ERROR,
          CLAIM_STATUS_PROCESSING,
          CLAIM_STATUS_ALERTED,
          CLAIM_STATUS_VALIDATED,
          CLAIM_STATUS_INCOMPLETE,
          CLAIM_STATUS_APPROVED,
          CLAIM_STATUS_PARTIALLY_APPROVED,
          CLAIM_STATUS_REJECTED,
          CLAIM_STATUS_RETURNED,
          CLAIM_STATUS_CANCELED,
          CLAIM_STATUS_TO_REVIEW,
        ]),
        propsMapper: {
          [CLAIM_STATUS_PENDING]: PENDING_STATUS,
          [CLAIM_STATUS_ERROR]: YELLOW_MARKED_PILL,
          [CLAIM_STATUS_PROCESSING]: PROCESSING_STATUS,
          [CLAIM_STATUS_ALERTED]: PENDING_STATUS,
          [CLAIM_STATUS_VALIDATED]: PENDING_STATUS,
          [CLAIM_STATUS_INCOMPLETE]: GREY_MARKED_PILL,
          [CLAIM_STATUS_APPROVED]: BLUE_MARKED_PILL,
          [CLAIM_STATUS_PARTIALLY_APPROVED]: LIGHT_BLUE_MARKED_PILL,
          [CLAIM_STATUS_REJECTED]: RED_MARKED_PILL,
          [CLAIM_STATUS_RETURNED]: YELLOW_MARKED_PILL,
          [CLAIM_STATUS_CANCELED]: LIGHT_GREY_MARKED_PILL,
          [CLAIM_STATUS_TO_REVIEW]: RED_LIGHT_MARKED_PILL,
        },
      };

    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
    default:
      return {
        textMapper: getClaimStatusTextMapper([
          CLAIM_STATUS_PENDING,
          CLAIM_STATUS_ERROR,
          CLAIM_STATUS_PROCESSING,
          CLAIM_STATUS_ALERTED,
          CLAIM_STATUS_VALIDATED,
          CLAIM_STATUS_INCOMPLETE,
          CLAIM_STATUS_APPROVED,
          CLAIM_STATUS_PARTIALLY_APPROVED,
          CLAIM_STATUS_REJECTED,
          CLAIM_STATUS_RETURNED,
          CLAIM_STATUS_CANCELED,
          CLAIM_STATUS_TO_REVIEW,
        ]),
        propsMapper: {
          [CLAIM_STATUS_PENDING]: PENDING_STATUS,
          [CLAIM_STATUS_ERROR]: YELLOW_MARKED_PILL,
          [CLAIM_STATUS_PROCESSING]: PROCESSING_STATUS,
          [CLAIM_STATUS_ALERTED]: YELLOW_MARKED_PILL,
          [CLAIM_STATUS_VALIDATED]: BLACK_MARKED_PILL,
          [CLAIM_STATUS_INCOMPLETE]: GREY_MARKED_PILL,
          [CLAIM_STATUS_APPROVED]: BLUE_MARKED_PILL,
          [CLAIM_STATUS_PARTIALLY_APPROVED]: LIGHT_BLUE_MARKED_PILL,
          [CLAIM_STATUS_REJECTED]: RED_MARKED_PILL,
          [CLAIM_STATUS_RETURNED]: PENDING_STATUS,
          [CLAIM_STATUS_CANCELED]: LIGHT_GREY_MARKED_PILL,
          [CLAIM_STATUS_TO_REVIEW]: RED_LIGHT_PILL,
        },
      };
  }
};

export const getClaimExportStatusOptions = ({
  claimMode,
  organizationRole,
}) => {
  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      let options = [
        CLAIM_STATUS_INCOMPLETE,
        CLAIM_STATUS_ERROR,
        CLAIM_STATUS_APPROVED,
        CLAIM_STATUS_PARTIALLY_APPROVED,
        CLAIM_STATUS_REJECTED,
        CLAIM_STATUS_CANCELED,
        CLAIM_STATUS_TO_REVIEW,
      ];
      if (claimMode === CLAIM_MODE_SUBMIT) options.push(CLAIM_STATUS_RETURNED);
      return options;

    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
    default:
      return [
        CLAIM_STATUS_ERROR,
        CLAIM_STATUS_INCOMPLETE,
        CLAIM_STATUS_ALERTED,
        CLAIM_STATUS_VALIDATED,
        CLAIM_STATUS_APPROVED,
        CLAIM_STATUS_PARTIALLY_APPROVED,
        CLAIM_STATUS_REJECTED,
        CLAIM_STATUS_RETURNED,
        CLAIM_STATUS_CANCELED,
        CLAIM_STATUS_TO_REVIEW,
      ];
  }
};

export const CLAIM_STATUS_PROCESSOR_ACTION_MAPPER = {
  [CLAIM_STATUS_APPROVED]: {
    label: "Approve",
    color: "success",
    variant: "contained",
    icon: "TickIcon",
  },
  [CLAIM_STATUS_REJECTED]: {
    label: "Reject",
    color: "secondary",
    variant: "contained",
    icon: "CloseIcon",
  },
  [CLAIM_STATUS_RETURNED]: {
    label: "Send back",
    color: "primary",
    variant: "contained",
    icon: "SendBackIcon",
  },
};

export const CLAIM_STATUS_PROCESSOR_ACTION_OPTIONS = getOptions(
  CLAIM_STATUS_PROCESSOR_ACTION_MAPPER
);
