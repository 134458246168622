import { DEFAULT_ERROR_MESSAGE } from "@constants/static/text";
import {
  apiWrapper,
  AveyBloomApi,
  getQueryParams,
  PayloadError,
} from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/utils/";
const queryKeyOrganizationCounts = "organization-count";
const queryKeyOrganizationPartnerCounts =
  "organization-partner-related-object-count";
const queryKeyAppConfigurationsCounts = "app-configurations-count";

export const getUniqueList = (list) => [...new Set(list)];

export async function sendSMS(data) {
  try {
    const response = await AveyBloomApi().post(`${endpointUrl}send_sms/`, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload:
        error?.response?.data?.detail ||
        error?.response.data?.reason ||
        DEFAULT_ERROR_MESSAGE,
    });
  }
}

export const useQueryFetchOrganizationPartnerRelatedObjectsCount = ({
  organization__slug = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeyOrganizationPartnerCounts, { organization__slug }],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationPartnerRelatedObjectsCount,
        ...apiWrapperProps,
      })({ organization__slug }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationPartnerRelatedObjectsCount({
  organization__slug = "",
}) {
  try {
    const queryParams = getQueryParams({ organization__slug });
    const response = await AveyBloomApi().get(
      `${endpointUrl}configurations/organization_partner_related_object_counts/?${queryParams}`
    );
    return response.data;
  } catch (error) {
    return {};
  }
}

export const useQueryFetchOrganizationRelatedObjectsCount = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeyOrganizationCounts],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationRelatedObjectsCount,
        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationRelatedObjectsCount() {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}configurations/organization_related_object_counts/`
    );
    return response.data;
  } catch (error) {
    return {};
  }
}

export const useQueryFetchAppConfigurationCount = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeyAppConfigurationsCounts],
    queryFn: () =>
      apiWrapper({
        fn: fetchAppConfigurationCount,
        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchAppConfigurationCount() {
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}configurations/app_configuration_counts/`
    );
    return response.data;
  } catch (error) {
    return {};
  }
}
