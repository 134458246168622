import React from "react";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteIdentityType,
  invalidateIdentityTypesTSQ,
  updateIdentityTypeObjectTSQ,
} from "@redux/actions/avey/identity-types";

export default function IdentityTypeActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateIdentityTypesTSQ();
    updateIdentityTypeObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="identity type"
      deleteFn={deleteIdentityType}
      handleDeleteFn={handleDeleteFn}
    />
  );
}
