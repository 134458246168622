import React from "react";
import CountriesAutocomplete from "@components/Custom/CustomAutocomplete/CountryAutocomplete/CountriesAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import Grid from "@mui/material/Grid";

export default function IdentityTypeFormData({
  formData = {},
  setFormData = () => {},
}) {
  const { code, name, description, countries } = formData || {};

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeCountries = (newValue) =>
    setFormData((prev) => ({ ...prev, countries: newValue }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            autoFocus
            label="Code"
            value={code}
            name="code"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            required
            label="Name"
            value={name}
            name="name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CountriesAutocomplete
            value={countries}
            handleChange={handleChangeCountries}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            label="Description"
            value={description}
            name="description"
            onChange={handleChange}
            multiline={true}
            minRows={3}
          />
        </Grid>
      </Grid>
    </div>
  );
}
