import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const endpointUrl = "/organizations/organization_agreements/";
const queryKeyPlural = "organization_agreements";
const queryKeySingular = "organization_agreement";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchOrganizationAgreements = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationAgreements,
        ...apiWrapperProps,
      })({ page, page_size, searchQuery, sortQuery, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationAgreements({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchOrganizationAgreementHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchOrganizationAgreementHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationAgreementHistory({
  page = 1,
  id,
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchOrganizationAgreement = ({
  processor_organization__slug = "",
  isSubmitterOrganization = true,
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [
      queryKeySingular,
      { processor_organization__slug, isSubmitterOrganization },
    ],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationAgreement,
        ...apiWrapperProps,
      })({ processor_organization__slug, isSubmitterOrganization }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationAgreement({
  processor_organization__slug,
  isSubmitterOrganization,
}) {
  if (!isSubmitterOrganization) return null;
  try {
    const response = await AveyBloomApi().get(
      `${endpointUrl}${processor_organization__slug}/`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function updateOrganizationAgreement(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function activateMaintenanceOrganizationAgreement(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/activate_maintenance/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateOrganizationAgreementObjectTSQ({
  object,
  processor_organization__slug,
}) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular &&
      queryKey[1].processor_organization__slug === processor_organization__slug,
    object,
  });
  updatePaginatedTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyPlural,
    isObjectPredicate: ({ object, result }) =>
      object?.processor_organization?.slug ===
      result?.processor_organization?.slug,
    object,
  });
}

export function invalidateOrganizationAgreementsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyPlural,
  });
}
