import { CircularProgress } from "@mui/material";
import { ClockIcon } from "@rimads/assets";
import {
  AVEY_BLACK,
  AVEY_BLUE,
  AVEY_BLUE_LIGHT,
  AVEY_GREY,
  AVEY_GREY_DARK,
  AVEY_GREY_F2,
  AVEY_RED,
  AVEY_RED_LIGHT,
  AVEY_WHITE,
  AVEY_YELLOW,
} from "@styles/theme";

export const PILL_BORDER_RADIUS = "6px";
const DEFAULT_OUTER_WRRAPPER_PROPS = {
  borderRadius: PILL_BORDER_RADIUS,
  overflow: "hidden",
  className: "elevated-8",
};

const getPill = ({ color = AVEY_BLACK, bgColor = AVEY_WHITE, ...other }) => ({
  color,
  backgroundColor: bgColor,
  p: "3px 6px",
  borderRadius: PILL_BORDER_RADIUS,
  ...other,
});

const getOutlinedPill = ({ color, outlinedColor }) => ({
  color,
  boxShadow: `inset 0px 0px 0px 1px ${outlinedColor}`,
  p: "3px 6px",
  borderRadius: PILL_BORDER_RADIUS,
});

const getMarkedPill = ({ color }) => ({
  outerWrapperProps: DEFAULT_OUTER_WRRAPPER_PROPS,
  innerWrapperProps: {
    boxShadow: `inset 6px 0 0 0 ${color}`,
    backgroundColor: AVEY_WHITE,
    padding: "6px 8px 6px 12px",
  },
});

export const WHITE_PILL = getPill({
  color: AVEY_BLACK,
  bgColor: AVEY_WHITE,
  p: "3px 0px",
});
export const BLACK_PILL = getPill({ color: AVEY_WHITE, bgColor: AVEY_BLACK });
export const GREY_PILL = getPill({
  color: AVEY_WHITE,
  bgColor: AVEY_GREY,
});
export const BLUE_PILL = getPill({ color: AVEY_WHITE, bgColor: AVEY_BLUE });
export const LIGHT_BLUE_PILL = getPill({
  color: AVEY_BLACK,
  bgColor: AVEY_BLUE_LIGHT,
});
export const YELLOW_PILL = getPill({
  color: AVEY_BLACK,
  bgColor: AVEY_YELLOW,
});
export const RED_PILL = getPill({ color: AVEY_WHITE, bgColor: AVEY_RED });
export const RED_LIGHT_PILL = getPill({
  color: AVEY_BLACK,
  bgColor: AVEY_RED_LIGHT,
});
export const OUTLINED_GREY_PILL = getOutlinedPill({
  color: AVEY_GREY_DARK,
  outlinedColor: AVEY_GREY,
});
export const OUTLINED_BLACK_PILL = getOutlinedPill({
  color: AVEY_BLACK,
  outlinedColor: AVEY_BLACK,
});
export const BLUE_MARKED_PILL = getMarkedPill({ color: AVEY_BLUE });
export const LIGHT_BLUE_MARKED_PILL = getMarkedPill({ color: AVEY_BLUE_LIGHT });
export const RED_MARKED_PILL = getMarkedPill({ color: AVEY_RED });
export const BLACK_MARKED_PILL = getMarkedPill({ color: AVEY_BLACK });
export const YELLOW_MARKED_PILL = getMarkedPill({ color: AVEY_YELLOW });
export const GREY_MARKED_PILL = getMarkedPill({ color: AVEY_GREY });
export const LIGHT_GREY_MARKED_PILL = getMarkedPill({ color: AVEY_GREY_F2 });
export const RED_LIGHT_MARKED_PILL = getMarkedPill({ color: AVEY_RED_LIGHT });

export const PENDING_STATUS = {
  icon: <ClockIcon />,
  typographyProps: {
    color: "textSecondary",
  },
  outerWrapperProps: DEFAULT_OUTER_WRRAPPER_PROPS,
  innerWrapperProps: {
    backgroundColor: AVEY_WHITE,
    padding: "6px 8px",
  },
};

export const PROCESSING_STATUS = {
  icon: <CircularProgress size={12} />,
  outerWrapperProps: DEFAULT_OUTER_WRRAPPER_PROPS,
  innerWrapperProps: {
    backgroundColor: AVEY_WHITE,
    padding: "6px 8px",
  },
};

export const CANCELED_STATUS = {
  typographyProps: {
    color: "textSecondary",
  },
};
