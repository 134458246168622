import React from "react";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import ConfigurationsBulkUploadsGrid from "./ConfigurationsBulkUploadsGrid";
import ConfigurationsBulkUploadsHeader from "./ConfigurationsBulkUploadsHeader";

export default function ConfigurationsBulkUploads() {
  return (
    <>
      <ConfigurationsBulkUploadsHeader />
      <CustomPage mt={0}>
        <ConfigurationsBulkUploadsGrid />
      </CustomPage>
    </>
  );
}
