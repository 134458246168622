import React from "react";
import CustomTooltip from "@components/Custom/CustomTooltip";
import Stack from "@mui/material/Stack";
import CustomChip from "./CustomChip";
import CustomGrid from "./CustomLayout/CustomGrid";
import CustomTypographyButton from "./CustomTypographyButton";

export default function CustomDividedList({
  list = [],
  divideAt = 2,
  CustomObjectComponent = CustomChip,
  wrapperProps = {},
  tooltipProps = {},
}) {
  const firstList = divideAt === null ? list : list.slice(0, divideAt);
  const secondList = divideAt === null ? [] : list.slice(divideAt);
  const secondListLength = secondList.length;

  if (list.length === 0) return "-";

  return (
    <CustomGrid
      rowSpacing={1}
      columnSpacing={1}
      alignItems="baseline"
      {...wrapperProps}
    >
      {firstList.map((props, index) => (
        <CustomObjectComponent key={index} {...props} />
      ))}
      {secondListLength > 0 && (
        <CustomTooltip
          tooltipProps={tooltipProps}
          title={
            <Stack spacing={1} maxHeight={200} overflow="scroll">
              {secondList.map((props, index) => (
                <CustomObjectComponent
                  key={index}
                  placement="right"
                  {...props}
                />
              ))}
            </Stack>
          }
        >
          <CustomTypographyButton
            value={`+${secondListLength} more`}
            fontWeight="normal"
          />
        </CustomTooltip>
      )}
    </CustomGrid>
  );
}
