import React from "react";
import { useQueryFetchIdentityTypes } from "@redux/actions/avey/identity-types";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getIdentityTypeOptionLabel,
  isIdentityTypeOptionEqualToValue,
  renderIdentityTypeOption,
} from "./IdentityTypeAutocompleteComponents";
import { useSelector } from "react-redux";
import { selectorAuthCurrentUserOrganizationCountryId } from "@redux/selectors/auth";

export default function IdentityTypeAutocomplete({
  label = "Identity type",
  error = false,
  value = null,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const country_id = useSelector(selectorAuthCurrentUserOrganizationCountryId);
  const useQueryFnProps = {
    filters: { time_deleted__isnull: true, newer_version__isnull: true },
    eesFilters: { country_id },
  };

  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        disableClearable: true,
        renderOption: renderIdentityTypeOption,
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchIdentityTypes}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getIdentityTypeOptionLabel}
      isOptionEqualToValue={isIdentityTypeOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
