import React from "react";
import DrugNameCreateForm from "@components/Authenticated/Configurations/DrugNames/DrugName/DrugNameForms/DrugNameCreateForm";
import { DRUG_NAME_TYPE_MAPPER } from "@constants/avey/drug-names";
import { useQueryFetchDrugNames } from "@redux/actions/avey/drug-names";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getDrugNameOptionLabel,
  isDrugNameOptionEqualToValue,
  renderDrugNameOption,
} from "./DrugNameAutocompleteComponents";

export default function DrugNameAutocomplete({
  label = "Drug",
  error = false,
  value,
  drug_name_type = null,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [initialFormData, setInitialFormData] = React.useState({});
  const autocompleteProps = React.useMemo(
    () => ({
      renderOption: renderDrugNameOption,
      noOptionsTextProps: {
        label: DRUG_NAME_TYPE_MAPPER[drug_name_type],
        handleIsOpen: ({ searchQuery }) => {
          setInitialFormData({ name: searchQuery, drug_name_type });
          setIsOpen(true);
        },
      },
    }),
    [drug_name_type]
  );
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
      drug_name_type,
    },
    eesFilters: { submitter_organization__slug },
    useInfiniteWrapper: true,
  };

  return (
    <>
      <CustomInfiniteAutocomplete
        label={label}
        error={error}
        wrapperProps={wrapperProps}
        textFieldProps={{ required: true, ...textFieldProps }}
        autocompleteProps={autocompleteProps}
        useQueryFn={useQueryFetchDrugNames}
        useQueryFnProps={useQueryFnProps}
        getOptionLabel={getDrugNameOptionLabel}
        isOptionEqualToValue={isDrugNameOptionEqualToValue}
        value={value}
        handleChange={handleChange}
      />
      <DrugNameCreateForm
        parentIsOpen={isOpen}
        parentSetIsOpen={setIsOpen}
        handleSelect={handleChange}
        withCustomButton={false}
        isDrugNameTypeFixed={true}
        initialFormData={initialFormData}
      />
    </>
  );
}
