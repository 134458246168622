import React from "react";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteSpeciality,
  invalidateSpecialitiesTSQ,
  updateSpecialityObjectTSQ,
} from "@redux/actions/avey/specialities";

export default function SpecialityActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateSpecialitiesTSQ();
    updateSpecialityObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="speciality"
      deleteFn={deleteSpeciality}
      handleDeleteFn={handleDeleteFn}
    />
  );
}
