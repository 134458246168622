import React from "react";
import { CustomDndListSingleton } from "@components/Custom/CustomDnd/CustomDnd";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import {
  CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimIcdCreateForm from "./ClaimIcdCreateForm";
import ClaimIcdData from "./ClaimIcdData";
import ClaimIcdUpdateForm from "./ClaimIcdUpdateForm";
import ClaimIcdsTextFormData from "./ClaimIcdsText/ClaimIcdsTextFormData";

export default function ClaimIcdsFormData({
  formData = {},
  handleUpdate = () => {},
  type = CLAIM_SECTION_SYMPTOMS,
  icd_type,
  useMigrateIcds = true,
  useCollapse = true,
}) {
  const { claim_icds = [], claim_icds_text = "" } = formData;

  const [state, setState] = React.useState({
    isOpen: false,
    object: null,
    index: 0,
  });

  const numClaimIcds = claim_icds.length;
  const hasClaimIcds = numClaimIcds > 0;

  const handleSelectClaimIcd = ({ object, index }) =>
    setState({ isOpen: true, object, index });

  const handleAddClaimIcd = ({ payload }) =>
    handleUpdate({ claim_icds: [...claim_icds, payload] });

  const handleUpdateClaimIcd = ({ payload, index }) =>
    handleUpdate({
      claim_icds: claim_icds.map((object, position) =>
        index === position ? payload : object
      ),
    });

  const handleRemoveClaimIcd = ({ index }) =>
    handleUpdate({
      claim_icds: claim_icds.filter((_, position) => index !== position),
    });

  const handleReorderClaimIcds = (payload) =>
    handleUpdate({ claim_icds: payload });

  const handleMigrateClaimIcd = ({ index }) => {
    const new_claim_icds = [...claim_icds];
    const [claim_icd] = new_claim_icds.splice(index, 1);
    handleUpdate({
      claim_icds: new_claim_icds,
      claim_icd_to_inverse: claim_icd,
    });
  };

  const handleBlurClaimIcdsText = (e) =>
    handleUpdate({ claim_icds_text: e.target.value });

  return (
    <>
      <Stack spacing={3}>
        <CustomListItemCollapse
          primary={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
          useCollapse={useCollapse}
        >
          <Stack spacing={3}>
            {hasClaimIcds ? (
              <CustomDndListSingleton
                spacing={3}
                list={claim_icds}
                setList={handleReorderClaimIcds}
                useDivider={true}
                renderObject={({ object, index }) => (
                  <Box py={1}>
                    <Box p={2} className="whiteBg elevated-4 borderRadius-8">
                      <ClaimIcdData
                        index={index}
                        type={type}
                        object={object}
                        useErrorFieldnames={true}
                        isCreateOrUpdateMode={true}
                        handleSelectClaimIcd={handleSelectClaimIcd}
                        handleRemoveClaimIcd={handleRemoveClaimIcd}
                        handleMigrateClaimIcd={handleMigrateClaimIcd}
                        useMigrateIcds={useMigrateIcds}
                      />
                    </Box>
                  </Box>
                )}
              />
            ) : (
              <CustomNotProvidedText
                title={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
              />
            )}
            <ClaimIcdCreateForm
              index={numClaimIcds}
              type={type}
              handleAddClaimIcd={handleAddClaimIcd}
              icd_type={icd_type}
            />
          </Stack>
        </CustomListItemCollapse>
        <Divider />
        <ClaimIcdsTextFormData
          type={type}
          claim_icds_text={claim_icds_text}
          handleBlur={handleBlurClaimIcdsText}
          useCollapse={useCollapse}
        />
      </Stack>
      <ClaimIcdUpdateForm
        type={type}
        state={state}
        setState={setState}
        handleUpdateClaimIcd={handleUpdateClaimIcd}
        icd_type={icd_type}
      />
    </>
  );
}
