import React from "react";
import AppConfigurations from "@components/Authenticated/Configurations/Configurations/AppConfigurations";
import ConfigurationsBulkExports from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExports";
import Consumable from "@components/Authenticated/Configurations/Consumables/Consumable/Consumable";
import DrugName from "@components/Authenticated/Configurations/DrugNames/DrugName/DrugName";
import IdentityType from "@components/Authenticated/Configurations/IdentityTypes/IdentityType/IdentityType";
import Medication from "@components/Authenticated/Configurations/Medications/Medication/Medication";
import Speciality from "@components/Authenticated/Configurations/Specialities/Speciality/Speciality";
import Unit from "@components/Authenticated/Configurations/Units/Unit/Unit";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { StaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import {
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
} from "@constants/static/globals";
import {
  CONSUMABLE_EXPORTS_HREF,
  CONSUMABLE_UPLOADS_HREF,
  CONSUMABLES_HREF,
  DRUG_NAMES_HREF,
  IDENTITY_TYPES_HREF,
  MEDICATION_EXPORTS_HREF,
  MEDICATION_UPLOADS_HREF,
  MEDICATIONS_HREF,
  SPECIALITIES_HREF,
  UNITS_HREF,
} from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";
import UploadRoutes from "../upload-routes";

export default function AppConfigurationRoutes() {
  return (
    <StaffContextProvider as_staff="1">
      <Routes>
        <Route path="" Component={AppConfigurations} />
        <Route
          path={`${CONSUMABLES_HREF}/:consumable__id`}
          Component={Consumable}
        />
        <Route
          path={`${DRUG_NAMES_HREF}/:drug_name__id`}
          Component={DrugName}
        />
        <Route
          path={`${IDENTITY_TYPES_HREF}/:identity_type__id`}
          Component={IdentityType}
        />
        <Route
          path={`${MEDICATIONS_HREF}/:medication__id`}
          Component={Medication}
        />
        <Route
          path={`${SPECIALITIES_HREF}/:speciality__id`}
          Component={Speciality}
        />
        <Route path={`${UNITS_HREF}/:unit__id`} Component={Unit} />
        <Route
          path={`${CONSUMABLE_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_CONSUMABLE}>
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${CONSUMABLE_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_CONSUMABLE}>
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${MEDICATION_UPLOADS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_MEDICATION}>
              <UploadRoutes />
            </ModelTypeContextProvider>
          }
        />
        <Route
          path={`${MEDICATION_EXPORTS_HREF}/*`}
          element={
            <ModelTypeContextProvider model_type={MODEL_TYPE_MEDICATION}>
              <ConfigurationsBulkExports />
            </ModelTypeContextProvider>
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </StaffContextProvider>
  );
}
