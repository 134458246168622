import React from "react";
import Typography from "@mui/material/Typography";

const REQUIRED_FIELDS_KEY = "{required_fields}";
const ERROR_MESSAGE_KEY = "{error_message}";

export default function ClaimEvaluationResultContext({ result = {} }) {
  const { error_fieldnames = [], error_message = "" } = result || {};
  const hasErrorFieldnames = error_fieldnames.length > 0;

  return {
    [REQUIRED_FIELDS_KEY]: hasErrorFieldnames && (
      <span>
        {error_fieldnames?.map((required_field, index) => {
          const delimiter = index < error_fieldnames.length - 1 ? ", " : "";
          return (
            <Typography variant="body2" display="inline" key={index}>
              <b>{required_field}</b>
              {delimiter}
            </Typography>
          );
        })}
      </span>
    ),
    [ERROR_MESSAGE_KEY]: (
      <Typography variant="body2" display="inline">
        {error_message}
      </Typography>
    ),
  };
}
