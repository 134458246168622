import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import Grid from "@mui/material/Grid";
import { useQueryFetchUnits } from "@redux/actions/avey/units";
import {
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import UnitCreateForms from "./UnitCreateForms";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./UnitsFilters";
import UnitsGridProps from "./UnitsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function UnitsGrid() {
  const { as_staff, isStaff } = useStaffContextProvider();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true, as_staff, ...filters },
    eesFilters: { submitter_organization__slug },
  };
  const dataGridProps = React.useMemo(
    () => UnitsGridProps({ as_staff, isStaff }),
    [as_staff, isStaff]
  );

  // const [isMergingMode, setIsMergingMode] = React.useState(false);

  // const dataGridProps = isMergingMode
  //   ? {
  //       ...UnitsGridProps,
  //       checkboxSelection: true,
  //       onRowSelectionModelChange: setRowSelectionModel,
  //       rowSelectionModel,
  //       keepNonExistentRowsSelected: true,
  //       navigateTo: undefined,
  //       onRowClick: ({ id }) =>
  //         rowSelectionModel.includes(id)
  //           ? setRowSelectionModel(
  //               rowSelectionModel.filter((rowId) => rowId !== id)
  //             )
  //           : setRowSelectionModel([...rowSelectionModel, id]),
  //     }
  //   : UnitsGridProps;

  // const handleIsMergingMode = () => {
  //   setRowSelectionModel([]);
  //   setTimeout(() => setIsMergingMode((prev) => !prev), 0);
  // };

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasAdminPrivileges && (
          <Grid item>
            <UnitCreateForms />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchUnits}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </div>
  );
}
