import React from "react";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteOrganizationClinician,
  invalidateOrganizationCliniciansTSQ,
  updateOrganizationClinicianObjectTSQ,
} from "@redux/actions/organizations/organization-clinicians";

export default function OrganizationClinicianActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateOrganizationCliniciansTSQ();
    updateOrganizationClinicianObjectTSQ({ object: payload });
  };

  return (
    <CustomDeactivateObject
      object={object}
      label="clinician"
      deleteFn={deleteOrganizationClinician}
      handleDeleteFn={handleDeleteFn}
    />
  );
}
