import React from "react";
import ConfigurationsBulkUploadCreateForm from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkUpload/ConfigurationsBulkUpload/ConfigurationsBulkUploadForms/ConfigurationsBulkUploadCreateForm";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";

export default function CustomAddOrUploadWrapper({
  label,
  uploadHistoryHref,
  children,
  useUploadForm = false,
}) {
  const { model_type } = useModelTypeContextProvider();
  const hasModelType = Boolean(model_type);
  const hasUploadHistoryHref = Boolean(uploadHistoryHref);
  return (
    <CustomMoreActions
      useRotateProps={{ useRotate90: false }}
      buttonProps={{
        label,
        variant: "contained",
        icon: "ArrowDownIcon",
        isSquare: false,
        useIconAsLabel: false,
        isStartIcon: false,
      }}
    >
      {children}
      {hasModelType && useUploadForm && (
        <ConfigurationsBulkUploadCreateForm useMenuItem={true} />
      )}
      {hasUploadHistoryHref && (
        <NavLink to={uploadHistoryHref}>
          <MenuItem>{"View upload history"}</MenuItem>
        </NavLink>
      )}
    </CustomMoreActions>
  );
}
