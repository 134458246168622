import React from "react";
import Stack from "@mui/material/Stack";
import ClaimsGrid from "../../Claims/Claims/ClaimsGrid";
import ClaimsNoResults from "../../Claims/Claims/ClaimsNoResults";
import ClaimSubmissionUploadsActiveUploads from "../../ClaimSubmissionUploads/ClaimSubmissionUploads/ClaimSubmissionUploadsActiveUploads";
import ClaimSubmissionClaimsSummary from "./ClaimSubmissionClaimsSummary";

const spacing = 2;

export default function ClaimsSubmissionBody({ object }) {
  const { name, claim_submission_summary, has_claim_submission_upload } =
    object;
  const { num_claims } = claim_submission_summary || { has_claims: false };
  const hasContent = Boolean(num_claims) || has_claim_submission_upload;

  if (hasContent)
    return (
      <Stack spacing={spacing}>
        <div>
          <ClaimSubmissionClaimsSummary
            claim_submission_summary={claim_submission_summary}
          />
          <ClaimSubmissionUploadsActiveUploads spacing={spacing} name={name} />
        </div>
        <ClaimsGrid />
      </Stack>
    );

  return <ClaimsNoResults />;
}
