import React from "react";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import CustomRadioForm from "@components/Custom/CustomRadioForm";
import {
  DRUG_NAME_TYPE_MAPPER,
  DRUG_NAME_TYPE_OPTIONS,
} from "@constants/avey/drug-names";
import Grid from "@mui/material/Grid";

export default function DrugNameFormData({
  formData = {},
  setFormData = () => {},
  isDrugNameTypeFixed = true,
}) {
  const { name, description, drug_name_type } = formData || {};
  const label = isDrugNameTypeFixed
    ? DRUG_NAME_TYPE_MAPPER[drug_name_type]
    : "Name";

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeField = ({ fieldname, newValue }) =>
    setFormData((prev) => ({ ...prev, [fieldname]: newValue }));

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomOutlinedTextField
            required
            autoFocus
            label={label}
            value={name}
            name="name"
            onChange={handleChange}
          />
        </Grid>
        {!isDrugNameTypeFixed && (
          <Grid item xs={12}>
            <CustomRadioForm
              label="Drug name type"
              value={drug_name_type}
              onChange={(newValue) =>
                handleChangeField({ fieldname: "drug_name_type", newValue })
              }
              options={DRUG_NAME_TYPE_OPTIONS}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomOutlinedTextField
            label="Description"
            value={description}
            name="description"
            onChange={handleChange}
            multiline={true}
            minRows={3}
          />
        </Grid>
      </Grid>
    </div>
  );
}
