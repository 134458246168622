const BILLI = 1_000_000_000;
const MILLI = 1_000_000;
const KILO = 1_000;

export const parseFileSize = (num) => {
  let finalNum = num;
  let unit = "bytes";

  if (num >= BILLI) {
    finalNum = (num / BILLI).toFixed(1).replace(/\.0$/, "");
    unit = "GB";
  } else if (num >= MILLI) {
    finalNum = (num / MILLI).toFixed(1).replace(/\.0$/, "");
    unit = "MB";
  } else if (num >= KILO) {
    finalNum = (num / KILO).toFixed(1).replace(/\.0$/, "");
    unit = "KB";
  }

  return `${finalNum} ${unit}`;
};

export const getIntOrFloat = (props) => {
  const isPropsObject = typeof props === "object";
  const value = isPropsObject ? props?.value : props;
  const options = isPropsObject
    ? {
        minimumFractionDigits: props?.minNumSigFig || 0,
        maximumFractionDigits: props?.maxNumSigFig || 2,
      }
    : {};
  const output = value % 1 === 0 ? parseInt(value) : parseFloat(value);
  return isNaN(output) ? value : output.toLocaleString(undefined, options);
};
