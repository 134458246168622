import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomButton from "@components/Custom/CustomButton";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { getTemplateFilename } from "@constants/static/upload";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { fetchTemplate } from "@redux/actions/utils/uploads";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export default function ConfigurationsBulkUploadTemplateForm({
  label = "Download template",
  isTypographyButton = false,
}) {
  const { organization__slug } = useParams();
  const { as_staff } = useStaffContextProvider();
  const { model_type, bundle_type } = useModelTypeContextProvider();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (isProcessing) return;
    setIsProcessing(true);
    fetchTemplate({
      model_type,
      filters: {
        as_staff,
        bundle_type,
        processor_organization__slug: organization__slug,
      },
    })
      .then(({ payload }) => {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(payload);
        link.href = url;
        link.download = getTemplateFilename({
          model_type,
          bundle_type,
        });
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((payload) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const loadingIndicator = isProcessing && <CircularProgress size={14} />;

  if (isTypographyButton)
    return (
      <Stack spacing={1} direction="row" alignItems="flex-end" display="inline">
        <CustomTypographyButton
          value={label}
          onClick={handleSubmit}
          display="inline"
        />
        {loadingIndicator}
      </Stack>
    );

  return (
    <CustomButton
      onClick={handleSubmit}
      label={label}
      startIcon={loadingIndicator}
      variant="outlined"
    />
  );
}
