import { UNIT_TYPE_OPTIONS } from "@constants/avey/units";
import {
  TIME_DELETED_ISNULL_FILTER,
  TIME_UPDATED_FILTER,
  timeDeletedIsNullApplyFilter,
  timeUpdatedApplyFilter,
} from "@constants/static/export";

export const initialFilterState = {
  unit_type__in: [],
  time_updated: [null, null],
  time_deleted__isnull: [],
};

export const filterOptions = {
  unit_type__in: {
    title: "Unit type",
    type: "option",
    options: UNIT_TYPE_OPTIONS,
    useCustomGrid: false,
  },
  time_updated: TIME_UPDATED_FILTER,
  time_deleted__isnull: TIME_DELETED_ISNULL_FILTER,
};

export const formatFilters = (filters = {}) => {
  let formattedFilters = {};
  const {
    unit_type__in = [],
    time_updated = [null, null],
    time_deleted__isnull = [],
  } = filters;

  if (unit_type__in.length > 0)
    formattedFilters["unit_type__in"] = unit_type__in.join(",");

  formattedFilters = timeUpdatedApplyFilter({
    formattedFilters,
    time_updated,
  });

  formattedFilters = timeDeletedIsNullApplyFilter({
    formattedFilters,
    time_deleted__isnull,
  });

  return formattedFilters;
};
