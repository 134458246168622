import { describeFilters as describeClaimFilters } from "@components/Authenticated/ClaimSubmissions/Claims/Claims/ClaimsFilters";
import { describeFilters as describeConsumableFilters } from "@components/Authenticated/Configurations/Consumables/Consumables/ConsumablesFilters";
import { describeFilters as describeMedicationFilters } from "@components/Authenticated/Configurations/Medications/Medications/MedicationsFilters";
import { describeFilters as describeOrganizationBundleFilters } from "@components/Authenticated/Configurations/OrganizationBundles/OrganizationBundles/OrganizationBundlesFilters";
import { describeFilters as describeOrganizationClinicianFilters } from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinicians/OrganizationCliniciansFilters";
import { describeFilters as describeOrganizationPatientFilters } from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatients/OrganizationPatientsFilters";
import { describeFilters as describeOrganizationPolicyFilters } from "@components/Authenticated/Configurations/OrganizationPolicies/OrganizationPolicies/OrganizationPoliciesFilters";
import {
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import {
  ORGANIZATION_ROLE_PROCESSOR,
  ORGANIZATION_ROLE_SUBMITTER,
  ORGANIZATION_ROLE_TPA,
} from "@constants/organizations/organizations";
import {
  exportConsumables,
  invalidateConsumablesTSQ,
  useQueryFetchConsumableExports,
} from "@redux/actions/avey/consumables";
import {
  exportMedications,
  invalidateMedicationsTSQ,
  useQueryFetchMedicationExports,
} from "@redux/actions/avey/medications";
import {
  exportClaims,
  invalidateClaimsTSQ,
  useQueryFetchClaimsExports,
} from "@redux/actions/claims/claims";
import {
  exportOrganizationBundles,
  invalidateOrganizationBundlesTSQ,
  useQueryFetchOrganizationBundleExports,
} from "@redux/actions/organizations/organization-bundles";
import {
  exportOrganizationClinicians,
  invalidateOrganizationCliniciansTSQ,
  useQueryFetchOrganizationClinicianExports,
} from "@redux/actions/organizations/organization-clinicians";
import {
  exportOrganizationPatients,
  invalidateOrganizationPatientsTSQ,
  useQueryFetchOrganizationPatientExports,
} from "@redux/actions/organizations/organization-patients";
import {
  exportOrganizationPolicies,
  invalidateOrganizationPoliciesTSQ,
  useQueryFetchOrganizationPolicyExports,
} from "@redux/actions/organizations/organization-policies";
import {
  MODEL_TYPE_CLAIM,
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "./globals";

// LOGIN
export const LOGIN_HREF = "/login";

// LOGOUT
export const LOGOUT_TITLE = "Logout";
export const LOGOUT_HREF = "/logout";

// AUTH
export const FORGOT_PASSWORD_HREF = "/forgot-password";
export const RESET_PASSWORD_HREF = "/reset-password";
export const ACTIVATE_USER_HREF = "/activate-user";

// APP
export const HOME_TITLE = "Home";
export const HOME_HREF = "/app";

// YOUR ACCOUNT
export const ACCOUNT_TITLE = "Your account";
export const ACCOUNT_HREF = "account";
export const ACCOUNT_FULL_HREF = `${HOME_HREF}/${ACCOUNT_HREF}`;

// YOUR PARTNERS
export const ORGANIZATIONS_TITLE = "Partners";
export const ORGANIZATIONS_HREF = "partners";
export const ORGANIZATIONS_FULL_HREF = `${HOME_HREF}/${ORGANIZATIONS_HREF}`;

// HEALTHCARE CONFIGURATIONS
export const HEALTHCARE_CONFIGURATIONS_TITLE = "Healthcare configurations";
export const HEALTHCARE_CONFIGURATIONS_HREF = "healthcare-configurations";
export const HEALTHCARE_CONFIGURATIONS_FULL_HREF = `${HOME_HREF}/${HEALTHCARE_CONFIGURATIONS_HREF}`;

// INSURANCE CONFIGURATIONS
export const INSURANCE_CONFIGURATIONS_TITLE = "Insurance configurations";
export const INSURANCE_CONFIGURATIONS_HREF = "insurance-configurations";
export const INSURANCE_CONFIGURATIONS_FULL_HREF = `${HOME_HREF}/${INSURANCE_CONFIGURATIONS_HREF}`;

// APP CONFIGURATIONS
export const APP_CONFIGURATIONS_TITLE = "Avey configurations";
export const APP_CONFIGURATIONS_HREF = "avey-configurations";
export const APP_CONFIGURATIONS_FULL_HREF = `${HOME_HREF}/${APP_CONFIGURATIONS_HREF}`;

// YOUR ORGANIZATION
export const YOUR_ORGANIZATION_TITLE = "Organization profile";
export const YOUR_ORGANIZATION_HREF = "organization";
export const YOUR_ORGANIZATION_FULL_HREF = `${HOME_HREF}/${YOUR_ORGANIZATION_HREF}`;

// USER MANAGEMENT
export const USER_MANAGEMENT_TITLE = "User management";
export const USER_MANAGEMENT_HREF = "user-management";
export const USER_MANAGEMENT_FULL_HREF = `${HOME_HREF}/${USER_MANAGEMENT_HREF}`;

// BILLING MANAGEMENT
export const BILLING_MANAGEMENT_TITLE = "Billing management";
export const BILLING_MANAGEMENT_HREF = "billing-management";
export const BILLING_MANAGEMENT_FULL_HREF = `${HOME_HREF}/${BILLING_MANAGEMENT_HREF}`;

// PAYMENT CYCLES
export const PAYMENT_CYCLES_TITLE = "Payment cycles";
export const PAYMENT_CYCLES_HREF = "payment-cycles";
export const PAYMENT_CYCLES_FULL_HREF = `${BILLING_MANAGEMENT_FULL_HREF}/${PAYMENT_CYCLES_HREF}`;
export const getOrganizationPaymentCycleHref = ({
  organization_payment_cycle__id,
}) => {
  const suffix = Boolean(organization_payment_cycle__id)
    ? `/${organization_payment_cycle__id}`
    : "";
  return `${PAYMENT_CYCLES_FULL_HREF}${suffix}`;
};

// PAYMENT METHODS
export const PAYMENT_METHODS_TITLE = "Payment methods";
export const PAYMENT_METHODS_HREF = "payment-methods";
export const PAYMENT_METHODS_FULL_HREF = `${BILLING_MANAGEMENT_FULL_HREF}/${PAYMENT_METHODS_HREF}`;

// BASE
export const APP_CONFIGURATIONS_BASE_HREFS = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: APP_CONFIGURATIONS_TITLE,
    href: APP_CONFIGURATIONS_FULL_HREF,
  },
];
export const HEALTHCARE_CONFIGURATIONS_BASE_HREFS = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: HEALTHCARE_CONFIGURATIONS_TITLE,
    href: HEALTHCARE_CONFIGURATIONS_FULL_HREF,
  },
];
export const INSURANCE_CONFIGURATIONS_BASE_HREFS = [
  {
    label: HOME_TITLE,
    href: HOME_HREF,
  },
  {
    label: INSURANCE_CONFIGURATIONS_TITLE,
    href: INSURANCE_CONFIGURATIONS_FULL_HREF,
  },
];
export const getConfigurationBaseHrefs = ({ as_staff }) => {
  const isStaff = getIsStaff({ as_staff });
  if (isStaff) return APP_CONFIGURATIONS_BASE_HREFS;
  return HEALTHCARE_CONFIGURATIONS_BASE_HREFS;
};
const getConfigurationHrefPrefix = ({ as_staff }) =>
  getIsStaff({ as_staff })
    ? APP_CONFIGURATIONS_FULL_HREF
    : HEALTHCARE_CONFIGURATIONS_FULL_HREF;

export const getIsStaff = ({ as_staff }) => as_staff === "1";

export const getOrganizationHref = ({ organization__slug }) =>
  `${ORGANIZATIONS_FULL_HREF}/${organization__slug}`;
export const getOrganizationSetupHref = ({ organization__slug }) =>
  `${INSURANCE_CONFIGURATIONS_FULL_HREF}/${organization__slug}`;
export const getOrganizationSetupDefaultHref = ({ organization__slug }) =>
  getOrganizationBundlesHref({
    bundle_type: ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
    organization__slug,
  });

// CLAIM
export const CLAIM_SUBMISSIONS_HREF = "claim-submissions";
export const CLAIM_SUBMISSION_UPLOADS_HREF = "uploads";
export const CLAIM_EXPORTS_HREF = "exports";
export const CLAIMS_HREF = "claims";
export const NEW_CLAIM_HREF = "new-claim";
export const getNewClaimHref = ({
  organization__slug,
  claim_submission__id,
}) => {
  const prefix = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id,
  });
  return `${prefix}/${NEW_CLAIM_HREF}`;
};
export const getClaimHref = ({
  organization__slug,
  claim_submission__id,
  claim__id,
  urlSearch,
}) => {
  const prefix = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id,
  });
  const suffix = Boolean(urlSearch) ? `/?${urlSearch}` : "";
  return `${prefix}/${CLAIMS_HREF}/${claim__id}${suffix}`;
};
export const getClaimSubmissionHref = ({
  organization__slug,
  claim_submission__id,
}) => {
  const prefix = getOrganizationHref({ organization__slug });
  const suffix = Boolean(claim_submission__id)
    ? `/${claim_submission__id}`
    : "";
  return `${prefix}/${CLAIM_SUBMISSIONS_HREF}${suffix}`;
};
export const getClaimSubmissionHrefGivenOrganizationRole = ({
  organizationRole,
  claim_submission,
}) => {
  const {
    processor_organization,
    submitter_organization,
    id: claim_submission__id,
  } = claim_submission;

  switch (organizationRole) {
    case ORGANIZATION_ROLE_SUBMITTER:
      return getClaimSubmissionHref({
        organization__slug: processor_organization.slug,
        claim_submission__id,
      });

    case ORGANIZATION_ROLE_PROCESSOR:
    case ORGANIZATION_ROLE_TPA:
      return getClaimSubmissionHref({
        organization__slug: submitter_organization.slug,
        claim_submission__id,
      });

    default:
      return "";
  }
};
export const getClaimSubmissionUploadHref = ({
  organization__slug,
  claim_submission__id,
  claim_submission_upload__id,
}) => {
  const prefix = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id,
  });
  const suffix = Boolean(claim_submission_upload__id)
    ? `/${claim_submission_upload__id}`
    : "";
  return `${prefix}/${CLAIM_SUBMISSION_UPLOADS_HREF}${suffix}`;
};
export const getClaimExportHref = ({
  organization__slug,
  claim_submission__id,
  claim_export__id,
}) => {
  const prefix = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id,
  });
  const suffix = Boolean(claim_export__id) ? `/${claim_export__id}` : "";
  return `${prefix}/${CLAIM_EXPORTS_HREF}${suffix}`;
};

// AVEY COWRITER
export const AVEY_COWRITER_TITLE = "Avey Cowriter";
export const AVEY_COWRITER_HREF = "avey-cowriter";
export const getClaimDemoHref = ({
  organization__slug,
  claim_submission__id,
}) => {
  const prefix = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id,
  });
  return `${prefix}/${AVEY_COWRITER_HREF}`;
};

// CPTS
export const CPTS_TITLE = "CPTs";
export const CPTS_HREF = "cpts";
export const getCptsHref = ({ cpt__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(cpt__id)
    ? `/${CPTS_HREF}/${cpt__id}`
    : `?tab=${CPTS_HREF}`;
  return `${prefix}${suffix}`;
};

// ICDS
export const ICDS_TITLE = "ICDs";
export const ICDS_HREF = "icds";
export const getIcdsHref = ({ icd__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(icd__id)
    ? `/${ICDS_HREF}/${icd__id}`
    : `?tab=${ICDS_HREF}`;
  return `${prefix}${suffix}`;
};

// UNITS
export const UNITS_TITLE = "Units";
export const UNITS_HREF = "units";
export const getUnitsHref = ({ unit__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(unit__id)
    ? `/${UNITS_HREF}/${unit__id}`
    : `?tab=${UNITS_HREF}`;
  return `${prefix}${suffix}`;
};

// DRUG NAMES
export const DRUG_NAMES_TITLE = "Drugs names";
export const DRUG_NAMES_HREF = "drug-names";
export const getDrugNamesHref = ({ drug_name__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(drug_name__id)
    ? `/${DRUG_NAMES_HREF}/${drug_name__id}`
    : `?tab=${DRUG_NAMES_HREF}`;
  return `${prefix}${suffix}`;
};

// SPECIALITIES
export const SPECIALITIES_TITLE = "Specialities";
export const SPECIALITIES_HREF = "specialities";
export const getSpecialitiesHref = ({ speciality__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(speciality__id)
    ? `/${SPECIALITIES_HREF}/${speciality__id}`
    : `?tab=${SPECIALITIES_HREF}`;
  return `${prefix}${suffix}`;
};

// IDENTITY TYPES
export const IDENTITY_TYPES_TITLE = "Identity types";
export const IDENTITY_TYPES_HREF = "identity-types";
export const getIdentityTypesHref = ({ identity_type__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(identity_type__id)
    ? `/${IDENTITY_TYPES_HREF}/${identity_type__id}`
    : `?tab=${IDENTITY_TYPES_HREF}`;
  return `${prefix}${suffix}`;
};

// MEDICATIONS
export const MEDICATIONS_TITLE = "NDCs";
export const MEDICATIONS_HREF = "medications";
export const MEDICATION_UPLOADS_TITLE = "Medication uploads";
export const MEDICATION_UPLOADS_HREF = "medication-uploads";
export const MEDICATION_EXPORTS_TITLE = "Medication exports";
export const MEDICATION_EXPORTS_HREF = "medication-exports";
export const getMedicationsHref = ({ medication__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(medication__id)
    ? `/${MEDICATIONS_HREF}/${medication__id}`
    : `?tab=${MEDICATIONS_HREF}`;
  return `${prefix}${suffix}`;
};
export const getMedicationUploadsHref = ({ upload__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(upload__id) ? `/${upload__id}` : "";
  return `${prefix}/${MEDICATION_UPLOADS_HREF}${suffix}`;
};
export const getMedicationExportsHref = ({ as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  return `${prefix}/${MEDICATION_EXPORTS_HREF}`;
};

// CONSUMABLES
export const CONSUMABLES_TITLE = "Consumables";
export const CONSUMABLES_HREF = "consumables";
export const CONSUMABLE_UPLOADS_TITLE = "Consumable uploads";
export const CONSUMABLE_UPLOADS_HREF = "consumable-uploads";
export const CONSUMABLE_EXPORTS_TITLE = "Consumable exports";
export const CONSUMABLE_EXPORTS_HREF = "consumable-exports";
export const getConsumablesHref = ({ consumable__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(consumable__id)
    ? `/${CONSUMABLES_HREF}/${consumable__id}`
    : `?tab=${CONSUMABLES_HREF}`;
  return `${prefix}${suffix}`;
};
export const getConsumableUploadsHref = ({ upload__id, as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  const suffix = Boolean(upload__id) ? `/${upload__id}` : "";
  return `${prefix}/${CONSUMABLE_UPLOADS_HREF}${suffix}`;
};
export const getConsumableExportsHref = ({ as_staff }) => {
  const prefix = getConfigurationHrefPrefix({ as_staff });
  return `${prefix}/${CONSUMABLE_EXPORTS_HREF}`;
};

// ORGANIZATION BUNDLES - PROCEDURES & CONSUMABLES
export const ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE =
  "Procedures & Consumables";
export const ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF =
  "procedures-and-consumables";
export const ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_UPLOADS_TITLE =
  "Procedures & Consumables uploads";
export const ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_UPLOADS_HREF =
  "procedures-and-consumables-uploads";
export const ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_EXPORTS_TITLE =
  "Procedures & Consumables exports";
export const ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_EXPORTS_HREF =
  "procedures-and-consumables-exports";

// ORGANIZATION BUNDLES - MEDICATIONS
export const ORGANIZATION_BUNDLE_MEDICATIONS_TITLE = "Medications";
export const ORGANIZATION_BUNDLE_MEDICATIONS_HREF = "medications";
const ORGANIZATION_BUNDLE_MEDICATIONS_UPLOADS_TITLE = "Medications uploads";
export const ORGANIZATION_BUNDLE_MEDICATIONS_UPLOADS_HREF =
  "medications-uploads";
export const ORGANIZATION_BUNDLE_MEDICATION_EXPORTS_TITLE =
  "Medication exports";
export const ORGANIZATION_BUNDLE_MEDICATIONS_EXPORTS_HREF =
  "medications-exports";

// ORGANIZATION BUNDLES
export const getOrganizationBundlesHref = ({
  bundle_type,
  organization__slug,
  organization_bundle__id,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  let suffix;

  switch (bundle_type) {
    case ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES:
      suffix = Boolean(organization_bundle__id)
        ? `/${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF}/${organization_bundle__id}`
        : `?tab=${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF}`;
      break;

    case ORGANIZATION_BUNDLE_TYPE_MEDICATIONS:
      suffix = Boolean(organization_bundle__id)
        ? `/${ORGANIZATION_BUNDLE_MEDICATIONS_HREF}/${organization_bundle__id}`
        : `?tab=${ORGANIZATION_BUNDLE_MEDICATIONS_HREF}`;
      break;

    default:
      break;
  }
  return `${prefix}${suffix}`;
};
export const getOrganizationBundlesUploadsHref = ({
  bundle_type,
  organization__slug,
  upload__id,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  const suffix = Boolean(upload__id) ? `/${upload__id}` : "";

  switch (bundle_type) {
    case ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES:
      return `${prefix}/${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_UPLOADS_HREF}${suffix}`;

    case ORGANIZATION_BUNDLE_TYPE_MEDICATIONS:
      return `${prefix}/${ORGANIZATION_BUNDLE_MEDICATIONS_UPLOADS_HREF}${suffix}`;

    default:
      return prefix;
  }
};
export const getOrganizationBundlesExportsHref = ({
  bundle_type,
  organization__slug,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });

  switch (bundle_type) {
    case ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES:
      return `${prefix}/${ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_EXPORTS_HREF}`;

    case ORGANIZATION_BUNDLE_TYPE_MEDICATIONS:
      return `${prefix}/${ORGANIZATION_BUNDLE_MEDICATIONS_EXPORTS_HREF}`;

    default:
      return prefix;
  }
};
export const ORGANIZATION_BUNDLE_TITLE_MAPPER = {
  [ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES]:
    ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
  [ORGANIZATION_BUNDLE_TYPE_MEDICATIONS]: ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
};
const ORGANIZATION_BUNDLE_UPLOAD_TITLE_MAPPER = {
  [ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES]:
    ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_UPLOADS_TITLE,
  [ORGANIZATION_BUNDLE_TYPE_MEDICATIONS]:
    ORGANIZATION_BUNDLE_MEDICATIONS_UPLOADS_TITLE,
};
const ORGANIZATION_BUNDLE_EXPORT_TITLE_MAPPER = {
  [ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES]:
    ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_EXPORTS_TITLE,
  [ORGANIZATION_BUNDLE_TYPE_MEDICATIONS]:
    ORGANIZATION_BUNDLE_MEDICATION_EXPORTS_TITLE,
};

// ORGANIZATION POLICIES
export const ORGANIZATION_POLICIES_TITLE = "Policies";
export const ORGANIZATION_POLICIES_HREF = "policies";
export const ORGANIZATION_POLICY_UPLOADS_TITLE = "Policy uploads";
export const ORGANIZATION_POLICY_UPLOADS_HREF = "policy-uploads";
export const ORGANIZATION_POLICY_EXPORTS_TITLE = "Policy exports";
export const ORGANIZATION_POLICY_EXPORTS_HREF = "policy-exports";
export const getOrganizationPoliciesHref = ({
  organization__slug,
  organization_policy__id,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  const suffix = Boolean(organization_policy__id)
    ? `/${ORGANIZATION_POLICIES_HREF}/${organization_policy__id}`
    : `?tab=${ORGANIZATION_POLICIES_HREF}`;
  return `${prefix}${suffix}`;
};
export const getOrganizationPolicyUploadsHref = ({
  organization__slug,
  upload__id,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  const suffix = Boolean(upload__id) ? `/${upload__id}` : "";
  return `${prefix}/${ORGANIZATION_POLICY_UPLOADS_HREF}${suffix}`;
};
export const getOrganizationPolicyExportsHref = ({ organization__slug }) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  return `${prefix}/${ORGANIZATION_POLICY_EXPORTS_HREF}`;
};

// ORGANIZATION PATIENTS
export const ORGANIZATION_PATIENTS_TITLE = "Patients";
export const ORGANIZATION_PATIENTS_HREF = "patients";
export const ORGANIZATION_PATIENT_UPLOADS_TITLE = "Patient uploads";
export const ORGANIZATION_PATIENT_UPLOADS_HREF = "patient-uploads";
export const ORGANIZATION_PATIENT_EXPORTS_TITLE = "Patient exports";
export const ORGANIZATION_PATIENT_EXPORTS_HREF = "patient-exports";
export const getOrganizationPatientsHref = ({
  organization__slug,
  organization_patient__id,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  const suffix = Boolean(organization_patient__id)
    ? `/${ORGANIZATION_PATIENTS_HREF}/${organization_patient__id}`
    : `?tab=${ORGANIZATION_PATIENTS_HREF}`;
  return `${prefix}${suffix}`;
};
export const getOrganizationPatientUploadsHref = ({
  organization__slug,
  upload__id,
}) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  const suffix = Boolean(upload__id) ? `/${upload__id}` : "";
  return `${prefix}/${ORGANIZATION_PATIENT_UPLOADS_HREF}${suffix}`;
};
export const getOrganizationPatientExportsHref = ({ organization__slug }) => {
  const prefix = getOrganizationSetupHref({ organization__slug });
  return `${prefix}/${ORGANIZATION_PATIENT_EXPORTS_HREF}`;
};

// ORGANIZATION CLINICIANS
export const ORGANIZATION_CLINICIANS_TITLE = "Clinicians";
export const ORGANIZATION_CLINICIANS_HREF = "clinicians";
export const ORGANIZATION_CLINICIAN_UPLOADS_TITLE = "Clinician uploads";
export const ORGANIZATION_CLINICIAN_UPLOADS_HREF = "clinician-uploads";
export const ORGANIZATION_CLINICIAN_EXPORTS_TITLE = "Clinician exports";
export const ORGANIZATION_CLINICIAN_EXPORTS_HREF = "clinician-exports";
export const getOrganizationCliniciansHref = ({
  organization_clinician__id,
}) => {
  const suffix = Boolean(organization_clinician__id)
    ? `/${ORGANIZATION_CLINICIANS_HREF}/${organization_clinician__id}`
    : `?tab=${ORGANIZATION_CLINICIANS_HREF}`;
  return `${HEALTHCARE_CONFIGURATIONS_FULL_HREF}${suffix}`;
};
export const getOrganizationClinicianUploadsHref = ({ upload__id }) => {
  const suffix = Boolean(upload__id) ? `/${upload__id}` : "";
  return `${HEALTHCARE_CONFIGURATIONS_FULL_HREF}/${ORGANIZATION_CLINICIAN_UPLOADS_HREF}${suffix}`;
};
export const ORGANIZATION_CLINICIAN_EXPORTS_FULL_HREF = `${HEALTHCARE_CONFIGURATIONS_FULL_HREF}/${ORGANIZATION_CLINICIAN_EXPORTS_HREF}`;

// EXPORTS
export const getExportsHref = ({
  model_type,
  bundle_type,
  organization__slug,
  as_staff,
}) => {
  switch (model_type) {
    case MODEL_TYPE_CONSUMABLE:
      return getConsumableExportsHref({ as_staff });

    case MODEL_TYPE_MEDICATION:
      return getMedicationExportsHref({ as_staff });

    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      return ORGANIZATION_CLINICIAN_EXPORTS_FULL_HREF;

    case MODEL_TYPE_ORGANIZATION_PATIENT:
      return getOrganizationPatientExportsHref({ organization__slug });

    case MODEL_TYPE_ORGANIZATION_POLICY:
      return getOrganizationPolicyExportsHref({ organization__slug });

    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      return getOrganizationBundlesExportsHref({
        organization__slug,
        bundle_type,
      });

    default:
      return "";
  }
};
export const getConfigurationsBulkExportsHeader = ({
  model_type,
  bundle_type,
  organization = {},
  as_staff,
}) => {
  let title;
  let hrefs = [];
  const { name = "-", slug: organization__slug } = organization || {};

  switch (model_type) {
    case MODEL_TYPE_CONSUMABLE:
      title = CONSUMABLE_EXPORTS_TITLE;
      hrefs = [
        ...getConfigurationBaseHrefs({ as_staff }),
        {
          label: CONSUMABLES_TITLE,
          href: getConsumablesHref({ as_staff }),
        },
        {
          label: title,
          href: getConsumableExportsHref({ as_staff }),
        },
      ];
      break;

    case MODEL_TYPE_MEDICATION:
      title = MEDICATION_EXPORTS_TITLE;
      hrefs = [
        ...getConfigurationBaseHrefs({ as_staff }),
        {
          label: MEDICATION_EXPORTS_TITLE,
          href: getMedicationsHref({ as_staff }),
        },
        {
          label: title,
          href: getMedicationExportsHref({ as_staff }),
        },
      ];
      break;

    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      title = ORGANIZATION_CLINICIAN_EXPORTS_TITLE;
      hrefs = [
        ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
        {
          label: ORGANIZATION_CLINICIANS_TITLE,
          href: getOrganizationCliniciansHref({}),
        },
        {
          label: title,
          href: ORGANIZATION_CLINICIAN_EXPORTS_FULL_HREF,
        },
      ];
      break;

    case MODEL_TYPE_ORGANIZATION_PATIENT:
      title = ORGANIZATION_PATIENT_EXPORTS_TITLE;
      hrefs = [
        ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
        {
          label: name,
          href: getOrganizationSetupDefaultHref({ organization__slug }),
        },
        {
          label: ORGANIZATION_PATIENTS_TITLE,
          href: getOrganizationPatientsHref({ organization__slug }),
        },
        {
          label: title,
          href: getOrganizationPatientExportsHref({ organization__slug }),
        },
      ];
      break;

    case MODEL_TYPE_ORGANIZATION_POLICY:
      title = ORGANIZATION_POLICY_EXPORTS_TITLE;
      hrefs = [
        ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
        {
          label: name,
          href: getOrganizationSetupDefaultHref({ organization__slug }),
        },
        {
          label: ORGANIZATION_POLICIES_TITLE,
          href: getOrganizationPoliciesHref({ organization__slug }),
        },
        {
          label: title,
          href: getOrganizationPolicyExportsHref({ organization__slug }),
        },
      ];
      break;

    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      title = ORGANIZATION_BUNDLE_EXPORT_TITLE_MAPPER[bundle_type];
      hrefs = [
        ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
        {
          label: name,
          href: getOrganizationSetupDefaultHref({ organization__slug }),
        },
        {
          label: ORGANIZATION_BUNDLE_TITLE_MAPPER[bundle_type],
          href: getOrganizationBundlesHref({ bundle_type, organization__slug }),
        },
        {
          label: title,
          href: getOrganizationBundlesExportsHref({
            bundle_type,
            organization__slug,
          }),
        },
      ];
      break;

    default:
      break;
  }

  return { title, hrefs };
};
export const DESCRIBE_FILTERS_EXPORT_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: describeConsumableFilters,
  [MODEL_TYPE_MEDICATION]: describeMedicationFilters,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: describeOrganizationBundleFilters,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: describeOrganizationClinicianFilters,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: describeOrganizationPatientFilters,
  [MODEL_TYPE_ORGANIZATION_POLICY]: describeOrganizationPolicyFilters,
  [MODEL_TYPE_CLAIM]: describeClaimFilters,
};
export const FN_EXPORT_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: exportConsumables,
  [MODEL_TYPE_MEDICATION]: exportMedications,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: exportOrganizationBundles,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: exportOrganizationClinicians,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: exportOrganizationPatients,
  [MODEL_TYPE_ORGANIZATION_POLICY]: exportOrganizationPolicies,
  [MODEL_TYPE_CLAIM]: exportClaims,
};
export const USE_QUERY_FN_EXPORT_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: useQueryFetchConsumableExports,
  [MODEL_TYPE_MEDICATION]: useQueryFetchMedicationExports,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: useQueryFetchOrganizationBundleExports,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]:
    useQueryFetchOrganizationClinicianExports,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: useQueryFetchOrganizationPatientExports,
  [MODEL_TYPE_ORGANIZATION_POLICY]: useQueryFetchOrganizationPolicyExports,
  [MODEL_TYPE_CLAIM]: useQueryFetchClaimsExports,
};
export const USE_QUERY_FN_CLEAR_CACHE_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: invalidateConsumablesTSQ,
  [MODEL_TYPE_MEDICATION]: invalidateMedicationsTSQ,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: invalidateOrganizationBundlesTSQ,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: invalidateOrganizationCliniciansTSQ,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: invalidateOrganizationPatientsTSQ,
  [MODEL_TYPE_ORGANIZATION_POLICY]: invalidateOrganizationPoliciesTSQ,
  [MODEL_TYPE_CLAIM]: invalidateClaimsTSQ,
};
export const LABEL_EXPORT_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: "consumables",
  [MODEL_TYPE_MEDICATION]: "medications",
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: "codes",
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: "clinicians",
  [MODEL_TYPE_ORGANIZATION_PATIENT]: "patients",
  [MODEL_TYPE_ORGANIZATION_POLICY]: "policies",
  [MODEL_TYPE_CLAIM]: "claims",
};

// UPLOADS
export const getUploadsHref = ({
  upload__id,
  model_type,
  bundle_type,
  organization__slug,
  as_staff,
}) => {
  switch (model_type) {
    case MODEL_TYPE_CONSUMABLE:
      return getConsumableUploadsHref({ upload__id, as_staff });

    case MODEL_TYPE_MEDICATION:
      return getMedicationUploadsHref({ upload__id, as_staff });

    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      return getOrganizationClinicianUploadsHref({ upload__id });

    case MODEL_TYPE_ORGANIZATION_PATIENT:
      return getOrganizationPatientUploadsHref({
        upload__id,
        organization__slug,
      });

    case MODEL_TYPE_ORGANIZATION_POLICY:
      return getOrganizationPolicyUploadsHref({
        upload__id,
        organization__slug,
      });

    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      return getOrganizationBundlesUploadsHref({
        upload__id,
        organization__slug,
        bundle_type,
      });

    default:
      return "";
  }
};
export const getConfigurationsBulkUploadsHeader = ({
  model_type,
  bundle_type,
  upload = {},
  organization = {},
  as_staff,
}) => {
  let title;
  let hrefs = [];
  const { id: upload__id, name: upload__name } = upload || {};
  const { name = "-", slug: organization__slug } = organization || {};

  switch (model_type) {
    case MODEL_TYPE_CONSUMABLE:
      title = CONSUMABLE_UPLOADS_TITLE;
      hrefs = [
        ...getConfigurationBaseHrefs({ as_staff }),
        {
          label: CONSUMABLES_TITLE,
          href: getConsumablesHref({ as_staff }),
        },
        {
          label: title,
          href: getConsumableUploadsHref({ as_staff }),
        },
      ];
      if (upload__id)
        hrefs.push({
          label: upload__name,
          href: getConsumableUploadsHref({ upload__id, as_staff }),
        });
      break;

    case MODEL_TYPE_MEDICATION:
      title = MEDICATION_UPLOADS_TITLE;
      hrefs = [
        ...getConfigurationBaseHrefs({ as_staff }),
        {
          label: MEDICATIONS_TITLE,
          href: getMedicationsHref({ as_staff }),
        },
        {
          label: title,
          href: getMedicationUploadsHref({ as_staff }),
        },
      ];
      if (upload__id)
        hrefs.push({
          label: upload__name,
          href: getMedicationUploadsHref({ upload__id, as_staff }),
        });
      break;

    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      title = ORGANIZATION_CLINICIAN_UPLOADS_TITLE;
      hrefs = [
        ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
        {
          label: ORGANIZATION_CLINICIANS_TITLE,
          href: getOrganizationCliniciansHref({}),
        },
        {
          label: title,
          href: getOrganizationClinicianUploadsHref({}),
        },
      ];
      if (upload__id)
        hrefs.push({
          label: upload__name,
          href: getOrganizationClinicianUploadsHref({ upload__id }),
        });
      break;

    case MODEL_TYPE_ORGANIZATION_PATIENT:
      title = ORGANIZATION_PATIENT_UPLOADS_TITLE;
      hrefs = [
        ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
        {
          label: name,
          href: getOrganizationSetupDefaultHref({ organization__slug }),
        },
        {
          label: ORGANIZATION_PATIENTS_TITLE,
          href: getOrganizationPatientsHref({ organization__slug }),
        },
        {
          label: title,
          href: getOrganizationPatientUploadsHref({ organization__slug }),
        },
      ];
      if (upload__id)
        hrefs.push({
          label: upload__name,
          href: getOrganizationPatientUploadsHref({
            upload__id,
            organization__slug,
          }),
        });
      break;

    case MODEL_TYPE_ORGANIZATION_POLICY:
      title = ORGANIZATION_POLICY_UPLOADS_TITLE;
      hrefs = [
        ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
        {
          label: name,
          href: getOrganizationSetupDefaultHref({ organization__slug }),
        },
        {
          label: ORGANIZATION_POLICIES_TITLE,
          href: getOrganizationPoliciesHref({ organization__slug }),
        },
        {
          label: title,
          href: getOrganizationPolicyUploadsHref({ organization__slug }),
        },
      ];
      if (upload__id)
        hrefs.push({
          label: upload__name,
          href: getOrganizationPolicyUploadsHref({
            upload__id,
            organization__slug,
          }),
        });
      break;

    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      title = ORGANIZATION_BUNDLE_UPLOAD_TITLE_MAPPER[bundle_type];
      hrefs = [
        ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
        {
          label: name,
          href: getOrganizationSetupDefaultHref({ organization__slug }),
        },
        {
          label: ORGANIZATION_BUNDLE_TITLE_MAPPER[bundle_type],
          href: getOrganizationBundlesHref({ bundle_type, organization__slug }),
        },
        {
          label: title,
          href: getOrganizationBundlesUploadsHref({
            bundle_type,
            organization__slug,
          }),
        },
      ];
      if (upload__id)
        hrefs.push({
          label: upload__name,
          href: getOrganizationBundlesUploadsHref({
            upload__id,
            bundle_type,
            organization__slug,
          }),
        });
      break;

    default:
      break;
  }

  return { title, hrefs };
};
