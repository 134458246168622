import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import CustomStepper from "@components/Custom/CustomStepper";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import {
  formatClaimFormData,
  formatClaimVariationFormData,
} from "@constants/claims/claims";
import { getClaimHref } from "@constants/static/routing";
import { useURLSearch } from "@helpers/api/getQueryParams";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { submitClaim } from "@redux/actions/claims/claims";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ClaimReviewAndConfirm from "../../ClaimReviewAndConfirm";
import {
  ClaimSubmitterResolveClaimFormContextProvider,
  getClaimSubmitterResolveClaimFormDialogProps,
  RESOLVE_CLAIM_STAGE_COMPONENT_MAPPER,
  useClaimSubmitterResolveClaimFormContextProvider,
} from "./ClaimSubmitterResolveClaimFormContextProvider";

export default function ClaimSubmitterResolveClaimForm({
  object,
  icd_type = ICD_TYPE_10_CM,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CustomButton
        label="Resolve variations"
        onClick={handleIsOpen}
        color="primary"
        icon="EditIcon"
      />
      {isOpen && (
        <ClaimSubmitterResolveClaimFormContextProvider
          object={object}
          icd_type={icd_type}
        >
          <ClaimSubmitterResolveClaimFormContent
            object={object}
            handleIsOpen={handleIsOpen}
          />
        </ClaimSubmitterResolveClaimFormContextProvider>
      )}
    </>
  );
}

const ClaimSubmitterResolveClaimFormTitleComponent = ({
  title = "",
  subtitle = "",
  steps = [],
  activeStep = 0,
  toggleTransition = false,
}) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
        <Fade in={toggleTransition}>
          <Typography variant="body2">{subtitle}</Typography>
        </Fade>
      </Stack>
      <CustomStepper
        steps={steps}
        activeStep={activeStep}
        wrapperProps={{
          className: "greyBgF9 borderRadius-8",
          style: { padding: 12 },
        }}
      />
    </Stack>
  );
};

const ClaimSubmitterResolveClaimFormContent = ({
  object = {},
  handleIsOpen = () => {},
}) => {
  const navigate = useNavigate();
  const urlSearch = useURLSearch();
  const { claim_submission__id, organization__slug } = useParams();
  const [toggleTransition, setToggleTransition] = React.useState(true);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [formData, setFormData] = React.useState(object);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;

  const {
    isProcessing: isProcessingContext,
    stageIndex = 0,
    handlePrev = () => {},
    handleNext = () => {},
    claim_visit_variation = {},
    claim_organization_patient_variation = {},
    claim_organization_clinician_variation = {},
    claim_symptoms_variation = {},
    claim_diagnoses_variation = {},
  } = useClaimSubmitterResolveClaimFormContextProvider();

  const { StageComponent, dialogProps } = React.useMemo(
    () => ({
      StageComponent:
        RESOLVE_CLAIM_STAGE_COMPONENT_MAPPER[stageIndex] || (() => null),
      dialogProps: getClaimSubmitterResolveClaimFormDialogProps({
        stageIndex,
        isProcessing: isProcessingContext,
      }),
    }),
    [stageIndex, isProcessingContext]
  );

  const { titleComponentProps = {}, ...otherDialogProps } = dialogProps || {};

  const handleSubmit = (e, isCheck = true) => {
    e.preventDefault();
    setSummaryState(({ prev }) => ({ ...prev, isOpen: true }));
    setIsProcessing(true);
    const newFormData = formatClaimVariationFormData({
      claim: object,
      claim_visit_variation,
      claim_organization_patient_variation,
      claim_organization_clinician_variation,
      claim_symptoms_variation,
      claim_diagnoses_variation,
    });
    setFormData(newFormData);
    const formattedFormData = formatClaimFormData({
      formData: newFormData,
      isCheck,
      claim_submission__id,
      processor_organization__slug: organization__slug,
    });
    submitClaim(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: "Claim successfully resubmitted",
          });
          navigate(
            getClaimHref({
              organization__slug,
              claim_submission__id,
              claim__id: instance.id,
              urlSearch,
            })
          );
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) =>
        setAlertState({ isOpen: true, errorStr: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    setToggleTransition(false);
    setTimeout(() => setToggleTransition(true), 150);
  }, [stageIndex]);

  if (isOpenSummary || isOpenAlert) {
    return (
      <>
        <CustomSummaryDialog
          summaries={summaries}
          isOpen={isOpenSummary && !isOpenAlert}
          isProcessing={isProcessing}
          setSummaryState={setSummaryState}
          handleSubmit={handleSubmitData}
          ContentComponent={ClaimReviewAndConfirm}
          contentComponentProps={{ claim: formData }}
          summaryDialogProps={{ maxWidth: "lg", isOk: false }}
          TitleComponent={InsuranceOrganizationActionDialogTitle}
        />
        <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      </>
    );
  }

  return (
    <CustomActionDialog
      isOpen={true}
      handleClose={handleIsOpen}
      handleCloseAlt={(e) => handlePrev({ e, handlePrevAlt: handleIsOpen })}
      handleSubmit={(e) => handleNext({ e, handleNextAlt: handleCheckData })}
      TitleComponent={ClaimSubmitterResolveClaimFormTitleComponent}
      titleComponentProps={{ toggleTransition, ...titleComponentProps }}
      {...otherDialogProps}
    >
      <Slide direction="up" in={toggleTransition} mountOnEnter unmountOnExit>
        <div>
          <StageComponent />
        </div>
      </Slide>
    </CustomActionDialog>
  );
};
