import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomMoreActions from "./CustomMoreActions";
import CustomValueWithUnit from "./CustomValueWithUnit";

export default function CustomSummaryCards({ summaries }) {
  return (
    <div>
      <Grid container spacing={2}>
        {summaries.map((props, index) => (
          <Grid item xs={12} sm={6} md="auto" key={index}>
            <CustomSummaryCard {...props} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export const CustomSummaryCard = ({
  title = "",
  titleProps = {},
  headerProps = {},
  action = undefined,
  moreActions = undefined,
  isLoading = false,
  primary = "",
  secondary = "",
  useChildren = false,
  children = undefined,
  wrapperProps = {},
}) => {
  const hasAction = Boolean(action);
  const hasMoreActions = Boolean(moreActions);
  return (
    <Stack
      px={2}
      py={1}
      minWidth={120}
      display="grid"
      alignItems="center"
      className="outlined-only borderRadius-8"
      {...wrapperProps}
    >
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height={30}
      >
        <Stack direction="row" alignItems="center" {...headerProps}>
          <Typography
            variant="caption"
            color="textSecondary"
            title={title}
            {...titleProps}
          >
            {title}
          </Typography>
          {hasAction && action}
        </Stack>
        {hasMoreActions && (
          <CustomMoreActions useIconButton={true}>
            {moreActions}
          </CustomMoreActions>
        )}
      </Stack>
      {useChildren ? (
        children
      ) : (
        <CustomValueWithUnit
          value={isLoading ? "-" : primary}
          valueTypographyProps={{ variant: "h6" }}
          unit={isLoading ? "" : secondary}
        />
      )}
    </Stack>
  );
};
