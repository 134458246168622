import React from "react";
import CustomCard from "@components/Custom/CustomCard";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import {
  PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO,
  PAYMENT_PLAN_TYPE_SUBSCRIPTION,
} from "@constants/payments/payments";
import { TOKEN_FULL_NAME } from "@constants/static/payment";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { getOrganizationPaymentCycleConsumption } from "@helpers/parse/tokens";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import OrganizationPaymentCycleBalanceFormActionPayAsYouGo from "./OrganizationPaymentCycleBalanceFormActionPayAsYouGo";
import OrganizationPaymentCycleBalanceFormActionSubscription from "./OrganizationPaymentCycleBalanceFormActionSubscription";

export default function OrganizationPaymentCycleBalanceForm({ object = {} }) {
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const {
    payment_plan_type,
    num_tokens,
    num_tokens_consumed,
    pay_as_you_go_usage_token_threshold,
    pay_as_you_go_rate_per_token,
    num_claims_processed,
  } = object;

  const {
    amountNumerator,
    amountDenominator,
    balanceNumerator,
    balanceDenominator,
  } = getOrganizationPaymentCycleConsumption({
    num_tokens,
    num_tokens_consumed,
    pay_as_you_go_rate_per_token,
    pay_as_you_go_usage_token_threshold,
    payment_plan_type,
    billing_conversion,
  });

  return (
    <CustomCard
      title="Consumption"
      action={<OrganizationPaymentCycleBalanceFormAction object={object} />}
    >
      <CustomGrid>
        <AnalyticSquare
          title={balanceNumerator}
          subtitle={balanceDenominator}
          label={TOKEN_FULL_NAME}
        />
        {amountNumerator && (
          <AnalyticSquare
            title={amountNumerator}
            subtitle={amountDenominator}
            label="Amount due"
          />
        )}
        <AnalyticSquare
          title={getIntOrFloat(num_claims_processed)}
          label="Claims"
        />
      </CustomGrid>
    </CustomCard>
  );
}

const OrganizationPaymentCycleBalanceFormAction = ({ object }) => {
  const { payment_plan_type, num_tokens } = object;
  switch (payment_plan_type) {
    case PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO:
      if (num_tokens < 0)
        return (
          <OrganizationPaymentCycleBalanceFormActionPayAsYouGo
            object={object}
          />
        );
      return null;

    case PAYMENT_PLAN_TYPE_SUBSCRIPTION:
      return (
        <OrganizationPaymentCycleBalanceFormActionSubscription
          object={object}
        />
      );

    default:
      return null;
  }
};

const AnalyticSquare = ({ title, subtitle, label, titleProps = {} }) => (
  <CustomLabeledText
    helperText={label}
    useChildren={true}
    wrapperProps={{ spacing: 0 }}
  >
    <div className="ellipsis-1">
      <Stack spacing={0.5} alignItems="baseline" direction="row">
        <Typography variant="h6" display="inline" {...titleProps}>
          {title}
        </Typography>
        {subtitle && (
          <Typography display="inline" variant="caption">
            {subtitle}
          </Typography>
        )}
      </Stack>
    </div>
  </CustomLabeledText>
);
