import React from "react";
import Stack from "@mui/material/Stack";
import ClaimVisitData from "../../../../ClaimVisit/ClaimVisitData";
import {
  RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX,
  useClaimSubmitterResolveClaimFormContextProvider,
} from "../ClaimSubmitterResolveClaimFormContextProvider";
import { ClaimSubmitterResolveClaimSelectOptionwrapper } from "../ClaimSubmitterResolveClaimOptionWrappers";

export default function ClaimVisitSelectVariation() {
  const {
    claim_visit_variations = [],
    claim_visit_variation = {},
    updateContext = () => {},
  } = useClaimSubmitterResolveClaimFormContextProvider();
  const { index: variation_index } = claim_visit_variation || {};

  const handleSelect = ({ e, index }) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateContext({
      claim_visit_variation: { index, object: claim_visit_variations?.[index] },
    });
  };

  const handleEdit = ({ e, index }) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateContext({
      claim_visit_variation: { index, object: claim_visit_variations?.[index] },
      stageIndex: RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX,
    });
  };

  return (
    <Stack spacing={2}>
      {claim_visit_variations.map((object, index) => (
        <ClaimSubmitterResolveClaimSelectOptionwrapper
          key={index}
          isSelected={index === variation_index}
          handleSelect={(e) => handleSelect({ e, index })}
          handleEdit={(e) => handleEdit({ e, index })}
        >
          <ClaimVisitData object={object} useCustomSection={false} />
        </ClaimSubmitterResolveClaimSelectOptionwrapper>
      ))}
    </Stack>
  );
}
