import React from "react";
import CustomAvatar from "@components/Custom/CustomAvatar";
import {
  ACCOUNT_FULL_HREF,
  YOUR_ORGANIZATION_FULL_HREF,
} from "@constants/static/routing";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  selectorAuthCurrentUser,
  selectorAuthCurrentUserOrganization,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function NavigationTopAppBarUserAvatar() {
  const isUpLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const user = useSelector(selectorAuthCurrentUser);
  const organization = useSelector(selectorAuthCurrentUserOrganization);
  const { first_name, last_name, image } = user;
  const full_name = `${first_name || ""} ${last_name || ""}`.trim();
  const { name } = organization;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {isUpLg && (
        <Box textAlign="end" display="grid">
          <NavLink to={YOUR_ORGANIZATION_FULL_HREF}>
            <Typography
              className="ellipsis-1 opacityOnHover-50"
              fontWeight="light"
              title={name}
            >
              {name}
            </Typography>
          </NavLink>
          <NavLink to={ACCOUNT_FULL_HREF}>
            <Typography
              variant="body2"
              color="textSecondary"
              className="ellipsis-1 opacityOnHover-50"
              title={full_name}
            >
              {full_name}
            </Typography>
          </NavLink>
        </Box>
      )}
      <NavLink className="whiteBg borderRadius-100" to={ACCOUNT_FULL_HREF}>
        <CustomAvatar variant="circle" src={image} className="sizeLg" />
      </NavLink>
    </Stack>
  );
}
