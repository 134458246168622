import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  UNITS_TITLE,
  getConfigurationBaseHrefs,
  getUnitsHref,
} from "@constants/static/routing";
import {
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UnitActivateForm from "../UnitForms/UnitActivateForm";
import UnitUpdateForm from "../UnitForms/UnitUpdateForm";
import UnitVersionHistory from "./UnitVersionHistory";

export function UnitHeaderTitle({ object, variant = "h3" }) {
  const { version, name, time_deleted } = object;

  return (
    <CustomHeaderTitle
      title={name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function UnitHeader({ object }) {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { unit__id } = useParams();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const { code, name, time_deleted, submitter_organization } = object || {};
  const { slug } = submitter_organization || {};
  const isSubmitterOrganization = slug === submitter_organization__slug;
  const title = code || name;
  const isDeleted = Boolean(time_deleted);
  const canUpdateObject =
    isStaff || (hasAdminPrivileges && isSubmitterOrganization);

  const hrefs = [
    ...getConfigurationBaseHrefs({ as_staff }),
    {
      label: UNITS_TITLE,
      href: getUnitsHref({ as_staff }),
    },
    {
      label: title,
      href: getUnitsHref({ unit__id, as_staff }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={UnitHeaderTitle}
      titleComponentProps={{ object }}
    >
      {canUpdateObject && !isDeleted && <UnitUpdateForm object={object} />}
      <CustomMoreActions>
        <UnitVersionHistory />
        {canUpdateObject && <UnitActivateForm object={object} />}
      </CustomMoreActions>
    </CustomHeader>
  );
}
