import React from "react";
import CustomButtonVariant from "@components/Custom/CustomButtonVariant";
import { getNewClaimHref } from "@constants/static/routing";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import { NavLink, useParams } from "react-router-dom";

export default function ClaimSubmitterCreateClaimFormLink({
  isMenuItem = false,
}) {
  const { organization__slug, claim_submission__id } = useParams();
  const { data } = useFetchClaimSubmission();
  const { isAgreementDisabled, isLoading } = useFetchOrganizationAgreement();
  const href = getNewClaimHref({
    organization__slug,
    claim_submission__id,
  });
  const disabled = isLoading || !Boolean(data) || isAgreementDisabled;
  const children = (
    <CustomButtonVariant
      icon="PlusIcon"
      label="Add a claim"
      isMenuItem={isMenuItem}
      buttonProps={{ icon: "PlusIcon", fullWidth: true }}
      disabled={disabled}
    />
  );

  if (disabled) return children;

  return <NavLink to={href}>{children}</NavLink>;
}
