import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchDrugNameHistory } from "@redux/actions/avey/drug-names";
import { useParams } from "react-router-dom";
import DrugNameData from "./DrugNameData";
import { DrugNameHeaderTitle } from "./DrugNameHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { name } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {name}
    </Typography>
  );
};

export default function DrugNameVersionHistory() {
  const { as_staff } = useStaffContextProvider();
  const { drug_name__id: id } = useParams();
  const useQueryFnProps = { id, filters: { as_staff } };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchDrugNameHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={DrugNameData}
      VersionHistoryObjectTitleComponent={DrugNameHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
