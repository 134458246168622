import React from "react";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import {
  CLAIM_SECTION_DIAGNOSES,
  CLAIM_SECTION_ICD_TITLE_MAPPER,
  CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE,
  CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE,
  CLAIM_SECTION_SYMPTOMS,
  CLAIM_SECTION_VISIT_DETAILS_TITLE,
} from "@constants/claims/claims";
import { ArrowLeftIcon, ArrowRightIcon } from "@rimads/assets";
import { AVEY_BLACK, AVEY_WHITE } from "@styles/theme";
import { v4 as uuidv4 } from "uuid";
import ClaimIcdsSelectVariation from "./ClaimIcdsVariations/ClaimIcdsSelectVariation";
import ClaimIcdsUpdateVariation from "./ClaimIcdsVariations/ClaimIcdsUpdateVariation";
import ClaimOrganizationClinicianSelectVariation from "./ClaimOrganizationClinicianVariations/ClaimOrganizationClinicianSelectVariation";
import ClaimOrganizationPatientSelectVariation from "./ClaimOrganizationPatientVariations/ClaimOrganizationPatientSelectVariation";
import ClaimVisitSelectVariation from "./ClaimVisitVariations/ClaimVisitSelectVariation";
import ClaimVisitUpdateVariation from "./ClaimVisitVariations/ClaimVisitUpdateVariation";

export const RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX = 0;
export const RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX = 1;
export const RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX = 2;
export const RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX = 3;
export const RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX = 4;
export const RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX = 5;
export const RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX = 6;
export const RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX = 7;

const INITIAL_STAGE = RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX;
const STAGE_INDEX_MAPPER = {
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX]:
    RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX]:
    RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX,
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX]:
    RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX,
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX]:
    RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX,
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX]:
    RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX]:
    RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX,
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX]: undefined,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX]: undefined,
};

const getNextStageIndex = ({ context }) => {
  const {
    stageIndex,
    claim_visit_variations,
    claim_organization_patient_variations,
    claim_organization_clinician_variations,
    claim_symptoms_variations,
    claim_diagnoses_variations,
  } = context;

  let nextStageIndex = STAGE_INDEX_MAPPER[stageIndex];

  [
    {
      index: RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX,
      isSkip: claim_visit_variations?.length === 0,
    },
    {
      index: RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX,
      isSkip: claim_organization_patient_variations?.length === 0,
    },
    {
      index: RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX,
      isSkip: claim_organization_clinician_variations?.length === 0,
    },
    {
      index: RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX,
      isSkip: claim_symptoms_variations?.length === 0,
      skipToIndex: RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX,
    },
    {
      index: RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX,
      isSkip: claim_diagnoses_variations?.length === 0,
      skipToIndex: RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX,
    },
  ].forEach(({ index, isSkip, skipToIndex = undefined }) => {
    if (nextStageIndex === index && isSkip)
      nextStageIndex = skipToIndex || STAGE_INDEX_MAPPER[nextStageIndex];
  });

  return nextStageIndex;
};

export const RESOLVE_CLAIM_STAGE_CLAIM_ICDS_VARIATION_MAPPER = {
  [CLAIM_SECTION_SYMPTOMS]: {
    variation_key: "claim_symptoms_variation",
    variations_key: "claim_symptoms_variations",
    update_stage_index:
      RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX,
  },
  [CLAIM_SECTION_DIAGNOSES]: {
    variation_key: "claim_diagnoses_variation",
    variations_key: "claim_diagnoses_variations",
    update_stage_index:
      RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX,
  },
};

export const RESOLVE_CLAIM_STAGE_COMPONENT_MAPPER = {
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX]:
    ClaimVisitSelectVariation,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX]:
    ClaimVisitUpdateVariation,
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX]:
    ClaimOrganizationPatientSelectVariation,
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX]:
    ClaimOrganizationClinicianSelectVariation,
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX]: () => (
    <ClaimIcdsSelectVariation type={CLAIM_SECTION_SYMPTOMS} />
  ),
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX]: () => (
    <ClaimIcdsUpdateVariation type={CLAIM_SECTION_SYMPTOMS} />
  ),
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX]: () => (
    <ClaimIcdsSelectVariation type={CLAIM_SECTION_DIAGNOSES} />
  ),
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX]: () => (
    <ClaimIcdsUpdateVariation type={CLAIM_SECTION_DIAGNOSES} />
  ),
};

const RESOLVE_CLAIM_STAGE_TITLE_MAPPER = {
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX]:
    "Select visit variation",
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX]:
    "Update visit variation",
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX]:
    "Select patient variation",
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX]:
    "Select clinician variation",
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX]:
    "Select patient presentation variation",
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX]:
    "Update patient presentation variation",
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX]:
    "Select clinician diagnosis variation",
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX]:
    "Update clinician diagnosis variation",
};

const RESOLVE_CLAIM_STAGE_STEPS = [
  CLAIM_SECTION_VISIT_DETAILS_TITLE,
  CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE,
  CLAIM_SECTION_ORGANIZATION_CLINICIAN_TITLE,
  CLAIM_SECTION_ICD_TITLE_MAPPER[CLAIM_SECTION_SYMPTOMS],
  CLAIM_SECTION_ICD_TITLE_MAPPER[CLAIM_SECTION_DIAGNOSES],
];

const RESOLVE_CLAIM_STAGE_ACTIVE_STEP_MAPPER = {
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX]: 0,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX]: 0.5,
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX]: 1,
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX]: 2,
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX]: 3,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX]: 3.5,
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX]: 4,
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX]: 4.5,
};

const PREV_TEXT = "Prev";
const NEXT_TEXT = "Next";
const SAVE_NEXT_TEXT = "Save & Proceed";
const REVIEW_AND_CONFIRM_TEXT = "Review & Confirm";

const RESOLVE_CLAIM_STAGE_DIALOG_PROPS_MAPPER = {
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX]: {
    noText: "Cancel",
    noButtonProps: {
      color: "info",
    },
  },
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX]: {
    yesText: SAVE_NEXT_TEXT,
  },
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX]: {},
  [RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX]: {},
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX]: {},
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX]: {
    yesText: SAVE_NEXT_TEXT,
  },
  [RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX]: {
    yesText: REVIEW_AND_CONFIRM_TEXT,
  },
  [RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX]: {
    yesText: REVIEW_AND_CONFIRM_TEXT,
  },
};

const formatClaimIcdsVariations = (claim_icds_variations) =>
  claim_icds_variations.map((claim_icds_variation) => ({
    ...claim_icds_variation,
    claim_icds: (claim_icds_variation?.claim_icds || []).map((claim_icd) => ({
      ...claim_icd,
      id: uuidv4(),
    })),
  }));

const getInitialContext = ({
  isProcessing = false,
  stageIndex = -1,
  icd_type = ICD_TYPE_10_CM,
  object = {},
}) => {
  const { to_review } = object;
  const { claim_variations } = to_review || {};
  const {
    claim_visit_variations = [],
    claim_organization_patient_variations = [],
    claim_organization_clinician_variations = [],
    claim_symptoms_variations = [],
    claim_diagnoses_variations = [],
  } = claim_variations || {};
  return {
    isProcessing,
    stageIndex,
    icd_type,
    claim_visit_variations,
    claim_organization_patient_variations,
    claim_organization_clinician_variations,
    claim_diagnoses_variations: formatClaimIcdsVariations(
      claim_diagnoses_variations
    ),
    claim_symptoms_variations: formatClaimIcdsVariations(
      claim_symptoms_variations
    ),
    claim_visit_variation: {
      index: 0,
      object: claim_visit_variations?.[0],
    },
    claim_organization_patient_variation: {
      index: 0,
      object: claim_organization_patient_variations?.[0],
    },
    claim_organization_clinician_variation: {
      index: 0,
      object: claim_organization_clinician_variations?.[0],
    },
    claim_symptoms_variation: {
      index: 0,
      object: claim_symptoms_variations?.[0],
    },
    claim_diagnoses_variation: {
      index: 0,
      object: claim_diagnoses_variations?.[0],
    },
    updateContext: () => {},
    handlePrev: () => {},
    handleNext: () => {},
  };
};

const selectVariationGivenKey = ({ prev, variation_key, variations_key }) => {
  const { index: prevIndex } = prev[variation_key] || {};
  const object = prev[variations_key]?.[prevIndex];
  return {
    [variation_key]: {
      index: prevIndex,
      object,
    },
  };
};

const updateVariationGivenKey = ({ prev, variation_key, variations_key }) => {
  const { index: prevIndex, object } = prev[variation_key] || {};
  return {
    [variations_key]: prev[variations_key]?.map((item, i) =>
      i === prevIndex ? object : item
    ),
  };
};

const getPayloadGivenCurrentStageIndex = ({ prev, currentStageIndex }) => {
  let payload = {};

  switch (currentStageIndex) {
    case RESOLVE_CLAIM_STAGE_SELECT_CLAIM_VISIT_VARIATION_INDEX:
      payload = selectVariationGivenKey({
        prev,
        variation_key: "claim_visit_variation",
        variations_key: "claim_visit_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_VISIT_VARIATION_INDEX:
      payload = updateVariationGivenKey({
        prev,
        variation_key: "claim_visit_variation",
        variations_key: "claim_visit_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_PATIENT_VARIATION_INDEX:
      payload = selectVariationGivenKey({
        prev,
        variation_key: "claim_organization_patient_variation",
        variations_key: "claim_organization_patient_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_SELECT_ORGANIZATION_CLINICIAN_VARIATION_INDEX:
      payload = selectVariationGivenKey({
        prev,
        variation_key: "claim_organization_clinician_variation",
        variations_key: "claim_organization_clinician_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_SELECT_CLAIM_SYMPTOMS_VARIATION_INDEX:
      payload = selectVariationGivenKey({
        prev,
        variation_key: "claim_symptoms_variation",
        variations_key: "claim_symptoms_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_SYMPTOMS_VARIATION_INDEX:
      payload = updateVariationGivenKey({
        prev,
        variation_key: "claim_symptoms_variation",
        variations_key: "claim_symptoms_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_SELECT_CLAIM_DIAGNOSES_VARIATION_INDEX:
      payload = selectVariationGivenKey({
        prev,
        variation_key: "claim_diagnoses_variation",
        variations_key: "claim_diagnoses_variations",
      });
      break;

    case RESOLVE_CLAIM_STAGE_UPDATE_CLAIM_DIAGNOSES_VARIATION_INDEX:
      payload = updateVariationGivenKey({
        prev,
        variation_key: "claim_diagnoses_variation",
        variations_key: "claim_diagnoses_variations",
      });
      break;

    default:
      break;
  }

  return { ...prev, ...payload };
};

const ClaimSubmitterResolveClaimFormContext = React.createContext();

export const useClaimSubmitterResolveClaimFormContextProvider = () =>
  React.useContext(ClaimSubmitterResolveClaimFormContext);

export const getClaimSubmitterResolveClaimFormDialogProps = ({
  stageIndex,
  isProcessing = false,
}) => {
  return {
    maxWidth: "lg",
    noText: PREV_TEXT,
    noButtonProps: {
      color: "primary",
      variant: "outlined",
      startIcon: <ArrowLeftIcon primary_fill={AVEY_BLACK} />,
    },
    yesText: NEXT_TEXT,
    yesButtonProps: {
      endIcon: <ArrowRightIcon primary_fill={AVEY_WHITE} />,
      disabled: isProcessing,
    },
    useDivider: false,
    useBottomDivider: true,
    bodyProps: { minHeight: "50vh" },
    titleComponentProps: {
      title: "Resolve claim conflicts",
      subtitle: RESOLVE_CLAIM_STAGE_TITLE_MAPPER[stageIndex],
      steps: RESOLVE_CLAIM_STAGE_STEPS,
      activeStep: RESOLVE_CLAIM_STAGE_ACTIVE_STEP_MAPPER[stageIndex],
    },
    ...RESOLVE_CLAIM_STAGE_DIALOG_PROPS_MAPPER[stageIndex],
  };
};

export const ClaimSubmitterResolveClaimFormContextProvider = ({
  children,
  icd_type = ICD_TYPE_10_CM,
  object,
}) => {
  const [history, setHistory] = React.useState([INITIAL_STAGE]);
  const [context, setContext] = React.useState(
    getInitialContext({
      stageIndex: INITIAL_STAGE,
      icd_type,
      object,
    })
  );

  const { stageIndex } = context;

  const updateContext = React.useCallback(
    (payload) => {
      const {
        stageIndex: payloadStageIndex,
        currentStageIndex,
        ...otherPayload
      } = payload || {};
      let newStageIndex;

      if (payloadStageIndex === -1) {
        const newHistory = history.slice(0, history.length - 1);
        newStageIndex = newHistory[newHistory.length - 1];
        setHistory(newHistory);
      } else if (payloadStageIndex !== undefined) {
        newStageIndex = payloadStageIndex;
        setHistory((prev) => [...prev, payloadStageIndex]);
      } else {
        newStageIndex = stageIndex;
      }

      setContext((prev) => ({
        ...getPayloadGivenCurrentStageIndex({ prev, currentStageIndex }),
        ...otherPayload,
        stageIndex: newStageIndex,
      }));
    },
    [history, stageIndex]
  );

  const handlePrev = ({ e, handlePrevAlt = () => {} }) => {
    e?.preventDefault();
    if (history.length <= 1) return handlePrevAlt(e);
    updateContext({ stageIndex: -1 });
  };

  const handleNext = ({ e, handleNextAlt = () => {} }) => {
    e?.preventDefault();
    const nextStageIndex = getNextStageIndex({ context });
    updateContext({
      currentStageIndex: stageIndex,
      stageIndex: nextStageIndex,
    });
    !nextStageIndex && handleNextAlt(e);
  };

  return (
    <ClaimSubmitterResolveClaimFormContext.Provider
      value={{ ...context, updateContext, handlePrev, handleNext }}
    >
      {children}
    </ClaimSubmitterResolveClaimFormContext.Provider>
  );
};
