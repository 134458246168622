import React from "react";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import OrganizationPatientData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData";
import {
  CLAIM_SECTION_DIAGNOSES,
  CLAIM_SECTION_SYMPTOMS,
  ICD_DIAGNOSES_TEXT_FIELDNAME,
  ICD_SYMPTOMS_TEXT_FIELDNAME,
} from "@constants/claims/claims";
import Stack from "@mui/material/Stack";
import ClaimAttachmentsReviewAndConfirm from "../ClaimAttachments/ClaimAttachmentsReviewAndConfirm";
import ClaimIcdsData from "../ClaimIcds/ClaimIcdsData";
import ClaimMedicationsReviewAndConfirm from "../ClaimMedications/ClaimMedicationsReviewAndConfirm";
import ClaimProceduresReviewAndConfirm from "../ClaimProcedures/ClaimProceduresReviewAndConfirm";
import ClaimVisitData from "../ClaimVisit/ClaimVisitData";

export default function ClaimReviewAndConfirm({ claim = {} }) {
  const {
    organization_patient = {},
    organization_clinician = {},
    claim_diagnoses = [],
    claim_symptoms = [],
    claim_procedures = [],
    claim_medications = [],
    claim_attachments = [],
    ...otherProps
  } = claim;

  const symptoms_text = otherProps[ICD_SYMPTOMS_TEXT_FIELDNAME];
  const diagnoses_test = otherProps[ICD_DIAGNOSES_TEXT_FIELDNAME];

  return (
    <Stack spacing={3}>
      <ClaimVisitData
        object={otherProps}
        useCustomSection={true}
        isMinified={true}
        useErrorFieldnames={false}
      />
      <OrganizationPatientData
        object={organization_patient}
        useCustomSection={true}
        isMinified={true}
      />
      <OrganizationClinicianData
        object={organization_clinician}
        useCustomSection={true}
        isMinified={true}
      />
      <ClaimIcdsData
        object={{
          claim_icds: claim_symptoms,
          claim_icds_text: symptoms_text,
        }}
        type={CLAIM_SECTION_SYMPTOMS}
        useCustomSection={true}
        isMinified={true}
      />
      <ClaimIcdsData
        object={{
          claim_icds: claim_diagnoses,
          claim_icds_text: diagnoses_test,
        }}
        type={CLAIM_SECTION_DIAGNOSES}
        useCustomSection={true}
        isMinified={true}
      />
      <ClaimProceduresReviewAndConfirm claim_procedures={claim_procedures} />
      <ClaimMedicationsReviewAndConfirm claim_medications={claim_medications} />
      <ClaimAttachmentsReviewAndConfirm claim_attachments={claim_attachments} />
    </Stack>
  );
}
