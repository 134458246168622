import { ORGANIZATION_ROLE_SUBMITTER } from "@constants/organizations/organizations";

export const selectorAuth = (state) => state?.auth;
export const selectorAuthIsAuthenticated = (state) =>
  state?.auth?.isAuthenticated;
export const selectorAuthCurrentUser = (state) => state?.auth?.user;
export const selectorAuthCurrentUserId = (state) => state?.auth?.user?.id;
export const selectorAuthCurrentUserIsStaff = (state) =>
  state?.auth?.user?.is_staff;
export const selectorAuthCurrentUserOrganization = (state) =>
  state?.auth?.user?.organization;
export const selectorAuthCurrentUserOrganizationCountryId = (state) =>
  state?.auth?.user?.organization?.country;
export const selectorAuthCurrentUserOrganizationSlug = (state) =>
  state?.auth?.user?.organization?.slug;
export const selectorAuthCurrentUserOrganizationNdcTagCodes = (state) =>
  state?.auth?.user?.organization?.ndc_tag_codes;
export const selectorAuthCurrentUserOrganizationCurrency = (state) =>
  state?.auth?.user?.organization?.currency;

export const selectorAuthCurrentUserOrganizationRole = (state) =>
  state?.auth?.user?.organization?.role;
export const selectorAuthIsSubmitterOrganization = (state) =>
  state?.auth?.user?.organization?.role === ORGANIZATION_ROLE_SUBMITTER;
export const selectorAuthIsAuditOnly = (state) =>
  state?.auth?.user?.organization?.is_audit_only;

export const selectorAuthHasSubmitterPrivileges = (state) =>
  state?.auth?.user?.has_submitter_privileges;
export const selectorAuthHasProcessorPrivileges = (state) =>
  state?.auth?.user?.has_processor_privileges;
export const selectorAuthHasAdminPrivileges = (state) =>
  state?.auth?.user?.has_manager_privileges;

export const selectorAuthClaimMode = (state) => state?.auth?.claimMode;
export const selectorAuthBillingConversion = (state) =>
  state?.auth?.user?.billing_conversion;
