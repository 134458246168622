import {
  AZURE_ICD_ENABLED,
  EES_ENABLED,
  GCP_ICD_ENABLED,
} from "@constants/static/globals";
import {
  AveyBloomApi,
  ServerApi,
  apiWrapper,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
} from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";
import { getUniqueList } from "../utils/utils";

const endpointUrl = "/avey/icds/";
const queryKeySingular = "icd";
const queryKeyPlural = "icds";
const queryKeySingularHistory = `${queryKeySingular}_history`;

const eesEndpointUrl = "/api/ees/icds/";
const azureEndpointUrl = "/api/azure/icds/";
const gcpEndpointUrl = "/api/gcp/icds/";

export const useQueryFetchIcds = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
  useEesIfEnabled = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters, useEesIfEnabled },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchIcds, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        useEesIfEnabled,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchIcds({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  useEesIfEnabled = true,
}) {
  try {
    if (EES_ENABLED && useEesIfEnabled) {
      let searchQueries = [searchQuery];
      let nlpEndpointUrl = null;
      if (Boolean(searchQuery)) {
        if (GCP_ICD_ENABLED) nlpEndpointUrl = gcpEndpointUrl;
        else if (AZURE_ICD_ENABLED) nlpEndpointUrl = azureEndpointUrl;
        if (nlpEndpointUrl) {
          const body = { searchQuery };
          const response = await ServerApi().post(nlpEndpointUrl, body);
          searchQueries = getUniqueList([searchQuery, ...response.data]);
        }
      }
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        searches: searchQueries,
        ordering: sortQuery,
        filters,
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...filters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchIcd = ({
  icd__id = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: icd__id }],
    queryFn: () =>
      apiWrapper({ fn: fetchIcd, ...apiWrapperProps })({ icd__id }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchIcd({ icd__id = "" }) {
  try {
    const response = await AveyBloomApi().get(`${endpointUrl}${icd__id}/`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const useQueryFetchIcdHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchIcdHistory, ...apiWrapperProps })({
        page: pageParam,
        id,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchIcdHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
