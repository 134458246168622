import React from "react";

const ModelTypeContext = React.createContext({
  model_type: undefined,
  bundle_type: undefined,
});

export const useModelTypeContextProvider = () =>
  React.useContext(ModelTypeContext);

export const ModelTypeContextProvider = ({
  children,
  model_type,
  bundle_type,
}) => {
  return (
    <ModelTypeContext.Provider value={{ model_type, bundle_type }}>
      {children}
    </ModelTypeContext.Provider>
  );
};
