import {
  BLACK_PILL,
  BLUE_MARKED_PILL,
  BLUE_PILL,
  CANCELED_STATUS,
  GREY_MARKED_PILL,
  GREY_PILL,
  OUTLINED_BLACK_PILL,
  OUTLINED_GREY_PILL,
  PENDING_STATUS,
  PROCESSING_STATUS,
  RED_MARKED_PILL,
  RED_PILL,
  YELLOW_MARKED_PILL,
  YELLOW_PILL,
} from "@components/Custom/CustomPills";
import {
  consumableRowsToObjects,
  formatConsumableFormData,
} from "@constants/avey/consumables";
import {
  formatMedicationFormData,
  medicationRowsToObjects,
} from "@constants/avey/medications";
import {
  formatOrganizationBundleFormData,
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
  organizationBundleRowsToObjects,
} from "@constants/organizations/organization-bundles";
import {
  formatOrganizationClinicianFormData,
  organizationClinicianRowsToObjects,
} from "@constants/organizations/organization-clinicians";
import {
  formatOrganizationPatientFormData,
  organizationPatientRowsToObjects,
} from "@constants/organizations/organization-patients";
import {
  formatOrganizationPolicyFormData,
  organizationPolicyRowsToObjects,
} from "@constants/organizations/organization-policies";
import { getOptions } from "@helpers/hooks/objects";
import { getIntOrFloat } from "@helpers/parse/numbers";
import {
  MODEL_TYPE_CONSUMABLE,
  MODEL_TYPE_MEDICATION,
  MODEL_TYPE_ORGANIZATION_BUNDLE,
  MODEL_TYPE_ORGANIZATION_CLINICIAN,
  MODEL_TYPE_ORGANIZATION_PATIENT,
  MODEL_TYPE_ORGANIZATION_POLICY,
} from "./globals";

export const UPLOAD_STATUS_ALL = "";
export const UPLOAD_STATUS_PENDING = "P";
export const UPLOAD_STATUS_PREPROCESSING = "K";
export const UPLOAD_STATUS_SUCCESS = "S";
export const UPLOAD_STATUS_FAILURE = "F";
export const UPLOAD_STATUS_COMMITTING = "G";
export const UPLOAD_STATUS_COMMITTED = "C";
export const UPLOAD_STATUS_CANCELED = "X";

export const UPLOAD_ENTRY_STATUS_ALL = "";
export const UPLOAD_ENTRY_STATUS_PENDING = "P";
export const UPLOAD_ENTRY_STATUS_INVALID = "I";
export const UPLOAD_ENTRY_STATUS_IGNORED = "G";
export const UPLOAD_ENTRY_STATUS_CREATED = "C";
export const UPLOAD_ENTRY_STATUS_UPDATED = "U";
export const UPLOAD_ENTRY_STATUS_CANCELED = "X";

export const UPLOAD_STATUS_TEXT_MAPPER = {
  [UPLOAD_STATUS_ALL]: "All",
  [UPLOAD_STATUS_PENDING]: "Pending",
  [UPLOAD_STATUS_PREPROCESSING]: "Processing",
  [UPLOAD_STATUS_SUCCESS]: "To apply",
  [UPLOAD_STATUS_FAILURE]: "Failure",
  [UPLOAD_STATUS_COMMITTING]: "Applying",
  [UPLOAD_STATUS_COMMITTED]: "Applied",
  [UPLOAD_STATUS_CANCELED]: "Canceled",
};

export const UPLOAD_STATUS_PROPS_MAPPER = {
  [UPLOAD_STATUS_PENDING]: PENDING_STATUS,
  [UPLOAD_STATUS_PREPROCESSING]: PROCESSING_STATUS,
  [UPLOAD_STATUS_SUCCESS]: YELLOW_MARKED_PILL,
  [UPLOAD_STATUS_FAILURE]: RED_MARKED_PILL,
  [UPLOAD_STATUS_COMMITTING]: PROCESSING_STATUS,
  [UPLOAD_STATUS_COMMITTED]: BLUE_MARKED_PILL,
  [UPLOAD_STATUS_CANCELED]: CANCELED_STATUS,
};

export const UPLOAD_STATUS_FILTER_MAPPER = {
  textOptions: getOptions(UPLOAD_STATUS_TEXT_MAPPER),
  propsMapper: {
    [UPLOAD_STATUS_ALL]: BLACK_PILL,
    [UPLOAD_STATUS_PENDING]: OUTLINED_GREY_PILL,
    [UPLOAD_STATUS_PREPROCESSING]: OUTLINED_BLACK_PILL,
    [UPLOAD_STATUS_SUCCESS]: YELLOW_PILL,
    [UPLOAD_STATUS_FAILURE]: RED_PILL,
    [UPLOAD_STATUS_COMMITTING]: OUTLINED_BLACK_PILL,
    [UPLOAD_STATUS_COMMITTED]: BLUE_PILL,
    [UPLOAD_STATUS_CANCELED]: OUTLINED_GREY_PILL,
  },
};

export const UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER = {
  [UPLOAD_ENTRY_STATUS_PENDING]: "Pending",
  [UPLOAD_ENTRY_STATUS_CANCELED]: "Canceled",
  [UPLOAD_ENTRY_STATUS_INVALID]: "Invalid",
  [UPLOAD_ENTRY_STATUS_IGNORED]: "Ignored",
  [UPLOAD_ENTRY_STATUS_CREATED]: "Valid & new",
  [UPLOAD_ENTRY_STATUS_UPDATED]: "Valid & existing",
};

export const UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER = {
  [UPLOAD_ENTRY_STATUS_PENDING]: "Pending",
  [UPLOAD_ENTRY_STATUS_CANCELED]: "Canceled",
  [UPLOAD_ENTRY_STATUS_INVALID]: "Invalid",
  [UPLOAD_ENTRY_STATUS_IGNORED]: "Ignored",
  [UPLOAD_ENTRY_STATUS_CREATED]: "Created",
  [UPLOAD_ENTRY_STATUS_UPDATED]: "Updated",
};

export const UPLOAD_ENTRY_PROCESSING_STATUS_PROPS_MAPPER = {
  [UPLOAD_ENTRY_STATUS_PENDING]: PENDING_STATUS,
  [UPLOAD_ENTRY_STATUS_INVALID]: RED_MARKED_PILL,
  [UPLOAD_ENTRY_STATUS_IGNORED]: GREY_MARKED_PILL,
  [UPLOAD_ENTRY_STATUS_CREATED]: BLUE_MARKED_PILL,
  [UPLOAD_ENTRY_STATUS_UPDATED]: YELLOW_MARKED_PILL,
};

export const UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER = {
  [UPLOAD_ENTRY_STATUS_ALL]: BLACK_PILL,
  [UPLOAD_ENTRY_STATUS_PENDING]: OUTLINED_GREY_PILL,
  [UPLOAD_ENTRY_STATUS_CANCELED]: OUTLINED_BLACK_PILL,
  [UPLOAD_ENTRY_STATUS_INVALID]: RED_PILL,
  [UPLOAD_ENTRY_STATUS_IGNORED]: GREY_PILL,
  [UPLOAD_ENTRY_STATUS_CREATED]: BLUE_PILL,
  [UPLOAD_ENTRY_STATUS_UPDATED]: YELLOW_PILL,
};

export const getUploadStatusFilterMapper = ({ use_processing_status }) => {
  if (use_processing_status) {
    return {
      textOptions: getOptions({
        [UPLOAD_ENTRY_STATUS_ALL]: "All",
        ...UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER,
      }),
      propsMapper: UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER,
    };
  }
  return {
    textOptions: getOptions({
      [UPLOAD_ENTRY_STATUS_ALL]: "All",
      ...UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER,
    }),
    propsMapper: UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER,
  };
};

export const uploadRowsToObjects = ({
  rows,
  model_type,
  bundle_type,
  currency,
}) => {
  switch (model_type) {
    case MODEL_TYPE_CONSUMABLE:
      return consumableRowsToObjects(rows);

    case MODEL_TYPE_MEDICATION:
      return medicationRowsToObjects(rows);

    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      return organizationClinicianRowsToObjects(rows);

    case MODEL_TYPE_ORGANIZATION_PATIENT:
      return organizationPatientRowsToObjects(rows);

    case MODEL_TYPE_ORGANIZATION_POLICY:
      return organizationPolicyRowsToObjects({ rows, currency });

    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      return organizationBundleRowsToObjects({ rows, currency, bundle_type });

    default:
      return [];
  }
};

const FORMAT_FORMDATA_FN_MAPPER = {
  [MODEL_TYPE_CONSUMABLE]: formatConsumableFormData,
  [MODEL_TYPE_MEDICATION]: formatMedicationFormData,
  [MODEL_TYPE_ORGANIZATION_CLINICIAN]: formatOrganizationClinicianFormData,
  [MODEL_TYPE_ORGANIZATION_PATIENT]: formatOrganizationPatientFormData,
  [MODEL_TYPE_ORGANIZATION_POLICY]: formatOrganizationPolicyFormData,
  [MODEL_TYPE_ORGANIZATION_BUNDLE]: formatOrganizationBundleFormData,
};

export const formatUploadRawJson = ({
  rawJson,
  model_type,
  bundle_type,
  organization__slug,
  isSingleton = false,
}) => {
  const fn = FORMAT_FORMDATA_FN_MAPPER[model_type];
  const getFnProps = (formData) => ({
    formData: { ...formData, id: undefined },
    bundle_type,
    processor_organization__slug: organization__slug,
  });
  if (isSingleton) return fn(getFnProps(rawJson));
  return rawJson.map((formData) => fn(getFnProps(formData)));
};

export const objectNameGivenModelType = ({ model_type }) => {
  switch (model_type) {
    case MODEL_TYPE_CONSUMABLE:
      return "consumable";
    case MODEL_TYPE_MEDICATION:
      return "medication";
    case MODEL_TYPE_ORGANIZATION_CLINICIAN:
      return "clinician";
    case MODEL_TYPE_ORGANIZATION_PATIENT:
      return "patient";
    case MODEL_TYPE_ORGANIZATION_POLICY:
      return "policy";
    case MODEL_TYPE_ORGANIZATION_BUNDLE:
      return "code";
    default:
      return "";
  }
};

export const getUploadSummary = ({ summary }) => {
  const {
    [UPLOAD_ENTRY_STATUS_PENDING]: num_pending = 0,
    [UPLOAD_ENTRY_STATUS_INVALID]: num_invalid = 0,
    [UPLOAD_ENTRY_STATUS_IGNORED]: num_ignored = 0,
    [UPLOAD_ENTRY_STATUS_CREATED]: num_created = 0,
    [UPLOAD_ENTRY_STATUS_UPDATED]: num_updated = 0,
  } = summary || {};

  const newSummary = {
    [UPLOAD_ENTRY_STATUS_IGNORED]: num_ignored + num_invalid + num_pending,
    [UPLOAD_ENTRY_STATUS_CREATED]: num_created,
    [UPLOAD_ENTRY_STATUS_UPDATED]: num_updated,
  };

  return (Object.entries(newSummary) || [])
    .filter(([_, value]) => value > 0)
    .map(([key, value]) => {
      const status =
        UPLOAD_ENTRY_PROCESSING_STATUS_TEXT_MAPPER[key]?.toLowerCase();
      return (
        <div key={key}>
          <b>{getIntOrFloat(value)}</b>
          {` will be ${status}`}
        </div>
      );
    });
};

export const getTemplateFilename = ({ model_type, bundle_type }) => {
  let filename = "";
  if (model_type === MODEL_TYPE_ORGANIZATION_BUNDLE) {
    filename = {
      [ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES]:
        "procedure-internal-codes",
      [ORGANIZATION_BUNDLE_TYPE_MEDICATIONS]: "medication-internal-codes",
    }[bundle_type];
  } else {
    filename = {
      [MODEL_TYPE_CONSUMABLE]: "consumables",
      [MODEL_TYPE_MEDICATION]: "medications",
      [MODEL_TYPE_ORGANIZATION_CLINICIAN]: "clinicians",
      [MODEL_TYPE_ORGANIZATION_PATIENT]: "patients",
      [MODEL_TYPE_ORGANIZATION_POLICY]: "policies",
    }[model_type];
  }

  return `${filename}-template.xlsx`;
};
