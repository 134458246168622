import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { UPLOAD_STATUS_ALL } from "@constants/static/upload";
import Grid from "@mui/material/Grid";
import { useQueryFetchUploads } from "@redux/actions/utils/uploads";
import { useParams } from "react-router-dom";
import ConfigurationsBulkUploadCreateForm from "../ConfigurationsBulkUpload/ConfigurationsBulkUploadForms/ConfigurationsBulkUploadCreateForm";
import ConfigurationsBulkUploadsGridProps from "./ConfigurationsBulkUploadsGridProps";
import ConfigurationsBulkUploadsStatusFilters from "./ConfigurationsBulkUploadsStatusFilters";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function ConfigurationsBulkUploadsGrid() {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { bundle_type, model_type } = useModelTypeContextProvider();
  const { organization__slug } = useParams();
  const [statusTab, setStatusTab] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const useQueryFnProps = {
    model_type,
    searchQuery,
    filters: {
      as_staff,
      bundle_type,
      processor_organization__slug: organization__slug,
      ...(statusTab !== UPLOAD_STATUS_ALL ? { status: statusTab } : {}),
    },
  };
  const dataGridProps = React.useMemo(
    () =>
      ConfigurationsBulkUploadsGridProps({
        model_type,
        bundle_type,
        organization__slug,
        as_staff,
        isStaff,
      }),
    [model_type, bundle_type, organization__slug, as_staff, isStaff]
  );

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
      <Grid item flexGrow={1}>
        <CustomContextSearchField setSearchQuery={setSearchQuery} />
      </Grid>
      <Grid item>
        <ConfigurationsBulkUploadCreateForm />
      </Grid>
      <Grid item xs={12}>
        <ConfigurationsBulkUploadsStatusFilters setStatusTab={setStatusTab} />
      </Grid>
      <Grid item xs={12}>
        <CustomInfiniteDataGrid
          useQueryFn={useQueryFetchUploads}
          useQueryFnProps={useQueryFnProps}
          dataGridProps={dataGridProps}
          initialSortModel={initialSortModel}
        />
      </Grid>
    </Grid>
  );
}
