import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { getIdentityTypesHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import IdentityTypeCountriesData from "../IdentityType/IdentityType/IdentityTypeCountriesData";

export default function IdentityTypesGridProps({ as_staff }) {
  return {
    columns: [
      {
        flex: 1,
        field: "code",
        headerName: "Code",
        valueGetter: ({ value }) => value || "-",
      },
      {
        flex: 1,
        field: "name",
        headerName: "Name",
        renderCell: ({ row }) => (
          <CustomLabeledText
            value={row?.name}
            helperText={row?.description}
            wrapperProps={{ height: "inherit" }}
          />
        ),
      },
      {
        flex: 2,
        field: "countries",
        headerName: "Countries",
        renderCell: ({ value }) => (
          <IdentityTypeCountriesData countries={value} />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      getIdentityTypesHref({ identity_type__id: id, as_staff }),
  };
}
