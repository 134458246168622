import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function OrganizationBundleRelatedObjectData({
  label = "",
  frequency,
  title,
  description,
}) {
  return (
    <CustomGrid>
      <CustomLabeledText label={label} useChildren={true}>
        <Stack spacing={0.5} direction="row">
          <Typography variant="body2">{frequency}</Typography>
          <Typography variant="body2" color="textSecondary">
            {"x"}
          </Typography>
          <Typography variant="body2">{title}</Typography>
        </Stack>
      </CustomLabeledText>
      <CustomLabeledText
        label="Description"
        useChildren={true}
        useCustomTooltip={true}
        title={description}
      >
        <Typography variant="body2" className="ellipsis-1">
          {description || "-"}
        </Typography>
      </CustomLabeledText>
    </CustomGrid>
  );
}
