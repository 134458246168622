import React from "react";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import { AVEY_BLACK, AVEY_GREY } from "@styles/theme";
import { CustomOutlinedField } from "./CustomOutlinedField";

export default function CustomRadioForm({
  label = "",
  value = null,
  options = [],
  onChange = () => {},
  wrapperProps = {},
  disabled = false,
  error = false,
  gridItemProps = {},
}) {
  const isOptionChecked = (key) => value === key;

  return (
    <CustomOutlinedField
      label={label}
      wrapperProps={wrapperProps}
      childProps={{ px: 0 }}
      disabled={disabled}
      error={error}
    >
      <Grid container spacing={0}>
        {options.map(({ key, value: subValue }, index) => {
          const isChecked = isOptionChecked(key);
          const color = isChecked ? AVEY_BLACK : AVEY_GREY;
          const handleClick = () => onChange(key);
          return (
            <Grid item key={index} flex={1} {...gridItemProps}>
              <ListItemButton
                onClick={handleClick}
                disabled={disabled}
                sx={{ pl: 0, pr: 2, py: 0, height: "100%" }}
              >
                <ListItemIcon>
                  <Radio checked={isChecked} style={{ color }} size="small" />
                </ListItemIcon>
                <ListItemText
                  primary={subValue}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </Grid>
          );
        })}
      </Grid>
    </CustomOutlinedField>
  );
}
