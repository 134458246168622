import React from "react";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import { MODEL_TYPE_CLAIM } from "@constants/static/globals";
import { CLAIM_SUBMISSIONS_HREF } from "@constants/static/routing";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import { Navigate, Route, Routes } from "react-router-dom";
import ClaimSubmissionsRoutes from "./claim-submissions-routes";

export default function OrganizationRoutes() {
  const { isLoading, hasData } = useFetchOrganization();

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <Routes>
      <Route
        path={`${CLAIM_SUBMISSIONS_HREF}/*`}
        element={
          <ModelTypeContextProvider model_type={MODEL_TYPE_CLAIM}>
            <ClaimSubmissionsRoutes />
          </ModelTypeContextProvider>
        }
      />
      <Route path="*" element={<Navigate to={CLAIM_SUBMISSIONS_HREF} />} />
    </Routes>
  );
}
