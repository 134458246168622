import React from "react";
import CustomAvatar from "@components/Custom/CustomAvatar";
import {
  getClaimSubmissionHref,
  INSURANCE_CONFIGURATIONS_FULL_HREF,
  ORGANIZATIONS_FULL_HREF,
} from "@constants/static/routing";
import { CLAIM_MODE_MAPPER } from "@constants/static/system-modes";
import { getOrganizationTagId } from "@helpers/hooks/useScrollOrganizationIntoView";
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { NavLink, useLocation } from "react-router-dom";
import OrganizationActiveIndicator from "./OrganizationActiveIndicator";

const listItemButtonHeight = "80px";
const textMargin = "0px 20px";
const avatarMarginLeft = 38;

const getIsOrganizationActive = ({ organization__slug, pathname }) => {
  const isOrgsRoute = pathname.startsWith(ORGANIZATIONS_FULL_HREF);
  const isConfRoute = pathname.startsWith(INSURANCE_CONFIGURATIONS_FULL_HREF);
  const isSlugMatch = pathname.includes(`/${organization__slug}`);
  return (isOrgsRoute || isConfRoute) && isSlugMatch;
};

export default function OrganizationObject({
  object,
  claimMode = false,
  onClick = () => {},
  hasSearchQuery = false,
  useClaimModeToggle = false,
}) {
  const { pathname } = useLocation();
  const { slug: organization__slug } = object;
  const tagId = getOrganizationTagId(organization__slug);
  const to = getClaimSubmissionHref({ organization__slug });
  const handleDragStart = (e) => e.preventDefault();
  const isActive = getIsOrganizationActive({ organization__slug, pathname });

  return (
    <div
      id={tagId}
      style={{ scrollMarginTop: 14 }}
      onDragStart={handleDragStart}
    >
      <NavLink to={to} onClick={onClick}>
        <OrganizationObjectContent
          object={object}
          isActive={isActive}
          claimMode={claimMode}
          hasSearchQuery={hasSearchQuery}
          useClaimModeToggle={useClaimModeToggle}
        />
      </NavLink>
    </div>
  );
}

function OrganizationObjectContent({
  object,
  isActive,
  claimMode,
  hasSearchQuery,
  useClaimModeToggle = false,
}) {
  const { name, short_name, image, claim_mode } = object;
  const padding = isActive ? 0 : "4px";
  const avatarClassName = isActive
    ? "sizeLg"
    : "sizeMd opacity-70 hoverOpacityChild";
  const textClassName = isActive
    ? "ellipsis-1"
    : "ellipsis-1 opacity-70 hoverOpacityChild";

  return (
    <ListItem
      className="hoverOpacityParent fullTransition"
      disablePadding
      style={{ height: listItemButtonHeight, position: "relative" }}
    >
      <OrganizationActiveIndicator isActive={isActive} claimMode={claimMode} />
      <ListItemButton
        disableGutters
        style={{ height: "100%", paddingLeft: avatarMarginLeft }}
      >
        <ListItemIcon sx={{ justifyContent: "center", padding }}>
          <Badge
            color="info"
            badgeContent={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <CustomAvatar
              title={short_name}
              className={avatarClassName}
              variant="square"
              src={image}
            />
          </Badge>
        </ListItemIcon>
        <div
          style={{ margin: textMargin }}
          className={textClassName}
          title={name}
        >
          <Typography className="ellipsis-1" fontWeight="bold">
            {name}
          </Typography>
          {hasSearchQuery && useClaimModeToggle && (
            <Typography variant="caption" className="ellipsis-1">
              {CLAIM_MODE_MAPPER[claim_mode] + " mode"}
            </Typography>
          )}
        </div>
      </ListItemButton>
    </ListItem>
  );
}
