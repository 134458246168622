import React from "react";
import { CustomNavTabs } from "@components/Custom/CustomTabs";
import {
  ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
} from "@constants/organizations/organization-bundles";
import {
  ORGANIZATION_BUNDLE_MEDICATIONS_HREF,
  ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
  ORGANIZATION_PATIENTS_HREF,
  ORGANIZATION_PATIENTS_TITLE,
  ORGANIZATION_POLICIES_HREF,
  ORGANIZATION_POLICIES_TITLE,
} from "@constants/static/routing";
import Stack from "@mui/material/Stack";
import { useQueryFetchOrganizationPartnerRelatedObjectsCount } from "@redux/actions/utils/utils";
import { useParams } from "react-router-dom";
import OrganizationBundlesGrid from "../../OrganizationBundles/OrganizationBundles/OrganizationBundlesGrid";
import OrganizationPatientsGrid from "../../OrganizationPatients/OrganizationPatients/OrganizationPatientsGrid";
import OrganizationPoliciesGrid from "../../OrganizationPolicies/OrganizationPolicies/OrganizationPoliciesGrid";

const tabs = [
  {
    label: ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
    tab: ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF,
    fieldname: "num_procedure_and_consumable_bundles",
  },
  {
    label: ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
    tab: ORGANIZATION_BUNDLE_MEDICATIONS_HREF,
    fieldname: "num_medication_bundles",
  },
  {
    label: ORGANIZATION_POLICIES_TITLE,
    tab: ORGANIZATION_POLICIES_HREF,
    fieldname: "num_policies",
  },
  {
    label: ORGANIZATION_PATIENTS_TITLE,
    tab: ORGANIZATION_PATIENTS_HREF,
    fieldname: "num_patients",
  },
];

const TabComponentMapper = {
  [ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF]:
    OrganizationBundlesGrid,
  [ORGANIZATION_BUNDLE_MEDICATIONS_HREF]: OrganizationBundlesGrid,
  [ORGANIZATION_POLICIES_HREF]: OrganizationPoliciesGrid,
  [ORGANIZATION_PATIENTS_HREF]: OrganizationPatientsGrid,
};

const TabPropsMapper = {
  [ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF]: {
    bundle_type: ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES,
  },
  [ORGANIZATION_BUNDLE_MEDICATIONS_HREF]: {
    bundle_type: ORGANIZATION_BUNDLE_TYPE_MEDICATIONS,
  },
  [ORGANIZATION_POLICIES_HREF]: {},
  [ORGANIZATION_PATIENTS_HREF]: {},
};

export default function InsuranceOrganizationConfigurationsBody() {
  const { organization__slug } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const tab = searchParams.get("tab");
  const Component = TabComponentMapper[tab] || React.Fragment;
  const props = TabPropsMapper[tab] || {};
  const { data } = useQueryFetchOrganizationPartnerRelatedObjectsCount({
    organization__slug,
  });

  return (
    <Stack spacing={2}>
      <CustomNavTabs
        tabs={tabs}
        defaultTab={ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_HREF}
        tabCountData={data}
      />
      <div>
        <Component key={tab} {...props} />
      </div>
    </Stack>
  );
}
