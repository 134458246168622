import React from "react";
import CustomBulletPoints from "@components/Custom/CustomBulletPoints";
import CustomSection from "@components/Custom/CustomSection";
import Stack from "@mui/material/Stack";

export default function ClaimSubmissionUploadDataExceptionsJson({
  exceptions_json = [],
}) {
  const hasExceptions = Boolean(exceptions_json) && exceptions_json?.length > 0;

  if (!hasExceptions) return null;

  return (
    <CustomSection title="Errors" color="red" isMinified={true}>
      <Stack spacing={4}>
        <CustomBulletPoints
          bulletpoints={exceptions_json.map(({ error, row_numbers }, index) => (
            <React.Fragment key={index}>
              <div style={{ marginBottom: 4 }}>{error}</div>
              <ul className="bulletpoint">
                {row_numbers.map((row_number, index) => (
                  <li key={index}>{`Row ${row_number}`}</li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        />
      </Stack>
    </CustomSection>
  );
}
