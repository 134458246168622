import React from "react";
import {
  CustomDatePicker,
  CustomOutlinedTextField,
} from "@components/Custom/CustomOutlinedField";
import CustomRadioForm from "@components/Custom/CustomRadioForm";
import { ORGANIZATION_PATIENT_GENDER_OPTIONS } from "@constants/organizations/organization-patients";
import Grid from "@mui/material/Grid";

export default function OrganizationPatientDetailsFormData({
  formData = {},
  handleUpdateFormData = () => {},
}) {
  const {
    first_name,
    last_name,
    localized_first_name,
    localized_last_name,
    dob,
    gender,
  } = formData;

  const handleChange = (e) =>
    handleUpdateFormData({ [e.target.name]: e.target.value });

  const handleChangeField = ({ fieldname, newValue }) =>
    handleUpdateFormData({ [fieldname]: newValue });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            label="First name"
            name="first_name"
            value={first_name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            label="Last name"
            name="last_name"
            value={last_name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            label="Localized first name"
            name="localized_first_name"
            value={localized_first_name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomOutlinedTextField
            label="Localized Last name"
            name="localized_last_name"
            value={localized_last_name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomRadioForm
            label="Gender"
            value={gender}
            onChange={(newValue) =>
              handleChangeField({ fieldname: "gender", newValue })
            }
            options={ORGANIZATION_PATIENT_GENDER_OPTIONS}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomDatePicker
            label="Date of birth"
            value={dob}
            onChange={(newValue) =>
              handleChangeField({ fieldname: "dob", newValue })
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
