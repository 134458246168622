import React from "react";
import CustomInfiniteAutocomplete from "@components/Custom/CustomAutocomplete/CustomInfiniteAutocomplete";
import CustomChip from "@components/Custom/CustomChip";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import Stack from "@mui/material/Stack";
import { useQueryFetchCountries } from "@redux/actions/avey/countries";
import {
  getOptionLabelCountry,
  isOptionEqualToValueCountry,
  renderOptionCountry,
} from "./CountryAutocompleteComponents";

export default function CountriesAutocomplete({
  label = "Countries",
  error = false,
  value = [],
  handleChange = () => {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const hasValue = value.length > 0;
  const handleRemoveOption = (option) =>
    handleChange(value.filter(({ code }) => code !== option?.code));

  return (
    <Stack spacing={2}>
      <CustomInfiniteAutocomplete
        label={label}
        error={error}
        textFieldProps={{ ...textFieldProps }}
        autocompleteProps={{
          multiple: true,
          filterSelectedOptions: true,
          renderOption: renderOptionCountry,
          disableCloseOnSelect: true,
          ...autocompleteProps,
        }}
        useQueryFn={useQueryFetchCountries}
        getOptionLabel={getOptionLabelCountry}
        isOptionEqualToValue={isOptionEqualToValueCountry}
        value={value}
        handleChange={handleChange}
      />
      {hasValue && (
        <CustomGrid rowSpacing={1} columnSpacing={1}>
          {value.map((option) => (
            <CustomChip
              key={option.id}
              name={getOptionLabelCountry(option)}
              title={option.description}
              handleClick={() => handleRemoveOption(option)}
              hasCloseIcon={true}
            />
          ))}
        </CustomGrid>
      )}
    </Stack>
  );
}
