import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { UPLOAD_STATUS_PENDING } from "@constants/static/upload";
import MenuItem from "@mui/material/MenuItem";
import {
  invalidateUploadEntriesTSQ,
  revalidateUpload,
  updateUploadObjectTSQ,
} from "@redux/actions/utils/uploads";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import AppConfigurationsTitleComponent from "../../../AppConfigurations/AppConfigurationsTitleComponent";
import {
  getBulkUploadSnackbarKey,
  parseBulkUploadSnackbar,
} from "../../ConfigurationsBulkUploads/ConfigurationsBulkUploadsSnackbar";

export default function ConfigurationsBulkUploadRevalidateForm({ object }) {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { model_type } = useModelTypeContextProvider();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const TitleComponent = AppConfigurationsTitleComponent({ isStaff });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    revalidateUpload({ id: object.id, model_type, as_staff })
      .then(({ payload }) => {
        const { instance } = payload;
        closeSnackbar(
          getBulkUploadSnackbarKey({
            id: instance.id,
            status: UPLOAD_STATUS_PENDING,
            model_type,
          })
        );
        enqueueSnackbar(parseBulkUploadSnackbar(instance));
        updateUploadObjectTSQ({ object: instance });
        invalidateUploadEntriesTSQ({ model_type });
        setIsOpen(false);
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ message: payload, variant: "error" })
      )
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{"Re-validate upload"}</MenuItem>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title: "Re-validate upload" }}
        subtitleComponentProps={{
          title: "Are you sure you wish to re-validate this upload?",
        }}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        isBodyEmpty={true}
        yesText="Yes, I'm sure"
        noText="No, go back"
      />
    </>
  );
}
