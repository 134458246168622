import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { useQueryFetchUpload } from "@redux/actions/utils/uploads";
import { useParams } from "react-router-dom";
import ConfigurationsBulkUploadEntriesGrid from "./ConfigurationsBulkUploadEntriesGrid";
import ConfigurationsBulkUploadHeader from "./ConfigurationsBulkUploadHeader";

export default function ConfigurationsBulkUpload() {
  const { as_staff } = useStaffContextProvider();
  const { model_type } = useModelTypeContextProvider();
  const { upload__id: id } = useParams();
  const { isLoading, data } = useQueryFetchUpload({ id, model_type, as_staff });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  return (
    <>
      <ConfigurationsBulkUploadHeader object={data} />
      <CustomPage mt={0}>
        <ConfigurationsBulkUploadEntriesGrid object={data} />
      </CustomPage>
    </>
  );
}
