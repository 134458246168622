import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomActionDialogTitle from "@components/Custom/CustomActionDialog/CustomActionDialogTitle";
import CustomLabeledCheckbox from "@components/Custom/CustomLabeledCheckbox";
import CustomMoreInformationIcon from "@components/Custom/CustomMoreInformationIcon";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { ICD_TYPE_OPTIONS } from "@constants/avey/icds";
import Stack from "@mui/material/Stack";
import { updateOrganizationAgreement } from "@redux/actions/organizations/organization-agreements";
import { enqueueSnackbar } from "notistack";
import OrganizationAgreementFollowupDiscountFormData from "../OrganizationAgreementFollowupDiscountForm/OrganizationAgreementFollowupDiscountFormData";
import OrganizationAgreementSecondProcedureDiscountFormData from "../OrganizationAgreementSecondProcedureDiscountForm/OrganizationAgreementSecondProcedureDiscountFormData";

export default function OrganizationAgreementUpdateForm({
  object,
  isOpen,
  handleClose = () => {},
}) {
  const { processor_organization } = object || {};
  const { name, image } = processor_organization;
  const [formData, setFormData] = React.useState(object);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { icd_type, is_create_patient_from_mds, is_create_clinician_from_mds } =
    formData;

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeBoolean = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.checked }));

  const handleSubmit = (e) => {
    e?.preventDefault();
    updateOrganizationAgreement(formData)
      .then(() => {
        enqueueSnackbar({
          message: "Organization agreement successfully updated",
          variant: "success",
        });
        handleClose();
      })
      .catch(() => {})
      .finally(() => setIsProcessing(false));
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      TitleComponent={CustomActionDialogTitle}
      titleComponentProps={{
        image,
        title: `Update agreement with ${name}`,
        fullScreen: false,
      }}
    >
      <Stack spacing={3} direction="row" alignItems="center">
        <OrganizationAgreementFollowupDiscountFormData
          formData={formData}
          setFormData={setFormData}
        />
        <OrganizationAgreementSecondProcedureDiscountFormData
          formData={formData}
          setFormData={setFormData}
        />
      </Stack>
      <CustomOutlinedTextField
        label="ICD type"
        name="icd_type"
        select
        options={ICD_TYPE_OPTIONS}
        value={icd_type || ""}
        onChange={handleChange}
      />
      <Stack spacing={1}>
        <Stack spacing={2} direction="row" alignItems="center">
          <CustomLabeledCheckbox
            name="is_create_patient_from_mds"
            checked={is_create_patient_from_mds}
            label="Extract patient details from MDS"
            onChange={handleChangeBoolean}
          />
          <CustomMoreInformationIcon>
            {
              "Patient information extracted from an uploaded MDS will be used to create or update patient records within the system."
            }
          </CustomMoreInformationIcon>
        </Stack>
        <Stack spacing={2} direction="row" alignItems="center">
          <CustomLabeledCheckbox
            name="is_create_clinician_from_mds"
            checked={is_create_clinician_from_mds}
            label="Extract clinician ID from MDS"
            onChange={handleChangeBoolean}
          />
          <CustomMoreInformationIcon>
            {
              "When an unidentified clinician ID is uploaded via an MDS, a new profile shall be automatically created."
            }
          </CustomMoreInformationIcon>
        </Stack>
      </Stack>
    </CustomActionDialog>
  );
}
