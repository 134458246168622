import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomPie from "@components/Custom/CustomPie";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { getClaimSubmissionHref } from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { formatDateRange } from "@helpers/parse/time";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AVEY_GREY_F9 } from "@styles/theme";
import pluralize from "pluralize";
import { NavLink, useParams } from "react-router-dom";

const ClaimSubmissionObjectTag = ({ count, label, bgColor }) => (
  <Typography
    variant="body2"
    fontWeight="bold"
    bgcolor={bgColor}
    p="2px 8px"
    width="max-content"
    borderRadius="4px"
    className="ellipsis-1"
  >
    {getIntOrFloat(count)} {pluralize(label, count, false)}
  </Typography>
);

export default function ClaimSubmissionObject({
  object,
  isSubmitterOrganization,
  organizationRole,
  colorMapper = {},
  textMapper = {},
}) {
  const { organization__slug } = useParams();
  const {
    id,
    name,
    time_locked,
    claim_submission_summary = {},
    start_date,
    end_date,
    submitter_user,
    processor_organization,
  } = object;
  const { num_claims, num_claims_progressed, claim_counts } =
    claim_submission_summary;
  const progress = (num_claims_progressed / (num_claims || 1)) * 100;
  const data = Object.entries(claim_counts).map(([key, value]) => ({
    value,
    color: colorMapper[key],
    label: textMapper[key],
  }));

  const isLocked = Boolean(time_locked);
  const className =
    "elevated-8 elevateOnHover " + (isLocked ? "opacity-50" : "");

  const href = getClaimSubmissionHref({
    organization__slug,
    claim_submission__id: id,
  });

  const dateRange = formatDateRange({ start_date, end_date });

  return (
    <CustomTooltip
      title={
        <div style={{ width: "100%" }}>
          {"Created by "}
          <b>{submitter_user?.email}</b>
          {!isSubmitterOrganization && (
            <>
              {" at "}
              <b>{processor_organization?.name}</b>
            </>
          )}
        </div>
      }
    >
      <CardActionArea
        component={NavLink}
        to={href}
        className={className}
        sx={{ height: "100%" }}
      >
        <Box flex={1} display="flex" flexDirection="column" height="100%">
          <Stack
            p={2}
            spacing={1}
            direction="row"
            alignItems="center"
            height="100%"
          >
            <Stack
              spacing={1.5}
              justifyContent="space-between"
              flex={1}
              height="100%"
            >
              <Stack spacing={0.5}>
                <Typography className="ellipsis-2" variant="body2">
                  {name}
                </Typography>
                {Boolean(dateRange) && (
                  <Typography
                    variant="caption"
                    className="ellipsis-1"
                    title={dateRange}
                  >
                    {dateRange}
                  </Typography>
                )}
              </Stack>
              <CustomGrid rowSpacing={1} columnSpacing={1}>
                <ClaimSubmissionObjectTag
                  count={num_claims}
                  label="claim"
                  bgColor={AVEY_GREY_F9}
                />
                {/* <ClaimSubmissionObjectTag
                  count={count}
                  label="change"
                  bgColor={AVEY_YELLOW}
                /> */}
              </CustomGrid>
            </Stack>
            <Box display="grid" justifyContent="flex-end">
              <CustomPie
                data={data}
                value={Math.floor(progress)}
                unit="%"
                caption={isLocked ? "Locked" : "Progress"}
              />
            </Box>
          </Stack>
        </Box>
      </CardActionArea>
    </CustomTooltip>
  );
}
