import React from "react";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  CLAIM_STATUS_PENDING,
  CLAIM_STATUS_PROCESSING,
} from "@constants/claims/claim-status";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import { useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import {
  selectorAuthHasProcessorPrivileges,
  selectorAuthHasSubmitterPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ClaimProcessorLockForm from "../ClaimProcessorForms/ClaimProcessorLockForm";
import {
  ClaimProcessorUpdateClaimForm,
  ClaimProcessorUpdateClaimFormButtons,
} from "../ClaimProcessorForms/ClaimProcessorUpdateClaimForm";
import { ClaimHeaderProcessorActionsContextProvider } from "../ClaimProcessorForms/ClaimProcessorUpdateClaimForm/ClaimProcessorUpdateClaimFormContext";
import ClaimRawJson from "../ClaimRawJson";
import ClaimSubmitterDeleteClaimForm from "../ClaimSubmitterForms/ClaimSubmitterDeleteClaimForm";
import ClaimSubmitterResolveOrUpdateForm from "../ClaimSubmitterForms/ClaimSubmitterResolveOrUpdateForm";
import ClaimHeaderPrevNextLink from "./ClaimHeaderPrevNextLinks";
import ClaimVersionHistory from "./ClaimVersionHistory";

const StackWrapper = ({ children }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    flexGrow={1}
    justifyContent="flex-end"
  >
    {children}
  </Stack>
);

export default function ClaimHeaderActions({ object }) {
  const { isAgreementDisabled, isLoading, icd_type } =
    useFetchOrganizationAgreement();
  const isDownLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const {
    id,
    status,
    claim_submission,
    time_locked,
    is_locked,
    is_deleted,
    prev_instance_id,
    next_instance_id,
    raw_json,
  } = object;
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const hasProcessorPrivileges = useSelector(
    selectorAuthHasProcessorPrivileges
  );
  const isClaimSubmissionLocked = Boolean(claim_submission?.time_locked);
  const isPreprocessing = [
    CLAIM_STATUS_PROCESSING,
    CLAIM_STATUS_PENDING,
  ].includes(status);
  const isReadyForProcessing = [
    CLAIM_STATUS_PROCESSING,
    CLAIM_STATUS_PENDING,
  ].includes(status);

  if (!is_deleted) {
    if (hasSubmitterPrivileges && !isAgreementDisabled && !isLoading) {
      if (!is_locked && !isPreprocessing)
        return (
          <StackWrapper>
            <ClaimSubmitterResolveOrUpdateForm
              object={object}
              icd_type={icd_type}
            />
            <ClaimHeaderPrevNextLink
              prev_instance_id={prev_instance_id}
              next_instance_id={next_instance_id}
            />
            <CustomMoreActions>
              <ClaimRawJson raw_json={raw_json} />
              <ClaimVersionHistory />
              <ClaimSubmitterDeleteClaimForm id={id} />
            </CustomMoreActions>
          </StackWrapper>
        );
    } else if (hasProcessorPrivileges) {
      return (
        <ClaimHeaderProcessorActionsContextProvider object={object}>
          <StackWrapper>
            {isReadyForProcessing && !isDownLg && (
              <ClaimProcessorUpdateClaimFormButtons useCustomButtons={true} />
            )}
            <ClaimHeaderPrevNextLink
              prev_instance_id={prev_instance_id}
              next_instance_id={next_instance_id}
            />
            <CustomMoreActions>
              <ClaimRawJson raw_json={raw_json} />
              <ClaimVersionHistory />
              {isReadyForProcessing && isDownLg && (
                <ClaimProcessorUpdateClaimFormButtons
                  useCustomButtons={false}
                />
              )}
              {!isClaimSubmissionLocked && (
                <ClaimProcessorLockForm id={id} time_locked={time_locked} />
              )}
            </CustomMoreActions>
          </StackWrapper>
          {isReadyForProcessing && (
            <ClaimProcessorUpdateClaimForm status={object?.status} />
          )}
        </ClaimHeaderProcessorActionsContextProvider>
      );
    }
  }

  return (
    <StackWrapper>
      <ClaimHeaderPrevNextLink
        prev_instance_id={prev_instance_id}
        next_instance_id={next_instance_id}
      />
      <CustomMoreActions>
        <ClaimRawJson raw_json={raw_json} />
        <ClaimVersionHistory />
      </CustomMoreActions>
    </StackWrapper>
  );
}
