import React from "react";
import ConfigurationsBulkExportsGrid from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExports/ConfigurationsBulkExportsGrid";
import { useParams } from "react-router-dom";

export default function ClaimExportsGrid() {
  const { organization__slug, claim_submission__id } = useParams();
  const useQueryFnPropsFilters = {
    organization__slug,
    ...(claim_submission__id ? { claim_submission__id } : {}),
  };

  return (
    <ConfigurationsBulkExportsGrid
      useQueryFnPropsFilters={useQueryFnPropsFilters}
    />
  );
}
