import React from "react";
import CustomMoreInformationIcon from "@components/Custom/CustomMoreInformationIcon";
import {
  PAYMENT_PLAN_TYPE_MAPPER,
  PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO,
  PAYMENT_PLAN_TYPE_SUBSCRIPTION,
} from "@constants/payments/payments";
import { TOKEN_SHORT_NAME_SINGULAR } from "@constants/static/payment";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTime } from "@helpers/parse/time";
import { tokensToMoney } from "@helpers/parse/tokens";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectorAuthBillingConversion } from "@redux/selectors/auth";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import CustomLabeledText from "@components/Custom/CustomLabeledText";

export function OrganizationPaymentNextCycle({
  object = {},
  wrapperProps = {},
}) {
  const {
    tokenThreshold,
    endDate,
    isSubscription,
    isPayAsYouGo,
    planName,
    amount,
    numTokens,
  } = useEffectOrganizationPaymentNextCycle({
    object,
  });

  const hasTokenThreshold = isPayAsYouGo && tokenThreshold;
  const finalPlanName = isSubscription && `${planName} Plan`;

  return (
    <Stack
      minHeight={22}
      spacing={1}
      direction="row"
      alignItems="flex-end"
      {...wrapperProps}
    >
      <CustomLabeledText label="Next billing" useChildren={true}>
        <Typography variant="body2" component="div">
          {"On "}
          <b>{endDate}</b>

          {hasTokenThreshold && (
            <>
              {" or after consuming "}
              <b>{tokenThreshold}</b>
            </>
          )}
          {finalPlanName}
        </Typography>
      </CustomLabeledText>
      {hasTokenThreshold && (
        <CustomMoreInformationIcon>
          {"You’ll be billed for every "}
          <b>{tokenThreshold}</b>
          {" consumed or on the next billing date "}
          <b>{endDate}</b>
          {", whichever comes first."}
        </CustomMoreInformationIcon>
      )}
      {finalPlanName && (
        <CustomMoreInformationIcon>
          <b>{finalPlanName}</b>
          <br />
          {"You’ll be billed "}
          <b>{amount}</b>
          {" for "} <b>{numTokens}</b>
          {" on "}
          <b>{endDate}</b>
          {"."}
        </CustomMoreInformationIcon>
      )}
    </Stack>
  );
}

export function OrganizationPaymentConfirmNextCycle({ object = {} }) {
  const {
    tokenThreshold,
    paymentPlanType,
    planName,
    endDate,
    numTokens,
    payment_plan_type,
    amount,
    usageTokenThreshold,
  } = useEffectOrganizationPaymentNextCycle({
    object,
  });

  switch (payment_plan_type) {
    case PAYMENT_PLAN_TYPE_SUBSCRIPTION:
      return (
        <>
          <Typography variant="body2">
            {`${paymentPlanType} plan “${planName}“ will top up your balance by `}
            <b>{numTokens}</b>
            {", equivalent to "}
            <b>{amount}</b>
            {", at the start of each payment cycle."}
          </Typography>
          <Typography variant="body2">
            {"Your first payment will be made "}
            <b>{"now"}</b>
            {", and your next billing date will be on "}
            <b>{endDate}</b>
            {"."}
          </Typography>
        </>
      );

    case PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO:
      return (
        <>
          <Typography variant="body2">
            {`You’ve set your “${paymentPlanType}” usage cap to `}
            <b>{usageTokenThreshold}</b>
            {"."}
          </Typography>
          <Typography variant="body2">
            {"You’ll be billed for every "}
            <b>{tokenThreshold}</b>
            {" consumed or on the next billing date "}
            <b>{endDate}</b>
            {", whichever comes first."}
          </Typography>
        </>
      );

    default:
      return null;
  }
}

export const useEffectOrganizationPaymentNextCycle = ({ object = {} }) => {
  const billing_conversion = useSelector(selectorAuthBillingConversion);
  const {
    end_date,
    payment_plan_type,
    subscription_plan,
    pay_as_you_go_rate_per_token,
    pay_as_you_go_token_threshold,
    pay_as_you_go_usage_token_threshold,
  } = object;
  const endDate = parseTime({ value: end_date, format: "MMMM Do" });
  const paymentPlanType = PAYMENT_PLAN_TYPE_MAPPER[payment_plan_type];
  const isSubscription = payment_plan_type === PAYMENT_PLAN_TYPE_SUBSCRIPTION;
  const isPayAsYouGo = payment_plan_type === PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO;
  const initialDict = {
    endDate,
    payment_plan_type,
    paymentPlanType,
    isSubscription,
    isPayAsYouGo,
  };

  switch (payment_plan_type) {
    case PAYMENT_PLAN_TYPE_PAY_AS_YOU_GO:
      const token_threshold =
        Boolean(pay_as_you_go_token_threshold) &&
        pay_as_you_go_token_threshold?.num_tokens;

      return {
        ...initialDict,
        rate_per_token: pay_as_you_go_rate_per_token,
        tokenThreshold:
          token_threshold &&
          getIntOrFloat(token_threshold) +
            " " +
            pluralize(TOKEN_SHORT_NAME_SINGULAR, token_threshold, false),
        usageTokenThreshold: pluralize(
          TOKEN_SHORT_NAME_SINGULAR,
          pay_as_you_go_usage_token_threshold,
          true
        ),
      };

    case PAYMENT_PLAN_TYPE_SUBSCRIPTION:
      const {
        name = "",
        num_tokens = 0,
        rate_per_token = 0,
      } = subscription_plan || {};
      const amount = tokensToMoney({
        num_tokens,
        rate_per_token,
        billing_conversion,
      });
      return {
        ...initialDict,
        rate_per_token,
        numTokens: pluralize(TOKEN_SHORT_NAME_SINGULAR, num_tokens, true),
        amount,
        planName: name,
      };

    default:
      return initialDict;
  }
};
