import React from "react";
import CustomProgressBar from "@components/Custom/CustomProgressBar";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import { CustomSummaryCard } from "@components/Custom/CustomSummaryCards";
import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
  CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/claims/claim-submission-uploads";
import { parseTime, parseTimestamp } from "@helpers/parse/time";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ClaimSubmissionUploadCancelForm from "../../ClaimSubmissionUpload/ClaimSubmissionUploadForms/ClaimSubmissionUploadCancelForm";

export default function ClaimSubmissionUploadsActiveUpload({
  object,
  handleClickRow = () => {},
}) {
  const { time_created, num_claims, num_claims_created, preprocessing_status } =
    object;
  const hasClaims = num_claims > 0;
  const isPending =
    preprocessing_status === CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING;
  const title = parseTimestamp({ value: time_created });
  const fullTime = parseTime({ value: time_created });
  const color =
    CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER[preprocessing_status];
  const handleViewSummary = () => handleClickRow(object);

  return (
    <>
      <CustomSummaryCard
        title={title}
        titleProps={{
          variant: "body2",
          color: "textPrimary",
          className: "ellipsis-2",
          title: fullTime,
        }}
        headerProps={{ spacing: 2 }}
        wrapperProps={{ spacing: 1, py: 2 }}
        useChildren={true}
        moreActions={
          <>
            {isPending && <ClaimSubmissionUploadCancelForm object={object} />}
            <MenuItem onClick={handleViewSummary}>{"View summary"}</MenuItem>
          </>
        }
        action={
          <Stack spacing={2} direction="row" alignItems="center">
            <CustomStatusBox
              status={preprocessing_status}
              textMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER}
              propsMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER}
            />
            {hasClaims ? (
              <CustomProgressBar
                color={color}
                nominator={num_claims_created}
                denominator={num_claims}
              />
            ) : (
              isPending && <CircularProgress size={18} />
            )}
          </Stack>
        }
      />
    </>
  );
}
