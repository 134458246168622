import React from "react";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import { CustomTab, CustomTabs } from "@components/Custom/CustomTabs";
import {
  CLAIM_STATUS_ALL,
  getClaimStatusFilterMapper,
} from "@constants/claims/claim-status";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import { getIntOrFloat } from "@helpers/parse/numbers";
import {
  selectorAuthClaimMode,
  selectorAuthCurrentUserOrganizationRole,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";

export default function ClaimsStatusFilters({ setStatusTab = () => {} }) {
  const [value, setValue] = React.useState(0);
  const { data } = useFetchClaimSubmission();
  const claimMode = useSelector(selectorAuthClaimMode);
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const { textOptions, propsMapper } = React.useMemo(
    () => getClaimStatusFilterMapper({ claimMode, organizationRole }),
    [organizationRole, claimMode]
  );
  const { claim_submission_summary } = data || {};
  const { num_claims, claim_counts: tabCountData = {} } =
    claim_submission_summary || {};

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setStatusTab(textOptions[newValue]["key"]);
  };

  return (
    <CustomTabs value={value} onChange={handleChange} useUnderline={false}>
      {textOptions.map(({ key, value }, index) => (
        <CustomTab
          key={key}
          label={value}
          value={index}
          icon={
            <CustomStatusBox
              status={key}
              propsMapper={propsMapper}
              value={getIntOrFloat(
                key === CLAIM_STATUS_ALL
                  ? num_claims || "0"
                  : (tabCountData || {})[key] || "0"
              )}
              wrapperProps={{ ml: 1 }}
            />
          }
          iconPosition="end"
        />
      ))}
    </CustomTabs>
  );
}
