import React from "react";
import { ORGANIZATION_ROLE_SUBMITTER } from "@constants/organizations/organizations";
import { getSelf, verifyAccessToken } from "@redux/actions/users/auth";
import {
  selectorAuth,
  selectorAuthCurrentUserIsStaff,
  selectorAuthCurrentUserOrganizationRole,
  selectorAuthHasAdminPrivileges,
  selectorAuthHasSubmitterPrivileges,
  selectorAuthIsAuditOnly,
} from "@redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";

export default function useIsAuthenticatedHook() {
  const dispatch = useDispatch();
  const auth = useSelector(selectorAuth);
  const { isAuthenticated, isLoadingAuth, user } = auth || {};
  const hasUser = Boolean(user);
  const isSubmitterOrganization =
    useSelector(selectorAuthCurrentUserOrganizationRole) ===
    ORGANIZATION_ROLE_SUBMITTER;
  const isAuditOnly = useSelector(selectorAuthIsAuditOnly);
  const hasSubmitterPrivileges = useSelector(
    selectorAuthHasSubmitterPrivileges
  );
  const isStaff = useSelector(selectorAuthCurrentUserIsStaff);
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const useClaimModeToggle = isSubmitterOrganization && !isAuditOnly;

  React.useEffect(() => {
    dispatch(verifyAccessToken())
      .then(() => dispatch(getSelf()))
      .catch(() => console.log("User authentication failure"));
  }, [dispatch]);

  return {
    isStaff,
    isAuthenticated,
    isLoadingAuth,
    isSubmitterOrganization,
    hasSubmitterPrivileges,
    hasAdminPrivileges,
    hasUser,
    useClaimModeToggle,
  };
}
