import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import { getConfigurationsBulkExportsHeader } from "@constants/static/routing";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { useParams } from "react-router-dom";

export default function ConfigurationsBulkExportsHeader() {
  const { as_staff } = useStaffContextProvider();
  const { model_type, bundle_type } = useModelTypeContextProvider();
  const { organization__slug } = useParams();
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { title, hrefs } = getConfigurationsBulkExportsHeader({
    model_type,
    bundle_type,
    organization,
    as_staff,
  });
  return (
    <CustomHeader
      TitleComponent={CustomHeaderTitle}
      titleComponentProps={{ title }}
      hrefs={hrefs}
    />
  );
}
