import React from "react";
import CustomAvatar from "@components/Custom/CustomAvatar";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import {
  ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_AFFILIATION_FORM,
  ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_MAINTENANCE_FORM,
  ORGANIZATION_AGREEMENT_ACTION_UPDATE_FORM,
} from "@constants/organizations/organization-agreements";
import {
  getOrganizationSetupDefaultHref,
  ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
  ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
  ORGANIZATION_PATIENTS_TITLE,
  ORGANIZATION_POLICIES_TITLE,
} from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimeSince } from "@helpers/parse/time";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import OrganizationAgreementTag from "../OrganizationAgreement/OrganizationAgreement/OrganizationAgreementTag";

const getCount = ({ params, field }) =>
  getIntOrFloat(
    params?.row?.organization_agreement_related_object_counts?.[field] || "0"
  );

const getActions = ({ row, setState = () => {}, claimModeStr }) => {
  const { time_deleted, time_maintenance } = row;
  const isDeleted = Boolean(time_deleted);
  const isUnderMaintenance = Boolean(time_maintenance);
  const activateAffiliationLabel = isDeleted
    ? "Reactivate partner"
    : "Deactivate partner";
  const activateMaintenanceLabel = isUnderMaintenance
    ? `Enable ${claimModeStr.toLowerCase()}`
    : `Disable ${claimModeStr.toLowerCase()}`;

  const handleToggleActivateAffiliation = () =>
    setState({
      object: row,
      action: ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_AFFILIATION_FORM,
      isOpen: true,
    });

  const handleToggleActivateMaintenance = () =>
    setState({
      object: row,
      action: ORGANIZATION_AGREEMENT_ACTION_TOGGLE_ACTIVATE_MAINTENANCE_FORM,
      isOpen: true,
    });

  const handleUpdateOrganizationAgreement = () =>
    setState({
      object: row,
      action: ORGANIZATION_AGREEMENT_ACTION_UPDATE_FORM,
      isOpen: true,
    });

  if (isDeleted)
    return [
      <GridActionsCellItem
        showInMenu
        label={activateAffiliationLabel}
        onClick={handleToggleActivateAffiliation}
      />,
    ];

  return [
    <GridActionsCellItem
      showInMenu
      label="Update agreement"
      onClick={handleUpdateOrganizationAgreement}
    />,
    <GridActionsCellItem
      showInMenu
      label={activateMaintenanceLabel}
      onClick={handleToggleActivateMaintenance}
    />,
    <GridActionsCellItem
      showInMenu
      label={activateAffiliationLabel}
      onClick={handleToggleActivateAffiliation}
    />,
  ];
};

export default function OrganizationAgreementsGridProps({
  setState = () => {},
  claimModeStr,
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "processor_organization",
        headerName: "Insurer",
        renderCell: ({ value }) => (
          <CustomAvatar
            src={value?.image}
            title={value?.name}
            variant="square"
          />
        ),
      },
      {
        flex: 1,
        field: "num_procedures_and_consumables",
        headerName: ORGANIZATION_BUNDLE_PROCEDURES_AND_CONSUMABLES_TITLE,
        valueGetter: (params) =>
          getCount({ params, field: "num_procedures_and_consumables" }),
      },
      {
        flex: 1,
        field: "num_medications",
        headerName: ORGANIZATION_BUNDLE_MEDICATIONS_TITLE,
        valueGetter: (params) => getCount({ params, field: "num_medications" }),
      },
      {
        flex: 1,
        field: "num_policies",
        headerName: ORGANIZATION_POLICIES_TITLE,
        valueGetter: (params) => getCount({ params, field: "num_policies" }),
      },
      {
        flex: 1,
        field: "num_patients",
        headerName: ORGANIZATION_PATIENTS_TITLE,
        valueGetter: (params) => getCount({ params, field: "num_patients" }),
      },
      {
        flex: 1,
        field: "time_maintenance",
        headerName: claimModeStr,
        renderCell: ({ row, value }) => {
          if (Boolean(row.time_deleted)) return "-";
          return (
            <OrganizationAgreementTag
              time_maintenance={value}
              useClaimMode={false}
            />
          );
        },
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
      {
        flex: 1,
        field: "actions",
        type: "actions",
        getActions: ({ row }) => getActions({ row, setState, claimModeStr }),
      },
    ],
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ time_deleted, processor_organization }) =>
      !Boolean(time_deleted) &&
      getOrganizationSetupDefaultHref({
        organization__slug: processor_organization?.slug,
      }),
  };
}
