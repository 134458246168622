import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import { CustomTab, CustomTabs } from "@components/Custom/CustomTabs";
import {
  UPLOAD_STATUS_ALL,
  UPLOAD_STATUS_FILTER_MAPPER,
} from "@constants/static/upload";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { useQueryFetchUploadsSummary } from "@redux/actions/utils/uploads";
import { useParams } from "react-router-dom";

export default function ConfigurationsBulkUploadsStatusFilters({
  setStatusTab = () => {},
}) {
  const { as_staff } = useStaffContextProvider();
  const { model_type } = useModelTypeContextProvider();
  const [value, setValue] = React.useState(0);
  const { organization__slug } = useParams();
  const { data } = useQueryFetchUploadsSummary({
    model_type,
    filters: { processor_organization__slug: organization__slug, as_staff },
  });
  const { textOptions, propsMapper } = UPLOAD_STATUS_FILTER_MAPPER;
  const { num_uploads, uploads_counts: tabCountData = {} } = data || {};
  const handleChange = (_, newValue) => {
    setValue(newValue);
    setStatusTab(textOptions[newValue]["key"]);
  };

  return (
    <CustomTabs value={value} onChange={handleChange} useUnderline={false}>
      {textOptions.map(({ key, value }, index) => (
        <CustomTab
          key={key}
          label={value}
          value={index}
          icon={
            <CustomStatusBox
              status={key}
              propsMapper={propsMapper}
              value={getIntOrFloat(
                key === UPLOAD_STATUS_ALL
                  ? num_uploads || "0"
                  : (tabCountData || {})[key] || "0"
              )}
              wrapperProps={{ ml: 1 }}
            />
          }
          iconPosition="end"
        />
      ))}
    </CustomTabs>
  );
}
