import React from "react";
import HealthcareOrganizationTag, {
  useHealthcareOrganizationTag,
} from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationTag";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import { getDrugNameStr, getUnitStr } from "@constants/avey/medications";

export default function MedicationDetailsData({ object, isMinified = false }) {
  const { isStaff } = useStaffContextProvider();
  const {
    submitter_organization,
    code,
    dosage_form,
    generic_name,
    package_type,
    trade_name,
  } = object;

  const hasCode = Boolean(code);
  const useHealthcareOrganization = useHealthcareOrganizationTag({
    isStaff,
    submitter_organization,
  });
  const dosageFormUnitStr = getUnitStr(dosage_form);
  const packageTypeUnitStr = getUnitStr(package_type);
  const tradeName = getDrugNameStr(trade_name);
  const genericName = getDrugNameStr(generic_name);

  return (
    <CustomSection title="Medication details" isMinified={isMinified}>
      <CustomGrid>
        {useHealthcareOrganization && (
          <HealthcareOrganizationTag
            submitter_organization={submitter_organization}
          />
        )}
        {hasCode && <CustomLabeledText label="Code" value={code} />}
        <CustomLabeledText label="Generic name" value={genericName} />
        <CustomLabeledText label="Trade name" value={tradeName} />
        <CustomLabeledText label="Package type" value={packageTypeUnitStr} />
        <CustomLabeledText label="Dosage form" value={dosageFormUnitStr} />
      </CustomGrid>
    </CustomSection>
  );
}
