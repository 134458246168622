import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function CustomStatusBox({
  value,
  status,
  textMapper = {},
  propsMapper = {},
  typographyProps = {},
  wrapperProps = {},
}) {
  return (
    <Box {...propsMapper[status]} {...wrapperProps}>
      <Typography variant="body2" {...typographyProps}>
        {typeof value === "undefined" ? textMapper[status] : value}
      </Typography>
    </Box>
  );
}
