import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchOrganizationPatientHistory } from "@redux/actions/organizations/organization-patients";
import { useParams } from "react-router-dom";
import OrganizationPatientData from "../OrganizationPatientData";
import { OrganizationPatientHeaderTitle } from "./OrganizationPatientHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { first_name, last_name, patient_id } = object;
  const name = `${first_name || ""} ${last_name || ""}`.trim();
  return (
    <Typography variant="body2" className="ellipsis-1">
      <b>{patient_id}</b> {name}
    </Typography>
  );
};

export default function OrganizationPatientVersionHistory() {
  const { organization_patient__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchOrganizationPatientHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={OrganizationPatientData}
      VersionHistoryObjectTitleComponent={OrganizationPatientHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
