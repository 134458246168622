import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const emptyIdentityTypeFormData = {
  code: "",
  name: "",
  description: "",
  countries: [],
};

export const formatIdentityTypeFormData = ({ isCheck, formData }) => {
  const { id = undefined, code, name, description, countries } = formData;

  return {
    id,
    is_check: isCheck,
    code: code?.trim(),
    name: name?.trim(),
    description: description?.trim(),
    countries,
  };
};

const identityTypeRowToObject = (row) => {
  const { code, name, description, country_codes } = getRowValue({
    row,
    columns: [
      { headerName: "CODE", fieldname: "code" },
      { headerName: "NAME", fieldname: "name" },
      { headerName: "DESCRIPTION", fieldname: "description" },
      { headerName: "COUNTRY CODES", fieldname: "countries" },
    ],
  });

  let countries = [];
  if (Boolean(country_codes))
    countries = country_codes.split(",").map((code) => ({ code: code.trim() }));

  const isRowEmpty = !(code || name || description);

  return {
    id: isRowEmpty ? null : uuidv4(),
    code,
    name,
    description,
    countries,
  };
};

export const identityTypeRowsToObject = (rows) =>
  rows
    .map((row) => identityTypeRowToObject(row))
    .filter(({ id }) => Boolean(id));
