import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import {
  CLAIM_SECTION_ICD_TITLE_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimIcdData from "../../../../ClaimIcds/ClaimIcdData";
import {
  RESOLVE_CLAIM_STAGE_CLAIM_ICDS_VARIATION_MAPPER,
  useClaimSubmitterResolveClaimFormContextProvider,
} from "../ClaimSubmitterResolveClaimFormContextProvider";
import { ClaimSubmitterResolveClaimSelectOptionwrapper } from "../ClaimSubmitterResolveClaimOptionWrappers";

export default function ClaimIcdsSelectVariation({
  type = CLAIM_SECTION_SYMPTOMS,
}) {
  const { variations_key, variation_key, update_stage_index } =
    RESOLVE_CLAIM_STAGE_CLAIM_ICDS_VARIATION_MAPPER[type];
  const {
    [variations_key]: claim_icd_variations = [],
    [variation_key]: claim_icd_variation = {},
    updateContext = () => {},
  } = useClaimSubmitterResolveClaimFormContextProvider();
  const { index: variationIndex } = claim_icd_variation || {};

  const handleSelect = ({ e, index }) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateContext({
      [variation_key]: {
        index,
        object: claim_icd_variations?.[index],
      },
    });
  };

  const handleEdit = ({ e, index }) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateContext({
      [variation_key]: {
        index,
        object: claim_icd_variations?.[index],
      },
      stageIndex: update_stage_index,
    });
  };

  return (
    <Stack spacing={2}>
      {claim_icd_variations.map(
        ({ claim_icds = [], claim_icds_text = "" }, index) => (
          <ClaimSubmitterResolveClaimSelectOptionwrapper
            key={index}
            isSelected={index === variationIndex}
            handleSelect={(e) => handleSelect({ e, index })}
            handleEdit={(e) => handleEdit({ e, index })}
            padding="16px"
          >
            <Stack spacing={2}>
              {claim_icds.map((object, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider />}
                  <ClaimIcdData index={index} object={object} type={type} />
                </React.Fragment>
              ))}
              {Boolean(claim_icds_text) && (
                <>
                  {claim_icds?.length > 0 && <Divider />}
                  <CustomLabeledText
                    label={CLAIM_SECTION_ICD_TITLE_MAPPER[type]}
                    value={claim_icds_text}
                  />
                </>
              )}
            </Stack>
          </ClaimSubmitterResolveClaimSelectOptionwrapper>
        )
      )}
    </Stack>
  );
}
