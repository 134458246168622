import React from "react";
import CustomInfiniteAutocomplete from "@components/Custom/CustomAutocomplete/CustomInfiniteAutocomplete";
import CustomChip from "@components/Custom/CustomChip";
import Stack from "@mui/material/Stack";
import { useQueryFetchClaimSubmissions } from "@redux/actions/claims/claim-submissions";
import { useParams } from "react-router-dom";
import {
  getClaimSubmissionOptionLabel,
  isClaimSubmissionOptionEqualToValue,
  renderClaimSubmissionOption,
} from "./ClaimSubmissionsAutocompleteComponents";

export default function ClaimSubmissionsAutocomplete({
  label = "Claim submissions",
  error = false,
  helperText = "",
  value = [],
  handleChange = () => {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const { organization__slug } = useParams();
  const hasValue = value.length > 0;
  const handleRemoveOption = (option) =>
    handleChange(value.filter(({ id }) => id !== option?.id));

  return (
    <Stack spacing={2}>
      <CustomInfiniteAutocomplete
        label={label}
        error={error}
        helperText={helperText}
        textFieldProps={textFieldProps}
        autocompleteProps={{
          multiple: true,
          filterSelectedOptions: true,
          disableCloseOnSelect: true,
          renderOption: renderClaimSubmissionOption,
          ...autocompleteProps,
        }}
        useQueryFn={useQueryFetchClaimSubmissions}
        useQueryFnProps={{ filters: { organization__slug } }}
        getOptionLabel={getClaimSubmissionOptionLabel}
        isOptionEqualToValue={isClaimSubmissionOptionEqualToValue}
        value={value}
        handleChange={handleChange}
      />
      {hasValue && (
        <Stack spacing={1}>
          {value.map((option) => (
            <CustomChip
              key={option.id}
              handleClick={() => handleRemoveOption(option)}
              color="outlined"
              hasCloseIcon={true}
              imageProps={{ variant: "circle" }}
            >
              {renderClaimSubmissionOption({}, option)}
            </CustomChip>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
