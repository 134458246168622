import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getFormData,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import {
  invalidateTSQ,
  updatePaginatedTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";
import { AUTH_UPDATE_ORGANIZATION } from "../../actionTypes/auth";

const endpointUrl = "/organizations/organizations/";
const queryKeySingular = "organization";
const queryKeyPlural = "organizations";
const processorQueryKeyPlural = `processor-${queryKeyPlural}`;
const queryKeySingularPartners = `${queryKeySingular}-partners`;

export const useQueryFetchOrganizations = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchOrganizations, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizations({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchAllProcessorOrganizations = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      processorQueryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchAllProcessorOrganizations,
        ...apiWrapperProps,
      })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 60_000,
    ...reactQueryProps,
  });
};

async function fetchAllProcessorOrganizations({
  page = 1,
  page_size = 20,
  searchQuery,
  sortQuery,
  filters,
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(
      `${endpointUrl}get_all_processor_organizations/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchPartnerOrganizations = ({
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingularPartners],
    queryFn: () =>
      apiWrapper({
        fn: fetchPartnerOrganizations,
        ...apiWrapperProps,
      })(),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchPartnerOrganizations() {
  try {
    const response = await AveyBloomApi().get(`${endpointUrl}get_partners/`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const useQueryFetchOrganization = ({
  organization__slug = "",
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { organization__slug }],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganization,
        ...apiWrapperProps,
      })({ organization__slug }),
    staleTime: 300_000,
    cacheTime: 60_000,
    ...reactQueryProps,
  });
};

async function fetchOrganization({ organization__slug = "" }) {
  try {
    if (!organization__slug) return null;
    const response = await AveyBloomApi().get(
      `${endpointUrl}${organization__slug}/`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const updateOrganizationImage = (data) => async (dispatch) => {
  try {
    const formData = getFormData(data);
    const response = await AveyBloomApi({
      headers: { "Content-Type": "multipart/form-data" },
    }).put(`${endpointUrl}${data.slug}/update_image/`, formData);
    dispatch({ type: AUTH_UPDATE_ORGANIZATION, payload: response.data });
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
};

export function updateOrganizationTSQ({ object }) {
  updatePaginatedTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeyPlural &&
      queryKey[1]?.filters?.as_tpa_submitters_filter === "1",
    object,
  });
}

export async function updateOrganizationAffiliations(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}toggle_activate_affiliations/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateOrganizationAffiliation(data) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}toggle_activate_affiliation/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function invalidatePartnerOrganizationsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeySingularPartners,
  });
}

export function invalidateOrganizationsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyPlural,
  });
}
