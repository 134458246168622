import React from "react";
import Avatar from "@mui/material/Avatar";
import CustomTooltip from "./CustomTooltip";

export default function CustomAvatar({
  src = "",
  placeholder = "",
  title,
  ...props
}) {
  const { className = "", ...otherProps } = props || {};
  const defaultSrc =
    placeholder || "/assets/images/placeholders/no-picture.png";
  return (
    <CustomTooltip title={title} tooltipProps={{ placement: "right" }}>
      <Avatar
        className={className + " elevated-4"}
        src={src || defaultSrc}
        {...otherProps}
      />
    </CustomTooltip>
  );
}
