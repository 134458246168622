import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchSpecialityHistory } from "@redux/actions/avey/specialities";
import { useParams } from "react-router-dom";
import SpecialityData from "./SpecialityData";
import { SpecialityHeaderTitle } from "./SpecialityHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { name } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {name}
    </Typography>
  );
};

export default function SpecialityVersionHistory() {
  const { speciality__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchSpecialityHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={SpecialityData}
      VersionHistoryObjectTitleComponent={SpecialityHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
