import React from "react";
import SpecialitiesAutocomplete from "@components/Custom/CustomAutocomplete/SpecialityAutocomplete/SpecialitiesAutocomplete";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function OrganizationClinicianFormData({
  formData = {},
  setFormData = () => {},
  gridItemProps = {},
}) {
  const {
    first_name,
    last_name,
    clinician_id,
    specialities = [],
  } = formData || {};

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChangeSpecialities = (payload) =>
    setFormData({ ...formData, specialities: payload });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} {...gridItemProps}>
          <CustomOutlinedTextField
            required
            autoFocus
            label="Clinician ID"
            name="clinician_id"
            value={clinician_id}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} {...gridItemProps}>
          <CustomOutlinedTextField
            required
            label="First name"
            name="first_name"
            value={first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} {...gridItemProps}>
          <CustomOutlinedTextField
            required
            label="Last name"
            name="last_name"
            value={last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SpecialitiesAutocomplete
            value={specialities}
            handleChange={handleChangeSpecialities}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export const OrganizationClinicianFormDataOnBlur = ({
  formData: parentFormData = {},
  setFormData: setParentFormData = () => {},
  gridItemProps = {},
}) => {
  const [formData, setFormData] = React.useState(parentFormData);

  React.useEffect(() => {
    setFormData(parentFormData);
  }, [parentFormData]);

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      !currentTarget?.contains(document.activeElement) &&
        setParentFormData(formData);
    }, 0);
  };

  return (
    <Box tabIndex={-1} onBlur={handleBlur}>
      <OrganizationClinicianFormData
        formData={formData}
        setFormData={setFormData}
        gridItemProps={gridItemProps}
      />
    </Box>
  );
};
