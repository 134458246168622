import {
  getClaimSubmissionUploadSnackbarKey,
  parseClaimSubmissionUploadSnackbar,
} from "@components/Authenticated/ClaimSubmissions/ClaimSubmissionUploads/ClaimSubmissionUpload/ClaimSubmissionUploadSnackbar";
import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED,
  CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
} from "@constants/claims/claim-submission-uploads";
import { ORGANIZATION_ROLE_SUBMITTER } from "@constants/organizations/organizations";
import { updateClaimSubmissionUploadObjectTSQ } from "@redux/actions/claims/claim-submission-uploads";
import { updateClaimSubmissionObjectTSQ } from "@redux/actions/claims/claim-submissions";
import { updateClaimObjectsTSQ } from "@redux/actions/claims/claims";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export default function handleWsClaimSubmissionUpload({
  object = {},
  organizationRole,
  enqueuedSnackbarKeys,
}) {
  let key = null;
  const { id, preprocessing_status, claims, claim_submission } = object;
  const isSubmitterOrganization =
    organizationRole === ORGANIZATION_ROLE_SUBMITTER;
  const isNotPendingStatus = ![
    CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
    CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED,
  ].includes(preprocessing_status);

  updateClaimSubmissionObjectTSQ({ object: claim_submission });
  updateClaimSubmissionUploadObjectTSQ({ object });
  if (Boolean(claims)) updateClaimObjectsTSQ({ objects: claims });
  if (isSubmitterOrganization && isNotPendingStatus) {
    closeSnackbar(
      getClaimSubmissionUploadSnackbarKey({
        id,
        preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
      })
    );
    const parsedClaimSubmissionUpload =
      parseClaimSubmissionUploadSnackbar(object);
    key = parsedClaimSubmissionUpload?.key;

    !enqueuedSnackbarKeys.has(key) &&
      enqueueSnackbar(parsedClaimSubmissionUpload);
  }
  return key;
}
