import Papa from "papaparse";
import * as XLSX from "xlsx";

export default function convertFileToJson({ file, callback }) {
  if (file) {
    const fileType = file.type;
    const isCsvFileType = fileType === "text/csv";
    const isTsvFileType = fileType === "text/tab-separated-values";
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileData = e.target.result;

      if (isCsvFileType || isTsvFileType) {
        Papa.parse(fileData, {
          header: true,
          delimiter: isTsvFileType ? "\t" : ",",
          complete: (results) => callback(results.data),
        });
      } else if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        const workbook = XLSX.read(new Uint8Array(fileData), {
          type: "array",
          cellDates: true,
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: "yyyy-mm-dd",
        });
        callback(json);
      } else {
        callback([]);
      }
    };

    reader.onerror = (e) => callback([]);

    if (isCsvFileType || isTsvFileType) {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  } else {
    callback([]);
  }
}
