import React from "react";
import ClaimVisitFormData from "../../../../ClaimVisit/ClaimVisitFormData";
import { useClaimSubmitterResolveClaimFormContextProvider } from "../ClaimSubmitterResolveClaimFormContextProvider";
import { ClaimSubmitterResolveClaimUpdateOptionWrapper } from "../ClaimSubmitterResolveClaimOptionWrappers";

export default function ClaimVisitUpdateVariation() {
  const { claim_visit_variation = {}, updateContext = () => {} } =
    useClaimSubmitterResolveClaimFormContextProvider();
  const { index: variationIndex, object } = claim_visit_variation || {};

  const handleUpdate = (payload) => {
    updateContext({
      claim_visit_variation: {
        index: variationIndex,
        object: payload,
      },
    });
  };

  return (
    <ClaimSubmitterResolveClaimUpdateOptionWrapper>
      <ClaimVisitFormData formData={object} setFormData={handleUpdate} />
    </ClaimSubmitterResolveClaimUpdateOptionWrapper>
  );
}
