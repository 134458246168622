import React from "react";
import Typography from "@mui/material/Typography";

export const isSpecialityOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getSpecialityOptionLabel = (object) => {
  if (!Boolean(object)) return "";
  const { name } = object;
  return name;
};

export const renderOptionSpeciality = (props, option) => {
  const { name, description } = option;
  return (
    <div {...props}>
      <div>
        <Typography variant="body2" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {description}
        </Typography>
      </div>
    </div>
  );
};
