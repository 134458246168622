import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import IdentityTypeCreateForm from "../IdentityType/IdentityTypeForms/IdentityTypeCreateForm";

export default function IdentityTypeCreateForms() {
  return (
    <CustomAddOrUploadWrapper label="Add identity types" useUploadForm={false}>
      <IdentityTypeCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
