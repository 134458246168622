import React from "react";
import ConfigurationsBulkExportForm from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportForm";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import { exportClaims } from "@redux/actions/claims/claims";
import { describeFilters } from "../../Claims/Claims/ClaimsFilters";
import ClaimExportUseReportFormData, {
  claimExportUseReportInitialFormData,
} from "./ClaimExportUseReportFormData";

export default function ClaimExportClaimSubmissionForm({
  useQueryFnPropsClaims,
}) {
  return (
    <ConfigurationsBulkExportForm
      label="claims"
      submitFn={exportClaims}
      useQueryFnProps={useQueryFnPropsClaims}
      TitleComponent={InsuranceOrganizationActionDialogTitle}
      describeFilters={describeFilters}
      parentFormData={claimExportUseReportInitialFormData}
      ParentFormDataComponent={ClaimExportUseReportFormData}
    />
  );
}
