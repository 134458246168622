import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import Grid from "@mui/material/Grid";
import { useQueryFetchDrugNames } from "@redux/actions/avey/drug-names";
import {
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import DrugNameCreateForms from "./DrugNameCreateForms";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./DrugNameFilters";
import DrugNamesGridProps from "./DrugNamesGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function DrugNamesGrid() {
  const { as_staff, isStaff } = useStaffContextProvider();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true, as_staff, ...filters },
    eesFilters: { submitter_organization__slug },
    useEesIfEnabled: !isStaff,
  };
  const dataGridProps = React.useMemo(
    () => DrugNamesGridProps({ as_staff, isStaff }),
    [as_staff, isStaff]
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasAdminPrivileges && (
          <Grid item>
            <DrugNameCreateForms />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchDrugNames}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </div>
  );
}
