import React from "react";
import CustomDividedList from "@components/Custom/CustomDividedList";

export default function OrganizationPatientIdentitiesDataSummary({
  organization_patient_identities = [],
  divideAt = 1,
}) {
  const list = organization_patient_identities.map(
    ({ identity_type, number }) => ({
      name: number,
      title: identity_type?.name,
      color: "grey",
    })
  );

  return <CustomDividedList list={list} divideAt={divideAt} />;
}
