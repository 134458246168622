import React from "react";
import CustomRotatingCircle from "@components/Custom/CustomRotatingCircle";
import { SITE_LOGO_BLUE, SITE_NAME } from "@constants/static/globals";
import { LOGIN_HREF } from "@constants/static/routing";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";

export default function UnauthenticatedWrapper({
  children,
  backgroundColor = "black",
  useLogo = true,
}) {
  return (
    <div style={{ backgroundColor, minHeight: "100vh", display: "grid" }}>
      <div
        style={{
          display: "grid",
          overflow: "clip",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: 0,
        }}
      >
        <CustomRotatingCircle
          width="100vw"
          top="50vh"
          left="-50vw"
          variant="2"
        />
        <CustomRotatingCircle
          width="100vw"
          top="-100vh"
          left="-50vw"
          variant="1"
        />
        <CustomRotatingCircle width="100vw" top="0vh" left="70vw" variant="2" />
      </div>
      <div style={{ padding: "0px 32px", minHeight: "inherit", zIndex: 1 }}>
        <Box
          minHeight="inherit"
          m="auto"
          maxWidth={{ xs: "100%", sm: 400 }}
          width="100%"
          display="grid"
          alignItems="center"
          textAlign="center"
          zIndex={1000}
        >
          <div style={{ padding: "32px 0px" }}>
            {useLogo && (
              <Box
                mb={4}
                position={{ xs: "relative", md: "absolute" }}
                top={{ md: 80 }}
                left={{ md: 80 }}
              >
                <NavLink className="opacityOnHover-50" to={LOGIN_HREF}>
                  <img
                    width="100%"
                    height={60}
                    src={SITE_LOGO_BLUE}
                    alt={SITE_NAME}
                  />
                </NavLink>
              </Box>
            )}
            <Box>{children}</Box>
          </div>
        </Box>
      </div>
    </div>
  );
}
