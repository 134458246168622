import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { getMedicationUploadsHref } from "@constants/static/routing";
import MedicationCreateForm from "../Medication/MedicationForms/MedicationCreateForm";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";

export default function MedicationsCreateForms() {
  const { as_staff } = useStaffContextProvider();
  const uploadHistoryHref = getMedicationUploadsHref({ as_staff });
  return (
    <CustomAddOrUploadWrapper
      label="Add medications"
      uploadHistoryHref={uploadHistoryHref}
      useUploadForm={true}
    >
      <MedicationCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
