import React from "react";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import { MODEL_TYPE_ORGANIZATION_CLINICIAN } from "@constants/static/globals";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationClinicians } from "@redux/actions/organizations/organization-clinicians";
import {
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ConfigurationsBulkExportActions from "../../Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportActions";
import OrganizationCliniciansCreateForms from "./OrganizationCliniciansCreateForms";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./OrganizationCliniciansFilters";
import OrganizationCliniciansGridProps from "./OrganizationCliniciansGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function OrganizationCliniciansGrid() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const useQueryFnProps = {
    searchQuery,
    filters: {
      newer_version__isnull: true,
      submitter_organization__slug,
      ...filters,
    },
  };
  const dataGridProps = React.useMemo(
    () => OrganizationCliniciansGridProps(),
    []
  );

  return (
    <ModelTypeContextProvider model_type={MODEL_TYPE_ORGANIZATION_CLINICIAN}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasAdminPrivileges && (
          <>
            <Grid item>
              <OrganizationCliniciansCreateForms />
            </Grid>
            <Grid item>
              <ConfigurationsBulkExportActions
                useQueryFnProps={useQueryFnProps}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchOrganizationClinicians}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </ModelTypeContextProvider>
  );
}
