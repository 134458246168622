import React from "react";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import { MODEL_TYPE_ORGANIZATION_POLICY } from "@constants/static/globals";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationPolicies } from "@redux/actions/organizations/organization-policies";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ConfigurationsBulkExportActions from "../../Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportActions";
import OrganizationPoliciesCreateForms from "./OrganizationPoliciesCreateForms";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./OrganizationPoliciesFilters";
import OrganizationPoliciesGridProps from "./OrganizationPoliciesGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function OrganizationPoliciesGrid() {
  const { organization__slug: processor_organization__slug } = useParams();
  const { data: processor_organization } = useFetchOrganization();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const { is_processor_tpa = false } = processor_organization || {};
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const useQueryFnProps = {
    searchQuery,
    filters: {
      newer_version__isnull: true,
      submitter_organization__slug,
      processor_organization__slug,
      ...filters,
    },
  };
  const dataGridProps = React.useMemo(
    () =>
      OrganizationPoliciesGridProps({
        processor_organization__slug,
        is_processor_tpa,
      }),
    [processor_organization__slug, is_processor_tpa]
  );

  return (
    <ModelTypeContextProvider model_type={MODEL_TYPE_ORGANIZATION_POLICY}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item>
          <OrganizationPoliciesCreateForms />
        </Grid>
        <Grid item>
          <ConfigurationsBulkExportActions useQueryFnProps={useQueryFnProps} />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchOrganizationPolicies}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </ModelTypeContextProvider>
  );
}
