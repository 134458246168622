import React from "react";
import { useModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomTitleComponent from "@components/Custom/CustomLayout/CustomTitleComponent";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import {
  EXPORT_STATUS_PROPS_MAPPER,
  EXPORT_STATUS_TEXT_MAPPER,
} from "@constants/static/export";
import { USE_QUERY_FN_EXPORT_MAPPER } from "@constants/static/routing";
import Grid from "@mui/material/Grid";
import AppConfigurationsTitleComponent from "../../AppConfigurations/AppConfigurationsTitleComponent";
import ConfigurationsBulkExportData from "../ConfigurationsBulkExport/ConfigurationsBulkExportData";
import ConfigurationsBulkExportsGridProps from "./ConfigurationsBulkExportsGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

const ConfigurationBulkExportTitleComponent = ({
  TitleComponent,
  titleComponentProps = {},
  status,
}) => {
  return (
    <CustomGrid alignItems="center" columnSpacing={2}>
      <CustomTitleComponent
        TitleComponent={TitleComponent}
        titleComponentProps={titleComponentProps}
      />
      {Boolean(status) && (
        <CustomStatusTag
          status={status}
          textMapper={EXPORT_STATUS_TEXT_MAPPER}
          propsMapper={EXPORT_STATUS_PROPS_MAPPER}
        />
      )}
    </CustomGrid>
  );
};

export default function ConfigurationsBulkExportsGrid({
  useQueryFnPropsFilters = {},
}) {
  const { as_staff, isStaff } = useStaffContextProvider();
  const { model_type, bundle_type } = useModelTypeContextProvider();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [state, setState] = React.useState({ isOpen: false, object: null });
  const { isOpen, object } = state;
  const { name, status } = object || {};
  const useQueryFnProps = {
    searchQuery,
    filters: { bundle_type, as_staff, ...useQueryFnPropsFilters },
  };
  const handleClickRow = (row) =>
    setState((prev) => ({ ...prev, isOpen: true, object: row }));
  const handleClose = (e) => {
    e?.preventDefault();
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  const TitleComponent = AppConfigurationsTitleComponent({ isStaff });
  const useQueryFn = USE_QUERY_FN_EXPORT_MAPPER[model_type];

  const dataGridProps = React.useMemo(
    () => ConfigurationsBulkExportsGridProps({ handleClickRow, model_type }),
    [model_type]
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFn}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
      <CustomActionDialog
        isOpen={isOpen}
        TitleComponent={ConfigurationBulkExportTitleComponent}
        titleComponentProps={{
          TitleComponent,
          titleComponentProps: { title: name },
          status,
        }}
        handleClose={handleClose}
        handleSubmit={handleClose}
        isOk={true}
        okText="Close"
        okButtonProps={{ color: "info" }}
        maxWidth="sm"
      >
        <ConfigurationsBulkExportData object={object} />
      </CustomActionDialog>
    </div>
  );
}
