import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
} from "@helpers/api";
import { useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/payments/organization_consumed_tokens/";
const queryKeyPlural = "organization_consumed_tokens";

export const useQueryFetchOrganizationConsumedTokens = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "-time_created",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: () =>
      apiWrapper({
        fn: fetchOrganizationConsumedTokens,
        ...apiWrapperProps,
      })({ page, page_size, searchQuery, sortQuery, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchOrganizationConsumedTokens({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
