import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import {
  CLAIM_STATUS_PROCESSING,
  getClaimStatusMapper,
} from "@constants/claims/claim-status";
import { selectorAuthCurrentUserOrganizationRole } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import ClaimStatusAdditionalTags from "./ClaimStatusAdditionalTags";

export default function ClaimStatus({
  status,
  time_deleted = null,
  time_locked = null,
  num_failure_evaluation_results = 0,
  num_alert_evaluation_results = 0,
  rowSpacing = 3,
  columnSpacing = 2,
  useDelay = false,
}) {
  const [displayStatus, setDisplayStatus] = React.useState(status);
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const { propsMapper = {}, textMapper = {} } = React.useMemo(
    () => getClaimStatusMapper({ organizationRole }),
    [organizationRole]
  );

  React.useEffect(() => {
    if (displayStatus !== status) {
      if (useDelay) {
        setDisplayStatus(CLAIM_STATUS_PROCESSING);
        const randomDelay = Math.floor(Math.random() * 2000) + 1000;
        const timer = setTimeout(() => setDisplayStatus(status), randomDelay);
        return () => clearTimeout(timer);
      } else {
        setDisplayStatus(status);
      }
    }
  }, [displayStatus, status, useDelay]);

  const isProcessing = displayStatus === CLAIM_STATUS_PROCESSING;
  const hasCounts =
    num_failure_evaluation_results + num_alert_evaluation_results > 0;

  return (
    <CustomGrid
      alignItems="center"
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
    >
      <CustomStatusTag
        status={displayStatus}
        textMapper={textMapper}
        propsMapper={propsMapper}
      />
      {!isProcessing && hasCounts && (
        <ClaimStatusAdditionalTags
          num_failure_evaluation_results={num_failure_evaluation_results}
          num_alert_evaluation_results={num_alert_evaluation_results}
          time_deleted={time_deleted}
          time_locked={time_locked}
        />
      )}
    </CustomGrid>
  );
}
