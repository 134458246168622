import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE,
  CLAIM_SECTION_ICD_TITLE_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimIcdData from "./ClaimIcdData";
import ClaimIcdsTextData from "./ClaimIcdsText/ClaimIcdsTextData";

export default function ClaimIcdsData({
  object = {},
  type = CLAIM_SECTION_SYMPTOMS,
  useCustomSection = false,
  isMinified = true,
}) {
  const title = CLAIM_SECTION_ICD_TITLE_MAPPER[type];
  const { claim_icds = [], claim_icds_text = "" } = object;
  const numClaimIcds = claim_icds.length;
  const hasClaimIcds = numClaimIcds > 0;

  return (
    <CustomSection
      title={title}
      isMinified={isMinified}
      useCustomSection={useCustomSection}
    >
      <Stack spacing={3}>
        <CustomListItemCollapse primary={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}>
          <Stack spacing={3}>
            {hasClaimIcds ? (
              claim_icds.map((object, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider />}
                  <ClaimIcdData
                    index={index}
                    type={type}
                    object={object}
                    useErrorFieldnames={true}
                    isCreateOrUpdateMode={false}
                  />
                </React.Fragment>
              ))
            ) : (
              <CustomNotProvidedText
                title={CLAIM_SECTION_ICD_ICD_CODE_SUBTITLE}
              />
            )}
          </Stack>
        </CustomListItemCollapse>
        <Divider />
        <ClaimIcdsTextData type={type} claim_icds_text={claim_icds_text} />
      </Stack>
    </CustomSection>
  );
}
