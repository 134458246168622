import React from "react";
import { CLAIM_SECTION_SYMPTOMS } from "@constants/claims/claims";
import ClaimIcdsFormData from "../../../../ClaimIcds/ClaimIcdsFormData";
import {
  RESOLVE_CLAIM_STAGE_CLAIM_ICDS_VARIATION_MAPPER,
  useClaimSubmitterResolveClaimFormContextProvider,
} from "../ClaimSubmitterResolveClaimFormContextProvider";

export default function ClaimIcdsUpdateVariation({
  type = CLAIM_SECTION_SYMPTOMS,
}) {
  const { variation_key } =
    RESOLVE_CLAIM_STAGE_CLAIM_ICDS_VARIATION_MAPPER[type];
  const {
    icd_type,
    [variation_key]: claim_icd_variation = {},
    updateContext = () => {},
  } = useClaimSubmitterResolveClaimFormContextProvider();
  const { index: variationIndex, object } = claim_icd_variation || {};

  const handleUpdate = (payload) =>
    updateContext({
      [variation_key]: {
        index: variationIndex,
        object: { ...object, ...payload },
      },
    });

  return (
    <ClaimIcdsFormData
      formData={object}
      handleUpdate={handleUpdate}
      type={type}
      icd_type={icd_type}
      useMigrateIcds={false}
      useCollapse={false}
    />
  );
}
