import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { getConsumableUploadsHref } from "@constants/static/routing";
import ConsumableCreateForm from "../Consumable/ConsumableForms/ConsumableCreateForm";

export default function ConsumablesCreateForms() {
  const { as_staff } = useStaffContextProvider();
  const uploadHistoryHref = getConsumableUploadsHref({ as_staff });
  return (
    <CustomAddOrUploadWrapper
      label="Add consumables"
      uploadHistoryHref={uploadHistoryHref}
      useUploadForm={true}
    >
      <ConsumableCreateForm />
    </CustomAddOrUploadWrapper>
  );
}
