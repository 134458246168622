import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledToolTip = styled(
  ({
    className,
    color,
    bgColor,
    padding,
    elevation,
    borderRadius,
    fontSize,
    ...props
  }) => <Tooltip {...props} classes={{ popper: className }} />
)(
  ({
    theme,
    color,
    bgColor,
    padding,
    elevation = 8,
    borderRadius = 6,
    fontSize = 12,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bgColor || theme.palette.common.white,
      color: color || theme.palette.text.primary,
      boxShadow: theme.shadows[elevation],
      fontSize,
      borderRadius,
      padding,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: bgColor || theme.palette.common.white, // Set arrow color to match bgColor
    },
  })
);

export default function CustomTooltip({
  title = "",
  children,
  className = "button",
  wrapperProps = {},
  tooltipProps = {},
  useCustomTooltip = true,
}) {
  if (!Boolean(title) || !useCustomTooltip)
    return <div {...wrapperProps}>{children}</div>;

  return (
    <StyledToolTip title={title} {...tooltipProps}>
      <span className={className} {...wrapperProps}>
        {children}
      </span>
    </StyledToolTip>
  );
}
