import React from "react";
import HealthcareOrganizationTag, {
  useHealthcareOrganizationTag,
} from "@components/Authenticated/Configurations/Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationTag";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import { DRUG_NAME_TYPE_MAPPER } from "@constants/avey/drug-names";

export default function DrugNameData({
  object,
  useCustomSection = true,
  isMinified = false,
}) {
  const { isStaff } = useStaffContextProvider();
  const { submitter_organization, name, description, drug_name_type } =
    object || {};
  const drugNameType = DRUG_NAME_TYPE_MAPPER[drug_name_type];
  const useHealthcareOrganization = useHealthcareOrganizationTag({
    isStaff,
    submitter_organization,
  });

  return (
    <CustomSection
      title="Drug name details"
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        {useHealthcareOrganization && (
          <HealthcareOrganizationTag
            submitter_organization={submitter_organization}
          />
        )}
        <CustomLabeledText label="Name" value={name} />
        <CustomLabeledText label="Type" value={drugNameType} />
        <CustomLabeledText label="Description" value={description} />
      </CustomGrid>
    </CustomSection>
  );
}

export const DrugNameDataReviewAndConfirm = ({ object }) => (
  <DrugNameData object={object} isMinified={true} />
);
