import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { getConsumablesHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import HealthcareOrganizationTag from "../../Configurations/HealthcareOrganizationConfigurations/HealthcareOrganizationTag";

export default function ConsumablesGridProps({ as_staff, isStaff }) {
  const hiddenColumns = isStaff ? [] : ["submitter_organization"];
  return {
    columns: [
      {
        flex: 1,
        field: "submitter_organization",
        headerName: "Healthcare provider",
        renderCell: ({ value }) => (
          <HealthcareOrganizationTag
            submitter_organization={value}
            useLabel={false}
          />
        ),
      },
      {
        flex: 1,
        field: "name",
        headerName: "Name",
      },
      {
        flex: 1,
        field: "description",
        headerName: "Description",
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      getConsumablesHref({ consumable__id: id, as_staff }),
  };
}
