import React from "react";
import ClaimExports from "@components/Authenticated/ClaimSubmissions/ClaimExports/ClaimExports";
import ClaimSubmitterCreateClaimForm from "@components/Authenticated/ClaimSubmissions/Claims/Claim/Claim/ClaimSubmitterForms/ClaimSubmitterCreateClaimForm";
import ClaimDemo from "@components/Authenticated/ClaimSubmissions/Claims/ClaimDemo";
import ClaimSubmission from "@components/Authenticated/ClaimSubmissions/ClaimSubmissions/ClaimSubmission";
import ClaimSubmissionUploads from "@components/Authenticated/ClaimSubmissions/ClaimSubmissionUploads/ClaimSubmissionUploads";
import {
  AVEY_COWRITER_HREF,
  CLAIM_EXPORTS_HREF,
  CLAIM_SUBMISSION_UPLOADS_HREF,
  CLAIMS_HREF,
  NEW_CLAIM_HREF,
} from "@constants/static/routing";
import { Navigate, Route, Routes } from "react-router-dom";
import ClaimsRoutes from "./claims-routes";

export default function ClaimSubmissionRoutes() {
  return (
    <Routes>
      <Route path="" Component={ClaimSubmission} />
      <Route path={AVEY_COWRITER_HREF} Component={ClaimDemo} />
      <Route
        path={`${CLAIM_SUBMISSION_UPLOADS_HREF}/*`}
        Component={ClaimSubmissionUploads}
      />
      <Route path={`${CLAIM_EXPORTS_HREF}/*`} Component={ClaimExports} />
      <Route path={NEW_CLAIM_HREF} Component={ClaimSubmitterCreateClaimForm} />
      <Route path={`${CLAIMS_HREF}/*`} Component={ClaimsRoutes} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
