import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomSection from "@components/Custom/CustomSection";
import IdentityTypeCountriesData from "./IdentityTypeCountriesData";

export default function IdentityTypeData({
  object,
  useCustomSection = true,
  isMinified = false,
}) {
  const { code, name, description, countries } = object || {};
  const hasCode = Boolean(code);

  return (
    <CustomSection
      title="Identity type details"
      useCustomSection={useCustomSection}
      isMinified={isMinified}
    >
      <CustomGrid>
        {hasCode && <CustomLabeledText label="Code" value={code} />}
        <CustomLabeledText label="Name" value={name} />
        <CustomLabeledText label="Description" value={description} />
        <CustomLabeledText useChildren={true} label="Countries">
          <IdentityTypeCountriesData countries={countries} />
        </CustomLabeledText>
      </CustomGrid>
    </CustomSection>
  );
}

export const IdentityTypeDataReviewAndConfirm = ({ object }) => (
  <IdentityTypeData object={object} isMinified={true} />
);
