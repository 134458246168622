import React from "react";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import {
  EXPORT_STATUS_PROPS_MAPPER,
  EXPORT_STATUS_TEXT_MAPPER,
} from "@constants/static/export";
import { LABEL_EXPORT_MAPPER } from "@constants/static/routing";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimeSince } from "@helpers/parse/time";

export default function ConfigurationsBulkExportsGridProps({
  handleClickRow = () => {},
  model_type,
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "name",
        headerName: "Name",
      },
      {
        flex: 1,
        field: "requesting_user__first_name",
        headerName: "Requested by",
        valueGetter: ({ row }) => row?.requesting_user?.full_name,
      },
      {
        flex: 1,
        field: "num_instances",
        headerName: `Exported ${LABEL_EXPORT_MAPPER[model_type] || "objects"}`,
        valueGetter: ({ value }) => getIntOrFloat(value),
      },
      {
        flex: 1,
        field: "file",
        sortable: false,
        headerName: "File",
        renderCell: ({ value }) =>
          Boolean(value) ? (
            <CustomExternalLink
              href={value}
              label="Download file"
              wrapperProps={{ download: true }}
              isTypography={true}
            />
          ) : (
            "-"
          ),
      },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        renderCell: ({ value }) => (
          <CustomStatusTag
            status={value}
            textMapper={EXPORT_STATUS_TEXT_MAPPER}
            propsMapper={EXPORT_STATUS_PROPS_MAPPER}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last Updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    disableRowSelectionOnClick: true,
    onRowClick: ({ row }) => handleClickRow(row),
  };
}
