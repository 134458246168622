import React from "react";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomThickDivider from "@components/Custom/CustomThickDivider";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { emptyMedicationActiveIngredientFormData } from "@constants/avey/medications";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import MedicationActiveIngredientFormData from "./MedicationActiveIngredientFormData";

export default function MedicationActiveIngredientsFormData({
  formData = [],
  setFormData = () => {},
}) {
  const hasNoFormData = formData.length === 0;
  const handleAddMedicationActiveIngredient = React.useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      medication_active_ingredients: [
        ...prev.medication_active_ingredients,
        emptyMedicationActiveIngredientFormData,
      ],
    }));
  }, [setFormData]);

  const handleChangeMedicationActiveIngredient = React.useCallback(
    ({ index, payload }) => {
      setFormData((prev) => ({
        ...prev,
        medication_active_ingredients: prev.medication_active_ingredients.map(
          (medication_active_ingredient, position) =>
            index === position ? payload : medication_active_ingredient
        ),
      }));
    },
    [setFormData]
  );

  const handleRemoveMedicationActiveIngredient = React.useCallback(
    ({ index }) => {
      setFormData((prev) => ({
        ...prev,
        medication_active_ingredients:
          prev.medication_active_ingredients.filter(
            (_, position) => index !== position
          ),
      }));
    },
    [setFormData]
  );

  return (
    <Stack spacing={3}>
      {hasNoFormData ? (
        <CustomNotProvidedText title="Active ingredients" />
      ) : (
        <>
          {formData.map((medication_active_ingredient, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <CustomThickDivider />}
              <MedicationActiveIngredientFormData
                index={index}
                medication_active_ingredient={medication_active_ingredient}
                handleChangeMedicationActiveIngredient={
                  handleChangeMedicationActiveIngredient
                }
                handleRemoveMedicationActiveIngredient={
                  handleRemoveMedicationActiveIngredient
                }
              />
            </React.Fragment>
          ))}
          <Divider />
        </>
      )}
      <CustomTypographyButton
        value="+ Add active ingredient"
        onClick={handleAddMedicationActiveIngredient}
      />
    </Stack>
  );
}
