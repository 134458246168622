import React from "react";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import { useQueryFetchIcds } from "@redux/actions/avey/icds";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getIcdOptionLabel,
  isIcdOptionEqualToValue,
  renderIcdOption,
} from "./IcdAutocompleteComponents";

export default function IcdAutocomplete({
  label = "ICD code",
  error = false,
  value,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
  icd_type = ICD_TYPE_10_CM,
}) {
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
      icd_type,
    },
    useInfiniteWrapper: true,
    sortQuery: "code",
  };

  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        disableClearable: true,
        renderOption: (props, option) => renderIcdOption(props, option),
        ...autocompleteProps,
      }}
      useQueryFn={useQueryFetchIcds}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getIcdOptionLabel}
      isOptionEqualToValue={isIcdOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
