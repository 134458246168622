import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchConsumableHistory } from "@redux/actions/avey/consumables";
import { useParams } from "react-router-dom";
import ConsumableData from "./ConsumableData";
import { ConsumableHeaderTitle } from "./ConsumableHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { name } = object;
  return (
    <Typography variant="body2" className="ellipsis-1">
      {name}
    </Typography>
  );
};

export default function ConsumableVersionHistory() {
  const { as_staff } = useStaffContextProvider();
  const { consumable__id: id } = useParams();
  const useQueryFnProps = { id, as_staff };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchConsumableHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={ConsumableData}
      VersionHistoryObjectTitleComponent={ConsumableHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
