import React from "react";
import {
  ConfigurationsBulkExportFormDialog,
  useConfigurationsBulkExportForm,
} from "@components/Authenticated/Configurations/Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportForm";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import ClaimSubmissionsAutocomplete from "@components/Custom/CustomAutocomplete/ClaimSubmissionAutocomplete/ClaimSubmissionsAutocomplete";
import { CustomFilterOptions } from "@components/Custom/CustomFilters";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { CLAIM_STATUS_ALL } from "@constants/claims/claim-status";
import MenuItem from "@mui/material/MenuItem";
import { exportClaims } from "@redux/actions/claims/claims";
import {
  selectorAuthClaimMode,
  selectorAuthCurrentUserOrganizationCurrency,
  selectorAuthCurrentUserOrganizationRole,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  describeFilters,
  formatFilters,
  getFilterOptions,
  getUseQueryFnPropsClaims,
  initialFilterState,
} from "../../Claims/Claims/ClaimsFilters";
import ClaimExportUseReportFormData, {
  claimExportUseReportInitialFormData,
} from "./ClaimExportUseReportFormData";

const initialAdditionalActiveFilters = {
  searchQuery: "",
  claim_submissions: [],
};

const ClaimSubmissionAdditionalFilters = ({ formData, setFormData }) => {
  const { searchQuery = "", claim_submissions = [] } = formData;

  const handleChangeClaimSubmissions = (payload) =>
    setFormData((prev) => ({
      ...prev,
      claim_submissions: payload,
    }));

  const handleChangeSearchQuery = (e) =>
    setFormData((prev) => ({
      ...prev,
      searchQuery: e.target.value,
    }));

  return (
    <>
      <CustomListItemCollapse primary="Claim submissions">
        <ClaimSubmissionsAutocomplete
          label=""
          value={claim_submissions}
          handleChange={handleChangeClaimSubmissions}
        />
      </CustomListItemCollapse>
      <CustomListItemCollapse primary="Search term">
        <CustomOutlinedTextField
          value={searchQuery}
          onChange={handleChangeSearchQuery}
          placeholder="Search for claims"
        />
      </CustomListItemCollapse>
    </>
  );
};

export default function ClaimExportClaimSubmissionsForm() {
  const { organization__slug } = useParams();
  const [isOpenFilterDialog, setIsOpenFilterDialog] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState(initialFilterState);
  const [additionalActiveFilters, setAdditionalActiveFilters] = React.useState(
    initialAdditionalActiveFilters
  );
  const currency = useSelector(selectorAuthCurrentUserOrganizationCurrency);
  const claimMode = useSelector(selectorAuthClaimMode);
  const organizationRole = useSelector(selectorAuthCurrentUserOrganizationRole);
  const filterOptions = React.useMemo(
    () =>
      getFilterOptions({
        claimMode,
        organizationRole,
        statusTab: CLAIM_STATUS_ALL,
        currency,
      }),
    [claimMode, organizationRole, currency]
  );

  const handleOpenFilterDialog = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpenFilterDialog(true);
  };

  const handleCloseFilterDialog = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpenFilterDialog(false);
  };

  const { searchQuery, claim_submissions } = additionalActiveFilters;

  const useQueryFnPropsClaims = getUseQueryFnPropsClaims({
    organizationRole,
    organization__slug,
    searchQuery,
    filters: formatFilters(activeFilters),
    claim_submissions,
  });

  const {
    title,
    summary,
    dialogProps,
    isOpen,
    isProcessing,
    hasNoData,
    formData,
    setFormData,
    handleClose,
    handleCheckData,
  } = useConfigurationsBulkExportForm({
    label: "claims",
    useQueryFnProps: useQueryFnPropsClaims,
    parentFormData: claimExportUseReportInitialFormData,
    submitFn: exportClaims,
    submitFnFinally: handleCloseFilterDialog,
  });

  React.useEffect(() => {
    if (isOpenFilterDialog) {
      setActiveFilters(initialFilterState);
      setAdditionalActiveFilters(initialAdditionalActiveFilters);
    }
  }, [isOpenFilterDialog]);

  return (
    <>
      <MenuItem onClick={handleOpenFilterDialog}>{"Export claims"}</MenuItem>
      <CustomActionDialog
        isOpen={isOpenFilterDialog && !isOpen}
        handleClose={handleCloseFilterDialog}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Export claims" }}
        yesText="Filter claims"
        handleSubmit={handleCheckData}
      >
        <ClaimSubmissionAdditionalFilters
          formData={additionalActiveFilters}
          setFormData={setAdditionalActiveFilters}
        />
        <CustomFilterOptions
          activeFilters={activeFilters}
          handleChangeActiveFilters={setActiveFilters}
          filterOptions={filterOptions}
        />
      </CustomActionDialog>
      <ConfigurationsBulkExportFormDialog
        title={title}
        summary={summary}
        dialogProps={dialogProps}
        isOpen={isOpen}
        isProcessing={isProcessing}
        hasNoData={hasNoData}
        formData={formData}
        setFormData={setFormData}
        handleClose={handleClose}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        describeFilters={describeFilters}
        ParentFormDataComponent={ClaimExportUseReportFormData}
        useQueryFnProps={useQueryFnPropsClaims}
      />
    </>
  );
}
