import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { getOrganizationCliniciansHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import OrganizationClinicianSpecialitiesData from "../OrganizationClinician/OrganizationClinicianData/OrganizationClinicianSpecialitiesData";

export default function OrganizationCliniciansGridProps() {
  return {
    columns: [
      {
        flex: 1,
        field: "clinician_id",
        headerName: "Clinician ID",
      },
      {
        flex: 2,
        field: "first_name",
        headerName: "Name",
        valueGetter: (params) =>
          `${params?.row?.first_name} ${params?.row?.last_name}`.trim(),
      },
      {
        flex: 2,
        sortable: false,
        field: "specialities",
        headerName: "Specialities",
        renderCell: ({ value }) => (
          <OrganizationClinicianSpecialitiesData specialities={value} />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      getOrganizationCliniciansHref({ organization_clinician__id: id }),
  };
}
