import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { ICD_TYPE_10_CM } from "@constants/avey/icds";
import { formatClaimFormData } from "@constants/claims/claims";
import { getClaimHref } from "@constants/static/routing";
import { useURLSearch } from "@helpers/api/getQueryParams";
import useFetchClaimSubmission from "@helpers/hooks/useFetchObjects/useFetchClaimSubmission";
import Stack from "@mui/material/Stack";
import { submitClaim } from "@redux/actions/claims/claims";
import { TickIcon } from "@rimads/assets";
import { AVEY_WHITE } from "@styles/theme";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ClaimBody from "../../ClaimBody";
import { ClaimHeaderTitle } from "../../ClaimHeader";
import ClaimReviewAndConfirm from "../../ClaimReviewAndConfirm";
import { ClaimContext, useClaimContext } from "../../context";

const isCreateOrUpdateMode = true;

export default function ClaimSubmitterUpdateClaimForm({
  object,
  icd_type = ICD_TYPE_10_CM,
  dialogProps = {},
  buttonProps = {},
  useIcdsOnly = false,
  hideDialogWhenSummary = false,
}) {
  const navigate = useNavigate();
  const { organization__slug, claim__id } = useParams();
  const { data: claim_submission, isLoading: isLoadingClaimSubmission } =
    useFetchClaimSubmission();
  const urlSearch = useURLSearch();
  const dialogRef = React.useRef();
  const { claim_submission__id } = useParams();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;
  const { processor_organization } = claim_submission || {};
  const { meta_mds_cols = [] } = processor_organization || {};

  const isOpenMainDialog = hideDialogWhenSummary
    ? isOpen && !isOpenSummaryDialog
    : isOpen;

  React.useEffect(() => {
    setIsOpen(false);
    setIsProcessing(false);
    setAlertState(initialAlertState);
    setSummaryState(initialSummaryState);
  }, [claim__id]);

  React.useEffect(() => {
    if (isOpen) {
      setFormData(object);
      setAlertState(initialAlertState);
      dialogRef.current &&
        dialogRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isOpen, object]);

  const handleIsOpen = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e.preventDefault();
    const newFormData = new FormData(e.target);
    const submitter_user_note = newFormData.get("submitter_user_note") || "";
    const formattedFormData = formatClaimFormData({
      formData,
      isCheck,
      claim_submission__id,
      processor_organization__slug: organization__slug,
      submitter_user_note,
    });
    setIsProcessing(true);
    submitClaim(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: "Claim successfully resubmitted",
          });
          navigate(
            getClaimHref({
              organization__slug,
              claim_submission__id,
              claim__id: instance.id,
              urlSearch,
            })
          );
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) =>
        setAlertState({ isOpen: true, errorStr: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  if (isLoadingClaimSubmission || !Boolean(claim_submission)) return null;

  return (
    <ClaimContext.Provider
      value={{
        claim: formData,
        setFormData,
        isCreateOrUpdateMode,
        meta_mds_cols,
        icd_type,
      }}
    >
      <CustomButton
        icon="EditIcon"
        label="Edit claim"
        onClick={handleIsOpen}
        style={{ minWidth: 115 }}
        {...buttonProps}
      />
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        fullScreen={true}
        useCustomCloseIcon={true}
        disableEscapeKeyDown={true}
        dialogContentProps={{ ref: dialogRef }}
        TitleComponent={ClaimHeaderTitle}
        titleComponentProps={{ object }}
        yesText="Update claim"
        yesButtonProps={{ startIcon: <TickIcon primary_fill={AVEY_WHITE} /> }}
        {...dialogProps}
      >
        <ClaimBody useIcdsOnly={useIcdsOnly} />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={ClaimSubmitterUpdateClaimFormContent}
        summaryDialogProps={{ maxWidth: "lg", isOk: false }}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </ClaimContext.Provider>
  );
}

const ClaimSubmitterUpdateClaimFormContent = () => {
  const { claim = {} } = useClaimContext();
  return (
    <Stack spacing={3}>
      <ClaimReviewAndConfirm claim={claim} />
      <CustomOutlinedTextField
        multiline
        minRows={3}
        defaultValue=""
        autoComplete="off"
        name="submitter_user_note"
        label="Additional notes"
        placeholder="Specify a reason for your changes"
      />
    </Stack>
  );
};
