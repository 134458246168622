import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import CustomButton from "@components/Custom/CustomButton";
import { formatOrganizationPatientFormData } from "@constants/organizations/organization-patients";
import { getOrganizationPatientsHref } from "@constants/static/routing";
import {
  invalidateOrganizationPatientsTSQ,
  updateOrganizationPatient,
} from "@redux/actions/organizations/organization-patients";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationPatientData from "../OrganizationPatientData";
import OrganizationPatientFormData from "../OrganizationPatientFormData";

export default function OrganizationPatientUpdateForm({
  object,
  isSquare = false,
  withNavigate = true,
  isFullScreen = false,
  handleSelectPatient = () => {},
}) {
  const navigate = useNavigate();
  const { organization__slug: processor_organization__slug } = useParams();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(object);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatOrganizationPatientFormData({
      isCheck,
      processor_organization__slug,
      formData,
    });
    updateOrganizationPatient(formattedFormData)
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: "Patient successfully updated",
          });
          invalidateOrganizationPatientsTSQ();
          if (withNavigate) {
            navigate(
              getOrganizationPatientsHref({
                organization__slug: processor_organization__slug,
                organization_patient__id: instance.id,
              })
            );
          } else {
            handleSelectPatient(instance);
            setIsOpen(false);
          }
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    if (isOpen) {
      setFormData(object);
      setAlertState(initialAlertState);
    }
    setSummaryState(initialSummaryState);
  }, [isOpen, object]);

  const dialogProps = isFullScreen
    ? { fullScreen: true }
    : { fullScreen: false, maxWidth: "lg" };

  return (
    <>
      <CustomButton
        isSquare={isSquare}
        useIconAsLabel={isSquare}
        variant={isSquare ? "outlined" : "contained"}
        label="Edit patient"
        icon="EditIcon"
        onClick={handleIsOpen}
      />
      <CustomActionDialog
        useCustomCloseIcon={true}
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Edit patient", fullScreen: true }}
        {...dialogProps}
      >
        <OrganizationPatientFormData
          formData={formData}
          setFormData={setFormData}
        />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={OrganizationPatientData}
        contentComponentProps={{
          object: formData,
          isMinified: true,
          useCustomSection: true,
        }}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
