import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedResponse,
  PayloadError,
} from "@helpers/api";
import { invalidateTSQ, useQueryWrapper } from "@helpers/tanstack";

const endpointUrl = "/users/users/";
const authEndpointUrl = "/auth/users/";
const djoserEndpointUrl = "/users/djoser_users/";
const queryKeyPlural = "users";

export const useQueryFetchUsers = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "first_name",
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      { page, page_size, searchQuery, sortQuery, filters },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchUsers,
        ...apiWrapperProps,
      })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
      }),
    staleTime: 300_000,
    cacheTime: 60_000,
    ...reactQueryProps,
  });
};

async function fetchUsers({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "first_name",
  filters = {},
}) {
  try {
    const queryParams = getQueryParams({
      page,
      page_size,
      search: searchQuery,
      ordering: sortQuery,
      ...filters,
    });
    const response = await AveyBloomApi().get(`${endpointUrl}?${queryParams}`);
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export function invalidateUsersTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) => queryKey[0] === queryKeyPlural,
  });
}

export async function inviteUser(data) {
  try {
    const response = await AveyBloomApi().post(authEndpointUrl, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data });
  }
}

export async function resendInvitation(id) {
  try {
    const response = await AveyBloomApi().post(
      `${djoserEndpointUrl}${id}/resend_activation/`,
      {}
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason });
  }
}

export async function deactivateUser(data) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/deactivation/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason });
  }
}
