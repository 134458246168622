import React from "react";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { getClaimSubmissionUploadHref } from "@constants/static/routing";
import { useParams } from "react-router-dom";
import ClaimSubmissionUploadCreateForm from "../../ClaimSubmissionUploads/ClaimSubmissionUpload/ClaimSubmissionUploadForms/ClaimSubmissionUploadCreateForm";
import ClaimSubmissionUploadReprocessForm from "../../ClaimSubmissionUploads/ClaimSubmissionUpload/ClaimSubmissionUploadForms/ClaimSubmissionUploadReprocessForm";
import ClaimSubmitterCreateClaimFormLink from "../Claim/Claim/ClaimSubmitterForms/ClaimSubmitterCreateClaimForm/ClaimSubmitterCreateClaimFormLink";

export default function ClaimsCreateForms({ useQueryFnPropsClaims = {} }) {
  const { claim_submission__id, organization__slug } = useParams();
  const uploadHistoryHref = getClaimSubmissionUploadHref({
    claim_submission__id,
    organization__slug,
  });
  return (
    <CustomAddOrUploadWrapper
      label="Add claims"
      uploadHistoryHref={uploadHistoryHref}
      useUploadForm={false}
    >
      <ClaimSubmitterCreateClaimFormLink isMenuItem={true} />
      <ClaimSubmissionUploadCreateForm isMenuItem={true} />
      <ClaimSubmissionUploadReprocessForm
        useQueryFnPropsClaims={useQueryFnPropsClaims}
      />
    </CustomAddOrUploadWrapper>
  );
}
