import React from "react";
import Typography from "@mui/material/Typography";
import CustomTooltip from "@components/Custom/CustomTooltip";
import CustomTypographyButton from "@components/Custom/CustomTypographyButton";
import { TypeAnimation } from "react-type-animation";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";

export default function ClaimDemoSummarySharedContent({
  name = "",
  object = {
    summary: "",
    isLoading: false,
    isTypeAnimation: true,
  },
  handleUpdateSummary = () => {},
}) {
  const { summary, isTypeAnimation } = object;
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleBlur = (e) => {
    e?.preventDefault();
    const { value } = e.target;
    handleUpdateSummary({ type: name, value });
    handleClose();
  };

  const hasSummary = Boolean(summary);

  if (isOpen)
    return (
      <CustomOutlinedTextField
        label="Summary"
        defaultValue={summary}
        autoFocus
        name="summary"
        onBlur={handleBlur}
        minRows={3}
        multiline
      />
    );

  if (hasSummary)
    return (
      <div>
        <CustomTooltip
          title="Edit summary"
          tooltipProps={{ followCursor: true }}
        >
          <Typography
            variant="body2"
            className="button opacityOnHover-50"
            onClick={handleOpen}
          >
            {isTypeAnimation ? (
              <TypeAnimation
                key={summary}
                sequence={[summary]}
                wrapper="span"
                speed={70}
                repeat={false}
                cursor={false}
              />
            ) : (
              summary
            )}
          </Typography>
        </CustomTooltip>
      </div>
    );

  return (
    <CustomTypographyButton
      value="+ Add summary"
      display="inline"
      onClick={handleOpen}
    />
  );
}
