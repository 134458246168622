import React from "react";
import { RadioButtonChecked } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { AVEY_BLACK, AVEY_GREY_F2 } from "@styles/theme";

const color1 = AVEY_BLACK;
const color2 = AVEY_GREY_F2;

const QontoConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color1,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color1,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: color2,
    borderTopWidth: 3,
    borderRadius: 1,
  },
});

const QontoStepIconRoot = styled("div")({
  color: color2,
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: color1,
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: { color: color1 },
    },
  ],
});

function QontoStepIcon(props) {
  const { active, completed, className, ...otherProps } = props;
  const { activeStep } = otherProps || {};

  const isSuperActive =
    activeStep !== undefined && active && Math.floor(activeStep) !== activeStep;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : isSuperActive ? (
        <RadioButtonChecked fontSize="small" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default function CustomStepper({
  steps = [],
  activeStep = 0,
  wrapperProps = {},
}) {
  return (
    <Box {...wrapperProps}>
      <Stepper
        activeStep={Math.floor(activeStep)}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              StepIconProps={{ activeStep }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
