import { EES_ENABLED } from "@constants/static/globals";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
  PayloadError,
  ServerApi,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const endpointUrl = "/avey/identity_types/";
const eesEndpointUrl = "/api/ees/identity-types/";
const queryKeySingular = "identity_type";
const queryKeyPlural = "identity_types";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchIdentityTypes = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  eesFilters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
  useEesIfEnabled = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      {
        page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        eesFilters,
        useEesIfEnabled,
      },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchIdentityTypes, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        eesFilters,
        useEesIfEnabled,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchIdentityTypes({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  eesFilters = {},
  useEesIfEnabled = true,
}) {
  try {
    if (EES_ENABLED && useEesIfEnabled) {
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        filters: { ...filters, ...eesFilters },
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...filters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchIdentityType = ({
  identity_type__id = "",
  as_staff = false,
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: identity_type__id, as_staff }],
    queryFn: () =>
      apiWrapper({ fn: fetchIdentityType, ...apiWrapperProps })({
        identity_type__id,
        as_staff,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchIdentityType({ identity_type__id = "", as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${identity_type__id}/?${queryParams}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createIdentityType({ data }) {
  try {
    const response = await AveyBloomApi().post(endpointUrl, data);
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateIdentityType({ data }) {
  try {
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function deleteIdentityType({ data }) {
  try {
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/activate/`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateIdentityTypeObjectTSQ({ object }) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1].id === object.id,
    object,
  });
}

export function invalidateIdentityTypesTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingular, queryKeyPlural, queryKeySingularHistory].includes(
        queryKey[0]
      ),
  });
}

export const useQueryFetchIdentityTypeHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchIdentityTypeHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchIdentityTypeHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
