import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getUnitsHref } from "@constants/static/routing";
import { useQueryFetchUnit } from "@redux/actions/avey/units";
import { Navigate, useParams } from "react-router-dom";
import UnitData from "./UnitData";
import UnitHeader from "./UnitHeader";

export default function Unit() {
  const { unit__id } = useParams();
  const { as_staff } = useStaffContextProvider();
  const { isLoading, data } = useQueryFetchUnit({ unit__id, as_staff });

  const hasData = Boolean(data);

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (Boolean(latest_version_id) && latest_version_id !== unit__id) {
    const latestVersionHref = getUnitsHref({
      unit__id: latest_version_id,
      as_staff,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <>
      <UnitHeader object={data} />
      <CustomPage>
        <UnitData object={data} />
      </CustomPage>
    </>
  );
}
