import React from "react";
import CustomChip from "@components/Custom/CustomChip";
import {
  PAYMENT_PLAN_TYPE_DESCRIPTION_MAPPER,
  PAYMENT_PLAN_TYPE_MAPPER,
  PAYMENT_PLAN_TYPE_TYPOGRAPHY_BG_COLOR_MAPPER,
} from "@constants/payments/payments";

export default function OrganizationPaymentCyclePaymentPlanType({
  payment_plan_type,
  withTooltip = false,
}) {
  const description = withTooltip
    ? PAYMENT_PLAN_TYPE_DESCRIPTION_MAPPER[payment_plan_type]
    : "";
  const paymentPlanType = PAYMENT_PLAN_TYPE_MAPPER[payment_plan_type];
  const color =
    PAYMENT_PLAN_TYPE_TYPOGRAPHY_BG_COLOR_MAPPER[payment_plan_type] || "";

  return (
    <CustomChip
      name={paymentPlanType}
      title={description}
      color={color}
      placement="bottom"
    />
  );
}
