import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import {
  emptyIdentityTypeFormData,
  formatIdentityTypeFormData,
} from "@constants/avey/identity-types";
import MenuItem from "@mui/material/MenuItem";
import {
  createIdentityType,
  invalidateIdentityTypesTSQ,
} from "@redux/actions/avey/identity-types";
import { enqueueSnackbar } from "notistack";
import { IdentityTypeDataReviewAndConfirm } from "../IdentityType/IdentityTypeData";
import IdentityTypeFormData from "./IdentityTypeFormData";

export default function IdentityTypeCreateForm({
  withCustomButton = true,
  parentIsOpen = false,
  parentSetIsOpen = undefined,
  handleSelect = undefined,
}) {
  const [isOpen, setIsOpen] = React.useState(parentIsOpen);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyIdentityTypeFormData);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;
  const isOpenMainDialog = isOpen && !isOpenSummaryDialog && !isOpenAlert;

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    Boolean(parentSetIsOpen) ? parentSetIsOpen(!isOpen) : setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatIdentityTypeFormData({ isCheck, formData });
    createIdentityType({ data: formattedFormData })
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: "Identity type successfully created",
          });
          invalidateIdentityTypesTSQ();
          Boolean(parentSetIsOpen) ? parentSetIsOpen(false) : setIsOpen(false);
          Boolean(handleSelect) && handleSelect(instance);
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => setIsOpen(parentIsOpen), [parentIsOpen]);

  React.useEffect(() => {
    isOpen && setFormData(emptyIdentityTypeFormData);
    setAlertState(initialAlertState);
    setSummaryState(initialSummaryState);
  }, [isOpen]);

  return (
    <>
      {withCustomButton && (
        <MenuItem onClick={handleIsOpen}>{"Add an identity type"}</MenuItem>
      )}
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        titleComponentProps={{ title: "New identity type" }}
      >
        <IdentityTypeFormData formData={formData} setFormData={setFormData} />
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={IdentityTypeDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
      />
    </>
  );
}
