import React from "react";
import CustomDeactivateObject from "@components/Custom/CustomDeactivateObject";
import {
  deleteDrugName,
  invalidateDrugNamesTSQ,
  updateDrugNameObjectTSQ,
} from "@redux/actions/avey/drug-names";

export default function DrugNameActivateForm({ object }) {
  const handleDeleteFn = ({ payload }) => {
    invalidateDrugNamesTSQ();
    updateDrugNameObjectTSQ({ object: payload });
  };
  return (
    <CustomDeactivateObject
      object={object}
      label="drug name"
      deleteFn={deleteDrugName}
      handleDeleteFn={handleDeleteFn}
    />
  );
}
