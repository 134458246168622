import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { CustomAlertDialog } from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import CustomButton from "@components/Custom/CustomButton";
import {
  CLAIM_STATUS_APPROVED,
  CLAIM_STATUS_PROCESSOR_ACTION_MAPPER,
  CLAIM_STATUS_PROCESSOR_ACTION_OPTIONS,
} from "@constants/claims/claim-status";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { LineArrowRightIcon } from "@rimads/assets";
import { AVEY_BLACK } from "@styles/theme";
import ClaimStatus from "../../ClaimStatus";
import ClaimRedirectToLatestVersion from "../../ClaimRedirectToLatestVersion";
import { useClaimProcessorActionsContext } from "./ClaimProcessorUpdateClaimFormContext";
import ClaimProcessorUpdateClaimFormData from "./ClaimProcessorUpdateClaimFormData";

export function ClaimProcessorUpdateClaimFormButtons({
  useCustomButtons = true,
}) {
  const { handleChangeStatus } = useClaimProcessorActionsContext();

  if (useCustomButtons)
    return (
      <Stack spacing={2} direction="row" alignItems="center">
        {CLAIM_STATUS_PROCESSOR_ACTION_OPTIONS.map(({ key, value }) => (
          <CustomButton
            key={key}
            onClick={() => handleChangeStatus(key)}
            style={{ minWidth: 115 }}
            {...value}
          />
        ))}
      </Stack>
    );

  return CLAIM_STATUS_PROCESSOR_ACTION_OPTIONS.map(({ key, value }) => (
    <MenuItem key={key} onClick={() => handleChangeStatus(key)}>
      {value?.label}
    </MenuItem>
  ));
}

export function ClaimProcessorUpdateClaimForm({ status: currentStatus }) {
  const {
    isOpen,
    isProcessing,
    latestVersionId,
    status,
    finalStatus,
    formData,
    alertState,
    handleClose,
    setAlertState,
    handleChangeClaimProcedure,
    handleChangeClaimMedication,
    handleChangeNote,
    handleSubmit,
  } = useClaimProcessorActionsContext();

  const showBillables = status === CLAIM_STATUS_APPROVED;
  const memoizedDialogProps = React.useMemo(() => {
    const { label, color, variant } =
      CLAIM_STATUS_PROCESSOR_ACTION_MAPPER[status] || {};
    return {
      yesText: `${label} claim`,
      yesButtonProps: { color, variant },
    };
  }, [status]);

  return (
    <>
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        disableEscapeKeyDown={true}
        maxWidth={showBillables ? "sm" : "xs"}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        titleComponentProps={{ title: "Review & Confirm" }}
        subtitleComponentProps={{
          title: "This will change the status of the claim.",
        }}
        {...memoizedDialogProps}
      >
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <ClaimStatus status={currentStatus} />
          <LineArrowRightIcon primary_fill={AVEY_BLACK} />
          <ClaimStatus status={finalStatus} />
        </Stack>
        <ClaimProcessorUpdateClaimFormData
          showBillables={showBillables}
          formData={formData}
          handleChangeClaimProcedure={handleChangeClaimProcedure}
          handleChangeClaimMedication={handleChangeClaimMedication}
          handleChangeNote={handleChangeNote}
        />
      </CustomActionDialog>
      <ClaimRedirectToLatestVersion latestVersionId={latestVersionId} />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
