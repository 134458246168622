import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomListAvatarItem from "@components/Custom/CustomListAvatarItem";

export const useHealthcareOrganizationTag = ({
  isStaff,
  submitter_organization,
}) => isStaff && Boolean(submitter_organization);

export default function HealthcareOrganizationTag({
  useLabel = true,
  submitter_organization,
  imageProps = {},
  textProps = {},
}) {
  if (!submitter_organization) return "-";
  return (
    <CustomLabeledText
      label={useLabel && "Healthcare provider"}
      useChildren={true}
    >
      <CustomListAvatarItem
        imageProps={{
          src: submitter_organization?.image,
          variant: "square",
          ...imageProps,
        }}
        textProps={{
          children: submitter_organization?.name,
          className: "ellipsis-1",
          ...textProps,
        }}
      />
    </CustomLabeledText>
  );
}
