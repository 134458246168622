import { getClaimSubmissionRevelantOrganization } from "@constants/claims/claim-submissions";
import { updateClaimSubmissionObjectTSQ } from "@redux/actions/claims/claim-submissions";
import { updateClaimObjectTSQ } from "@redux/actions/claims/claims";
import { updateOrganizationTSQ } from "@redux/actions/organizations/organizations";

export default function handleWsClaim({ object = {}, organizationRole }) {
  const { claim_submission } = object;
  const organization = getClaimSubmissionRevelantOrganization({
    object: claim_submission,
    organizationRole,
  });
  updateOrganizationTSQ({ object: organization });
  updateClaimSubmissionObjectTSQ({ object: claim_submission });
  updateClaimObjectTSQ({ object });
  return null;
}
