import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { getSpecialitiesHref } from "@constants/static/routing";
import { parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";

export default function SpecialitiesGridProps({ as_staff }) {
  return {
    columns: [
      {
        flex: 1,
        field: "name",
        headerName: "Name",
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value}
          </Typography>
        ),
      },
      {
        flex: 2,
        field: "description",
        headerName: "Description",
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value}
          </Typography>
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      getSpecialitiesHref({ speciality__id: id, as_staff }),
  };
}
