import React from "react";
import OrganizationClinicianData from "@components/Authenticated/Configurations/OrganizationClinicians/OrganizationClinician/OrganizationClinicianData";
import OrganizationClinicianAutocomplete from "@components/Custom/CustomAutocomplete/OrganizationClinicianAutocomplete";
import CustomButton from "@components/Custom/CustomButton";
import Stack from "@mui/material/Stack";
import { useClaimSubmitterResolveClaimFormContextProvider } from "../ClaimSubmitterResolveClaimFormContextProvider";
import {
  ClaimSubmitterResolveClaimSelectOptionwrapper,
  ClaimSubmitterResolveClaimUpdateOptionWrapper,
} from "../ClaimSubmitterResolveClaimOptionWrappers";

export default function ClaimOrganizationClinicianSelectVariation() {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    claim_organization_clinician_variations = [],
    claim_organization_clinician_variation = {},
    updateContext = () => {},
  } = useClaimSubmitterResolveClaimFormContextProvider();
  const { index: variationIndex } =
    claim_organization_clinician_variation || {};

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSelect = ({ e, index }) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateContext({
      claim_organization_clinician_variation: {
        index,
        object: claim_organization_clinician_variations?.[index],
      },
    });
  };

  const handleSelectOther = (payload) => {
    updateContext({
      claim_organization_clinician_variations: [
        ...claim_organization_clinician_variations,
        payload,
      ],
      claim_organization_clinician_variation: {
        index: claim_organization_clinician_variations.length,
        object: payload,
      },
    });
    setIsOpen(false);
  };

  return (
    <Stack spacing={2}>
      {claim_organization_clinician_variations.map((object, index) => (
        <ClaimSubmitterResolveClaimSelectOptionwrapper
          key={index}
          isSelected={index === variationIndex}
          handleSelect={(e) => handleSelect({ e, index })}
          useEditIcon={false}
        >
          <OrganizationClinicianData object={object} useCustomSection={false} />
        </ClaimSubmitterResolveClaimSelectOptionwrapper>
      ))}
      {isOpen ? (
        <ClaimSubmitterResolveClaimUpdateOptionWrapper>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <OrganizationClinicianAutocomplete
              label="Select clinician"
              handleChange={handleSelectOther}
              textFieldProps={{ autoFocus: true }}
            />
            <CustomButton
              icon="CloseIcon"
              isSquare={true}
              useIconAsLabel={true}
              variant="outlined"
              onClick={handleIsOpen}
            />
          </Stack>
        </ClaimSubmitterResolveClaimUpdateOptionWrapper>
      ) : (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          pt
        >
          <CustomButton
            label="Search for a clinician"
            color="info"
            onClick={handleIsOpen}
          />
        </Stack>
      )}
    </Stack>
  );
}
