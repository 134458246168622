import { getOptions } from "@helpers/hooks/objects";
import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const DRUG_NAME_TYPE_GENERIC = "G";
export const DRUG_NAME_TYPE_TRADE = "T";

export const DRUG_NAME_TYPE_MAPPER = {
  [DRUG_NAME_TYPE_GENERIC]: "Generic name",
  [DRUG_NAME_TYPE_TRADE]: "Trade name",
};

export const DRUG_NAME_TYPE_OPTIONS = getOptions(DRUG_NAME_TYPE_MAPPER);

export const emptyDrugNameFormData = {
  name: "",
  description: "",
  drug_name_type: DRUG_NAME_TYPE_GENERIC,
};

export const formatDrugNameFormData = ({ isCheck, formData }) => {
  const { id = undefined, name, description, drug_name_type } = formData;

  return {
    id,
    is_check: isCheck,
    name: name?.trim(),
    description: description?.trim(),
    drug_name_type,
  };
};

const drugNameRowToObject = (row) => {
  const { name, description, drug_name_type } = getRowValue({
    row,
    columns: [
      { headerName: "NAME", fieldname: "name" },
      { headerName: "DESCRIPTION", fieldname: "description" },
      { headerName: "DRUG NAME TYPE", fieldname: "drug_name_type" },
    ],
  });

  const isRowEmpty = !(name || description || drug_name_type);
  return {
    id: isRowEmpty ? null : uuidv4(),
    name,
    description,
    drug_name_type,
  };
};

export const drugNameRowsToObjects = (rows) =>
  rows.map((row) => drugNameRowToObject(row)).filter(({ id }) => Boolean(id));
