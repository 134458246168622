import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED,
  CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE,
  CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
  CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS,
} from "@constants/claims/claim-submission-uploads";
import { closeSnackbar } from "notistack";

export const getClaimSubmissionUploadSnackbarKey = ({
  id,
  preprocessing_status,
}) => `claim-submission-upload-${id}-${preprocessing_status}`;

export const parseClaimSubmissionUploadSnackbar = (claim_submission_upload) => {
  const { id, preprocessing_status } = claim_submission_upload;
  const key = getClaimSubmissionUploadSnackbarKey({ id, preprocessing_status });
  const context = getSnackbarContext(claim_submission_upload);
  const variant = "CustomSnackbar";
  const anchorOrigin = { vertical: "bottom", horizontal: "right" };

  return { key, variant, context, anchorOrigin, persist: true };
};

const getSnackbarContext = (object) => {
  const { id, preprocessing_status, claim_submission } = object;
  const { name, processor_organization } = claim_submission || {};
  const image = processor_organization?.image;
  const key = getClaimSubmissionUploadSnackbarKey({ id, preprocessing_status });
  const context = { key, image };

  switch (preprocessing_status) {
    case CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING:
      return {
        ...context,
        isLoadingVariant: true,
        title: (
          <>
            {"Uploading "}
            <b>{name}</b>
          </>
        ),
      };

    case CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE:
      return {
        ...context,
        isErrorVariant: true,
        title: (
          <>
            <b>{name}</b> {" could not be uploaded"}
          </>
        ),
      };

    case CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {" successfully uploaded"}
          </>
        ),
      };

    case CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {" upload canceled"}
          </>
        ),
      };

    default:
      return context;
  }
};

export const handleCloseClaimSubmissionUploadSnackbar = (
  claim_submission_upload
) => {
  const { id, preprocessing_status } = claim_submission_upload;
  const key = getClaimSubmissionUploadSnackbarKey({ id, preprocessing_status });
  closeSnackbar(key);
};
