import React from "react";
import Typography from "@mui/material/Typography";

export default function CustomNotProvidedText({ title }) {
  return (
    <Typography variant="body2" color="textSecondary" fontStyle="italic">
      {`Missing ${title?.toLowerCase()}`}
    </Typography>
  );
}
