import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import Grid from "@mui/material/Grid";
import { useQueryFetchIdentityTypes } from "@redux/actions/avey/identity-types";
import IdentityTypeCreateForms from "./IdentityTypeCreateForms";
import {
  filterOptions,
  formatFilters,
  initialFilterState,
} from "./IdentityTypesFilters";
import IdentityTypesGridProps from "./IdentityTypesGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function IdentityTypesGrid() {
  const { as_staff } = useStaffContextProvider();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const useQueryFnProps = {
    searchQuery,
    filters: { newer_version__isnull: true, as_staff, ...filters },
  };
  const dataGridProps = React.useMemo(
    () => IdentityTypesGridProps({ as_staff }),
    [as_staff]
  );

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item>
          <IdentityTypeCreateForms />
        </Grid>
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchIdentityTypes}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </div>
  );
}
