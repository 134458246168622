import React from "react";
import AppConfigurationsTitleComponent from "@components/Authenticated/Configurations/Configurations/AppConfigurations/AppConfigurationsTitleComponent";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import {
  emptyMedicationFormData,
  formatMedicationFormData,
} from "@constants/avey/medications";
import MenuItem from "@mui/material/MenuItem";
import {
  createMedication,
  invalidateMedicationsTSQ,
} from "@redux/actions/avey/medications";
import { enqueueSnackbar } from "notistack";
import { MedicationDataReviewAndConfirm } from "../MedicationData";
import MedicationFormData from "../MedicationFormData";

export default function MedicationCreateForm() {
  const { as_staff, isStaff } = useStaffContextProvider();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyMedicationFormData);
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;
  const isOpenMainDialog = isOpen && !isOpenSummary && !isOpenAlert;
  const TitleComponent = AppConfigurationsTitleComponent({ isStaff });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatMedicationFormData({ isCheck, formData });
    createMedication({ data: formattedFormData, as_staff })
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          setIsOpen(false);
          enqueueSnackbar({
            variant: "success",
            message: "Medication successfully created",
          });
          invalidateMedicationsTSQ();
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => {
    if (isOpen) {
      setFormData(emptyMedicationFormData);
      setAlertState(initialAlertState);
    }
    setSummaryState(initialSummaryState);
  }, [isOpen]);

  return (
    <>
      <MenuItem onClick={handleIsOpen}>{"Add a medication"}</MenuItem>
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title: "New medication" }}
        maxWidth="md"
      >
        <MedicationFormData formData={formData} setFormData={setFormData} />
      </CustomActionDialog>
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={MedicationDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
        TitleComponent={TitleComponent}
        summaryDialogProps={{ maxWidth: "sm" }}
      />
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
    </>
  );
}
