import { EES_ENABLED } from "@constants/static/globals";
import {
  apiWrapper,
  AveyBloomApi,
  emptyPaginatedResponse,
  getQueryParams,
  parsePaginatedEesResponse,
  parsePaginatedResponse,
  PayloadError,
  ServerApi,
} from "@helpers/api";
import {
  invalidateTSQ,
  updateObjectTSQ,
  useQueryWrapper,
} from "@helpers/tanstack";

const endpointUrl = "/avey/units/";
const eesEndpointUrl = "/api/ees/units/";
const queryKeySingular = "unit";
const queryKeyPlural = "units";
const queryKeySingularHistory = `${queryKeySingular}_history`;

export const useQueryFetchUnits = ({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  eesFilters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = false,
  useEesIfEnabled = true,
}) => {
  return useQueryWrapper({
    keepPreviousData: true,
    useInfiniteWrapper,
    queryKey: [
      queryKeyPlural,
      {
        page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        eesFilters,
        useEesIfEnabled,
      },
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({ fn: fetchUnits, ...apiWrapperProps })({
        page: useInfiniteWrapper ? pageParam : page,
        page_size,
        searchQuery,
        sortQuery,
        filters,
        eesFilters,
        useEesIfEnabled,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUnits({
  page = 1,
  page_size = 20,
  searchQuery = "",
  sortQuery = "",
  filters = {},
  eesFilters = {},
  useEesIfEnabled = true,
}) {
  try {
    if (EES_ENABLED && useEesIfEnabled) {
      const response = await ServerApi().post(eesEndpointUrl, {
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        filters: { ...filters, ...eesFilters },
      });
      return parsePaginatedEesResponse(response.data);
    } else {
      const queryParams = getQueryParams({
        page,
        page_size,
        search: searchQuery,
        ordering: sortQuery,
        ...filters,
      });
      const response = await AveyBloomApi().get(
        `${endpointUrl}?${queryParams}`
      );
      return parsePaginatedResponse(response.data);
    }
  } catch (error) {
    return emptyPaginatedResponse;
  }
}

export const useQueryFetchUnit = ({
  unit__id = "",
  as_staff,
  reactQueryProps = {},
  apiWrapperProps = {},
}) => {
  return useQueryWrapper({
    queryKey: [queryKeySingular, { id: unit__id, as_staff }],
    queryFn: () =>
      apiWrapper({ fn: fetchUnit, ...apiWrapperProps })({
        unit__id,
        as_staff,
      }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUnit({ unit__id = "", as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${unit__id}/?${queryParams}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createUnit({ data, as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().post(
      `${endpointUrl}?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function updateUnit({ data, as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().put(
      `${endpointUrl}${data.id}/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export async function deleteUnit({ data, as_staff }) {
  try {
    const queryParams = getQueryParams({ as_staff });
    const response = await AveyBloomApi().post(
      `${endpointUrl}${data.id}/activate/?${queryParams}`,
      data
    );
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.reason,
    });
  }
}

export function updateUnitObjectTSQ({ object }) {
  updateObjectTSQ({
    predicate: ({ queryKey }) =>
      queryKey[0] === queryKeySingular && queryKey[1].id === object.id,
    object,
  });
}

export function invalidateUnitsTSQ() {
  invalidateTSQ({
    predicate: ({ queryKey }) =>
      [queryKeySingular, queryKeyPlural, queryKeySingularHistory].includes(
        queryKey[0]
      ),
  });
}

export const useQueryFetchUnitHistory = ({
  id,
  filters = {},
  reactQueryProps = {},
  apiWrapperProps = {},
  useInfiniteWrapper = true,
}) => {
  return useQueryWrapper({
    useInfiniteWrapper,
    queryKey: [queryKeySingularHistory, { id, filters }],
    queryFn: ({ pageParam = 1 }) =>
      apiWrapper({
        fn: fetchUnitHistory,
        ...apiWrapperProps,
      })({ page: pageParam, id, filters }),
    staleTime: 300_000,
    cacheTime: 0,
    ...reactQueryProps,
  });
};

async function fetchUnitHistory({ page = 1, id, filters = {} }) {
  try {
    const queryParams = getQueryParams({ page, ...filters });
    const response = await AveyBloomApi().get(
      `${endpointUrl}${id}/history/?${queryParams}`
    );
    return parsePaginatedResponse(response.data);
  } catch (error) {
    return emptyPaginatedResponse;
  }
}
