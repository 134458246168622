import React from "react";
import CustomButton from "@components/Custom/CustomButton";
import { CustomCheckbox } from "@components/Custom/CustomLabeledCheckbox";
import Stack from "@mui/material/Stack";

export const ClaimSubmitterResolveClaimSelectOptionwrapper = ({
  isSelected = false,
  children,
  handleSelect = () => {},
  handleEdit = () => {},
  useEditIcon = true,
  padding = "12px 16px",
}) => {
  return (
    <Stack
      spacing={3}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="button elevated-8 borderRadius-8"
      style={{ padding }}
      onClick={handleSelect}
    >
      <CustomCheckbox isRadio={true} checked={isSelected} />
      <div style={{ flexGrow: 1, minHeight: 40 }}>{children}</div>
      {useEditIcon && isSelected && (
        <CustomButton
          icon="EditIcon"
          variant="outlined"
          isSquare={true}
          useIconAsLabel={true}
          onClick={handleEdit}
        />
      )}
    </Stack>
  );
};

export const ClaimSubmitterResolveClaimUpdateOptionWrapper = ({ children }) => {
  return (
    <div className="elevated-8 borderRadius-8" style={{ padding: "16px" }}>
      {children}
    </div>
  );
};
