import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import {
  getClaimExportHref,
  getClaimSubmissionHref,
  HOME_HREF,
  HOME_TITLE,
} from "@constants/static/routing";

export default function ClaimExportsHeader({ claim_submission, organization }) {
  const { id: claim_submission__id, name: claim_submission_name } =
    claim_submission || {};
  const { name: organization__name, slug: organization__slug } = organization;
  let title;
  let hrefs = [
    {
      label: HOME_TITLE,
      href: HOME_HREF,
    },
    {
      label: organization__name,
      href: getClaimSubmissionHref({ organization__slug }),
    },
  ];

  if (Boolean(claim_submission__id)) {
    title = claim_submission_name;
    hrefs = hrefs.concat([
      {
        label: title,
        href: getClaimSubmissionHref({
          organization__slug,
          claim_submission__id,
        }),
      },
      {
        label: "Claim exports",
        href: getClaimExportHref({ claim_submission__id, organization__slug }),
      },
    ]);
  } else {
    title = "Claim exports";
    hrefs = hrefs.concat([
      {
        label: title,
        href: getClaimExportHref({ organization__slug }),
      },
    ]);
  }

  return (
    <CustomHeader
      TitleComponent={CustomHeaderTitle}
      titleComponentProps={{ title }}
      hrefs={hrefs}
    />
  );
}
